import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: {} });

function payPaip(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.PAY_PAIP:
      return { isLoading: true, error: null, data: {} };
    case Actions.PAY_PAIP_SUCCESS:
      return { isLoading: false, error: null, data: action.data };
    case Actions.PAY_PAIP_FAIL:
      return { isLoading: false, error: action.error, data: {} };
    case Actions.RESET_PAY_PAIP:
      return getDefaultState();
    default:
      return state;
  }
}

export default payPaip;
