import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";

const getSessionData = store => store.PROFILE.userSession;

function* history({data}) {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };

  const { response, error } = yield call(api.history, data, headers);

  if (response) {
    yield put(Actions.historySuccess(response.data.data));
  } else {
    yield put(Actions.historyFailed(error.response.data));
  }
}

function* watchHistory() {
  yield takeLatest(Actions.HISTORY, history);
}

export default function* submit() {
  yield all([fork(watchHistory)]);
}
