import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";

const getSessionData = store => store.PROFILE.userSession;

function* getOtherCompoundList({ data }) {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}` };

  // console.log("Other compound SAGA", data);
  const { response, error } = yield call(
    api.getOtherCompoundList,
    headers,
    data
  );
  // console.log("Other compound", response);
  if (response) {
    yield put(Actions.getOtherCompoundListSuccess(response.data.data));
  } else {
    yield put(Actions.getOtherCompoundListFail(error.response.data));
  }
}

function* watchGetOtherCompoundList() {
  yield takeLatest(Actions.GET_OTHER_COMPOUND_LIST, getOtherCompoundList);
}

export default function* submit() {
  yield all([fork(watchGetOtherCompoundList)]);
}
