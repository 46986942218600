import Actions from '../../actions';

const getDefaultState = () => ({ 
    isLoading: false,
});

function product(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.VALIDATE_PRODUCT:
            return {
                ...state,
                isLoading: true,
            };   
        case Actions.VALIDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case Actions.VALIDATE_PRODUCT_FAILED:
            return {
                ...state,
                isLoading: false,
            };                   
        default:
            return state;
    }
}

export default product;