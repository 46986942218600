export const NAME = 'BIIL'

export const GET_BILL = `${NAME}/GET_BILL`;

export const GET_BILL_SUCCESS = `${NAME}/GET_BILL_SUCCESS`;

export const GET_BILL_FAILED = `${NAME}/GET_BILL_FAILED`;

export const GET_TRANSACTION_HISTORY = `${NAME}/GET_TRANSACTION_HISTORY`;

export const GET_TRANSACTION_HISTORY_SUCCESS = `${NAME}/GET_TRANSACTION_HISTORY_SUCCESS`;

export const GET_TRANSACTION_HISTORY_FAILED = `${NAME}/GET_TRANSACTION_HISTORY_FAILED`;


export const getBill = (data, callback, callbackError) => ({
    type: GET_BILL,
    data,
    callback,
    callbackError
});

export const getBillSuccess = (data) => ({
    type: GET_BILL_SUCCESS,
    data
});

export const getBillFailed = () => ({
    type: GET_BILL_FAILED
});

export const getTransactionHistory = (data, callback, callbackError) => ({
    type: GET_TRANSACTION_HISTORY,
    data,
    callback,
    callbackError
});

export const getTransactionHistorySuccess = (data) => ({
    type: GET_TRANSACTION_HISTORY_SUCCESS,
    data
});

export const getTransactionHistoryFailed = () => ({
    type: GET_TRANSACTION_HISTORY_FAILED
});
