import React from "react";
import {useSelector, useDispatch} from "react-redux"
import { reduxForm, Field, SubmissionError } from "redux-form";

import TextInput from "../../components/TextInput.js";

import Actions from "../../actions";
import * as api from "../../api"

import LoadingOverlay from "../../components/LoadingOverlay"
import FavouriteModal from "../../components/FavouriteModal"

const Bill = props => {
  const [cardActive, setCardActive] = React.useState(null);
  const [favouriteActive, setFavouriteActive] = React.useState(null);
  const [favouriteModal, setFavouriteModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [number, setNumber] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [bills, setBills] = React.useState([{
    name: "PAIP",
    picture: require('../../assets/images/paip.png'),
    have_bills: 1
  }])
  const {list} = useSelector(state => state.IIMMPACT.utilities)
  const {fav_accounts} = useSelector(state => state.FAVOURITE.list)
  const {data: {auth_token}} = useSelector(state => state.PROFILE.userSession)
  const {isLoading, data, error} = useSelector(state => state.FEEDERS.getPaipList)
  const result = useSelector(state => state.IIMMPACT.bill)
  const [slug, setSlug] = React.useState(null)
  const dispatch = useDispatch()
  const [invalidError, setInvalidError] = React.useState("")

  React.useEffect(() => {
    dispatch(Actions.getUtilitiesList())
    // dispatch(Actions.fetchListFavourites("paip", "utilities"))
  }, [])

  React.useEffect(() => {
    if (list.length > 0) {
      let obj = bills.find(o => o.name === "Astro")
      if (obj === undefined)
        setBills(state => [...state, list[0]])
    }
    //   list.push({
    //     id: 1,
    //     picture: require('../../assets/images/paip.png'),
    //     name: 'PAIP'
    //   })
  }, [list])

  React.useEffect(() => {
    // console.log("lala")
    console.log(fav_accounts)
  }, [fav_accounts])

  React.useEffect(() => {
    if (!isLoading && !error && Object.keys(data).length > 0) {
      data.paip = true
      // console.log(data)
      props.history.push({pathname: '/bill/payment_form', state: data})
    } else if (!isLoading && error) {
      setInvalidError(`Account number is not valid for ${bills[cardActive].name} account`)
    }
  }, [data, error])

  React.useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  // console.log(favourite)

  const handleNext = () => {
    if (bills[cardActive].name !== "PAIP") {
      const payload = {
        account_no: number,
        product_code: bills[cardActive].merchant_code
      }
      setLoading(true)
  
      dispatch(Actions.getBill(payload, (a) => {
        props.history.push({pathname: '/bill/payment_form', state: {account_no: number, paip: false, product_code: bills[cardActive].merchant_code}})
        setLoading(false)
      }, (error) => {
        setLoading(false)
        setInvalidError(`Account number is not valid for ${bills[cardActive].name} account`)
      }))
    } else {
      dispatch(Actions.getPaipList(number))
    }
    // const cardArray = ['Astro'];
    // const favouriteArray = ['33232910102', '233023000023']

    // props.history.push({
    //   pathname: '/bill/pay',
    //   state: {card: cardArray[cardActive], amount, number: favouriteActive !== null ? favouriteArray[favouriteActive] : number}
    // })
  }

  // return (
  //   <div className="content home">
  //     {loading && <LoadingOverlay />}
  //     <h1 className="content-title">Bills</h1>

  //     <div className="content-card">
  //       <h4>Select Bill</h4>

  //       {list.length === 0 && (
  //         <div className="loading_holder">
  //           <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  //         </div>
  //       )}

  //       {list.length > 0 && (
  //         <div className="card-holder">
  //           {bills.map((l, i) => (
  //             <div
  //               key={l.id}
  //               className={cardActive === i ? "active" : ""}
  //               onClick={async () => {
  //                 if (l.name !== "PAIP") {
  //                   var headers = { Authorization: `Bearer ${auth_token}` }
  //                   const {response: {data: {data}}} = await api.getMerchantsById(headers, l.merchant_id)
  //                   setSlug(data.slug)
  //                   dispatch(Actions.fetchListFavourites(data.slug, null))
  //                 } else {
  //                   setSlug("paip_utilities")
  //                   dispatch(Actions.fetchListFavourites("paip", "utilities"))
  //                 }
  //                 setCardActive(cardActive !== null ? cardActive === i ? null : i : i)
  //               }}
  //             >
  //               <img
  //                 src={l.picture}
  //                 style={{ marginTop: "10px" }}
  //                 alt=""
  //               />
  //               <p>{l.name}</p>
  //             </div>
  //           ))}
  //         </div>
  //       )}
  //     </div>

  //     {cardActive !== null && (
  //       <div className="content-form">
  //         <form onSubmit={props.handleSubmit(handleNext)}>
  //           <div className="form-wrapper">
  //             <Field 
  //               component={TextInput}
  //               type="text"
  //               label="Account Number"
  //               name="account_no"
  //               placeholder="Enter account number"
  //             />
  //             {bills[cardActive].have_bills === 0 && (
  //               <div className="input-holder amount">
  //                 <label htmlFor="">Amount</label>
  //                 <p>Minimum amount RM40</p>
  //                 <div className="amount-input">
  //                   <h4>RM</h4>
  //                   <input type="text" placeholder="0" onChange={e => setAmount(e.target.value)} />
  //                 </div>
  //               </div>
  //             )}
  //             <div className={`favourite-holder ${number ? 'hidden' : ''}`}>
  //               <h4>My Favourite</h4>
  //               {fav_accounts.hasOwnProperty(slug) && fav_accounts[slug].map((fav, i) => (
  //                 <div key={i} className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
  //                   <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //                   <p>{fav.name} - {fav.phoneNumber}</p>
  //                 </div>
  //               ))}
  //               {/* <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
  //                 <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //                 <p>My account number - 33232910102</p>
  //               </div>
  //               <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 1 : favouriteActive === 1 ? null : 1)}>
  //                 <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //                 <p>Mom's account number - 233023000023</p>
  //               </div> */}
  //             </div>
  //             <button>NEXT</button>
  //           </div>
  //         </form>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div id="content" className="flex">
      {loading && <LoadingOverlay />}
      <FavouriteModal slug={slug === "paip_utilities" ? 'paip' : slug} category={slug === "paip_utilities" ? 'utilities' : ''} favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} label="Account number" />
      <div className="h-100 d-flex padding" id="page-container">
        <div className="block flex mb-0">
        <div className="page-title padding pb-0 ">
            <h2 className="text-md mb-0">Bill</h2>
        </div>
        <div className="padding">
          <div className="row">
            <div className="col-sm-4 col-md-8">
              {/* <div className="card shadow-md border"> */}
                <div className="text-muted">Select bill</div>
                <div className="pt-4">
                    {list.length === 0 && (
                        <div className="text-center d-flex py-4 justify-content-center">
                            <div className="loading w-48"></div>
                        </div>
                    )}
                  <div className="row">
                    {list.length > 0 && bills.map((l, i) => (
                      <div className="col-3" key={l.id}>
                        <div className={`card pointer border no-shadow ${cardActive === i ? "border-primary" : ""}`} onClick={async () => {
                        if (l.name !== "PAIP") {
                            var headers = { Authorization: `Bearer ${auth_token}` }
                            const {response: {data: {data}}} = await api.getMerchantsById(headers, l.merchant_id)
                            setSlug(data.slug)
                            dispatch(Actions.fetchListFavourites(data.slug, null))
                          } else {
                            setSlug("paip_utilities")
                            dispatch(Actions.fetchListFavourites("paip", "utilities"))
                          }
                          setNumber("")
                          setInvalidError("")
                          setCardActive(cardActive !== null ? cardActive === i ? null : i : i)
                      }
                      }>
                          <div className="p-3 text-center">
                            <img style={{height: "50px"}} src={l.picture} alt=""/>
                            <p className="text-center m-2">{l.name.split(" ")[0]}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          {cardActive !== null && (
            <div className="row">
              <div className="col-sm-4 col-md-8">
                  {/* <div className="card shadow-md border"> */}
                    {/* <div className="p-4"> */}
                      <div className="form-group">
                        <label htmlFor="">Account Number</label>
                        <input type="number" className={`form-control ${invalidError ? 'is-invalid' : ''} `} disabled={favouriteActive !== null} value={number} onChange={e => setNumber(e.target.value)} />
                        {invalidError && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
                        <li class="parsley-required">{invalidError}</li>
                        </ul>}
                      </div>
                      {bills[cardActive].have_bills === 0 && (
                        <div className="form-group">
                          <label htmlFor="">Amount</label>
                          <div className="input-group">
                              <div className="input-group-prepend">
                                  <span className="input-group-text">RM</span>
                              </div>
                              <input type="number" className="form-control" name="name" />
                          </div>
                        </div>
                      )}
                      <div className="row m-0 justify-content-between">
                        <p className="text-muted">My Favourite</p>
                        <div className="i-con-h-a" onClick={() => setFavouriteModal(true)}>
                          <i className="i-con i-con-plus"><i></i></i>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col">
                          {fav_accounts.hasOwnProperty(slug) && fav_accounts[slug].map((fav, i) => (
                            <div className={`card border no-shadow i-con-h-a pointer ${favouriteActive === i ? 'border-primary' : ''}`} onClick={() => {
                                setFavouriteActive(favouriteActive === null ? i : favouriteActive === i ? null : i)
                                setNumber(favouriteActive === null ? fav.phoneNumber : favouriteActive === i ? "" : fav.phoneNumber)
                            }}>
                              <div className="p-2">
                                <div className="row row-sm">
                                  <div className="col-auto">
                                  <i className="i-con i-con-ok"><i></i></i>
                                  </div>
                                  <div className="col">
                                    {fav.name} - {fav.phoneNumber}
                                  </div>
                                  <div className="col-auto i-con-h-a">
                                    <i className={`i-con i-con-trash`} onClick={(e) => {
                                      e.stopPropagation()
                                      dispatch(Actions.deleteFavourite(slug === "paip_utilities" ? 'paip' : slug, fav.name, slug === "paip_utilities" ? 'utilities' : null))
                                    }}><i></i></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button className="btn btn-primary w-100 mt-2" disabled={!number} onClick={handleNext}>Next</button>
                    {/* </div> */}
                  {/* </div> */}
              </div>
            </div>
          )}
          </div>
      </div>
    </div>

    </div>
  );
};

export default reduxForm({
  form: "billForm",
  // validate
})(Bill);
