import { all, fork } from 'redux-saga/effects';
import paymentMethodOptions from './paymentMethodOptions';
import addPaymentMethod from './addPaymentMethod';
import addCardPaymentMethod from './addCardPaymentMethod';
import deletePaymentMethod from './deletePaymentMethod';
import getPaymentMethods from './getPaymentMethods';
import setDefaultPaymentMethod from './setDefaultPaymentMethod';
import paymentService from './paymentService';
import verifyPayment from "./verifyPayment"

export default function* home() {
    yield all([
        fork(paymentMethodOptions),
        fork(addPaymentMethod),
        fork(addCardPaymentMethod),
        fork(deletePaymentMethod),
        fork(getPaymentMethods),
        fork(setDefaultPaymentMethod),
        fork(paymentService),
        fork(verifyPayment)
    ]);
}