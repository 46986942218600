export const NAME = "PAYMENT";

export const GET_PAYMENT_METHOD_OPTIONS = `${NAME}/GET_PAYMENT_METHOD_OPTIONS`;
export const GET_PAYMENT_METHOD_OPTIONS_SUCCESS = `${NAME}/GET_PAYMENT_METHOD_OPTIONS_SUCCESS`;
export const GET_PAYMENT_METHOD_OPTIONS_FAIL = `${NAME}/GET_PAYMENT_METHOD_OPTIONS_FAIL`;
export const RESET_GET_PAYMENT_METHOD_OPTIONS = `${NAME}/RESET_GET_PAYMENT_METHOD_OPTIONS`;

export const getPaymentMethodOptionsData = store => store[NAME].paymentMethodOptions;

export const getPaymentMethodOptions = data => ({
  type: GET_PAYMENT_METHOD_OPTIONS,
  data
});

export const getPaymentMethodOptionsSuccess = data => ({
  type: GET_PAYMENT_METHOD_OPTIONS_SUCCESS,
  data
});

export const getPaymentMethodOptionsFail = error => ({
  type: GET_PAYMENT_METHOD_OPTIONS_FAIL,
  error
});

export const resetGetPaymentMethodOptions = () => ({
  type: RESET_GET_PAYMENT_METHOD_OPTIONS
});
