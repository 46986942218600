import React from 'react';
import { Link } from 'react-router-dom';

const OtherCompoundTable = ({location}) => {
    
    const formatCurrency = d => {
        let t = d / 100
        return `${t.toFixed(2)}`
    }

    return (
        <div className="pt-4">
            <div className="table-responsive">
            <table className="table table-theme table-row v-middle">
                <thead>
                <tr>
                    <th className="text-muted">Compound Code</th>
                    <th className="text-muted">Reason</th>
                    <th className="text-muted">Amount(RM)</th>
                    <th className="text-muted"></th>
                </tr>
                </thead>
                <tbody>
                    {location.state.map(data => {
                        data.pbtid = location.state.pbtid
                        return (
                            <tr className="v-middle">
                                <td>{data.service_reference_1}</td>
                                <td>{data.violation_type}</td>
                                <td>{formatCurrency(data.compound_amount)}</td>
                                <td>
                                    <Link to={{pathname: '/pay/kuantan/other_compound/form', state: data}}>
                                        <button className="btn btn-primary w-100">PAY</button>
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
        </div>
    );
};

export default OtherCompoundTable;