export const NAME = "PROFILE";

export const GET_USER_PROFILE = `${NAME}/GET_USER_PROFILE`;
export const GET_USER_PROFILE_SUCCESS = `${NAME}/GET_USER_PROFILE_SUCCESS`;
export const GET_USER_PROFILE_FAIL = `${NAME}/GET_USER_PROFILE_FAIL`;

export const UPDATE_USER_PROFILE = `${NAME}/UPDATE_USER_PROFILE`;
export const UPDATE_USER_PROFILE_SUCCESS = `${NAME}/UPDATE_USER_PROFILE_SUCCESS`;
export const UPDATE_USER_PROFILE_FAIL = `${NAME}/UPDATE_USER_PROFILE_FAIL`;

export const UPDATE_USER_EMAIL = `${NAME}/UPDATE_USER_EMAIL`;
export const UPDATE_USER_EMAIL_SUCCESS = `${NAME}/UPDATE_USER_EMAIL_SUCCESS`;
export const UPDATE_USER_EMAIL_FAIL = `${NAME}/UPDATE_USER_EMAIL_FAIL`;

export const RESET_USER_PROFILE = `${NAME}/RESET_USER_PROFILE`;
export const CLEAR_USER_PROFILE = `${NAME}/CLEAR_USER_PROFILE`;

export const getUserProfileData = store => store[NAME].userProfile;

export const getUserProfile = () => ({
  type: GET_USER_PROFILE
});

export const getUserProfileSuccess = data => ({
  type: GET_USER_PROFILE_SUCCESS,
  data
});

export const getUserProfileFail = error => ({
  type: GET_USER_PROFILE_FAIL,
  error
});

export const updateUserProfile = data => ({
  type: UPDATE_USER_PROFILE,
  data
});

export const updateUserProfileSuccess = data => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  data
});

export const updateUserProfileFail = error => ({
  type: UPDATE_USER_PROFILE_FAIL,
  error
});

export const updateUserEmail = data => ({
  type: UPDATE_USER_EMAIL,
  data
});

export const updateUserEmailSuccess = data => ({
  type: UPDATE_USER_EMAIL_SUCCESS,
  data
});

export const updateUserEmailFail = error => ({
  type: UPDATE_USER_EMAIL_FAIL,
  error
});

export const resetUserProfile = () => ({
  type: RESET_USER_PROFILE
});

export const clearUserProfile = () => ({
  type: CLEAR_USER_PROFILE
});
