import React from 'react';
import {Route} from "react-router-dom"

import Header from "./Header"
import Sidebar from "./Sidebar"

const CustomRoute = ({
    component: Component,
    location,
    history,
    ...rest
}) => {
    return (
        <>
            {location.pathname !== "/" && (
                <Header location={location} history={history} />
            )}
            <div className="layout-row flex" id="main">
                {location.pathname !== "/" && (
                    <Sidebar location={location} />
                )}
                <Route {...rest} component={props => <Component {...props} />} />
            </div>
        </>
    );
};

export default CustomRoute;