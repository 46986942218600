import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Analytics from "react-router-ga"

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./assets/scss/App.scss"
import "./assets/css/bootstrap.css"
import "./assets/css/i-con.css"
import "./assets/css/theme.css"
import "./assets/css/style.css"

import configureStore from "./store";

import CustomRoute from "./components/CustomRoute"

import Home from "./pages/Home"
import ProfileSettings from "./pages/ProfileSettings"
import Account from "./pages/Account"
import PaymentMethod from "./pages/PaymentMethod"

import MainAuth from "./pages/MainAuth"
import PaymentHistory from "./pages/PaymentHistory"
import Prepaid from "./pages/Prepaid/Prepaid";
import PrepaidPay from "./pages/Prepaid/PrepaidPay";
import Postpaid from "./pages/Postpaid/Postpaid";
import PostpaidPay from "./pages/Postpaid/PostpaidPay";
import Bill from "./pages/Bill/Bill"
import BillPay from "./pages/Bill/BillPay";

import AddAccount from "./pages/Paip/AddAccount"
import PaipAccount from "./pages/Paip/PaipAccount"
import PaipPay from "./pages/Paip/PaipPay"

import Pay from "./pages/Pay/PayList"
import PaySelection from "./pages/Pay/PaySelection";
import PayForm from "./pages/Pay/PayForm"
import PayDetails from "./pages/Pay/PayDetails"
import PayViewTable from "./pages/Pay/PayViewTable"

import PaymentForm from "./pages/PaymentForm"
import Payment from "./pages/Payment"

import ReturnPage from "./pages/ReturnPage"

import BusForm from "./pages/Bus/BusForm"

import EducationForm from "./pages/Education/EducationForm"

import RoadtaxForm from "./pages/Roadtax/RoadtaxForm"
import RoadtaxSummary from "./pages/Roadtax/RoadtaxSummary"

import Header from "./components/Header"
import Sidebar from "./components/Sidebar"

import Zakat from "./pages/Zakat"

import NotFound from "./pages/NotFound"

import './App.css';

const { store, persistor } = configureStore();

function App() {
  // console.log(store.getState())

  React.useEffect(() => {
    // console.log()
  }, [store])

  return (
    <div className="App">
      <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Route
            render={({ location, history }) => (
              <>
                {/* {location.pathname !== "/" && (
                    <Header location={location} history={history} />
                )} */}
                {/* <div className="layout-row flex" id="main"> */}
                {/* <div className={`superwrapper ${location.pathname === "/" ? 'no-margin' : ''}`}> */}
                  {/* <div className={`wrapper ${location.pathname === "/" ? 'no-flex' : ''}`}> */}
                  {/* {location.pathname !== "/" && (
                    <Sidebar location={location} />
                  )} */}
                  <Analytics id="UA-183383517-1">
                    <Switch>
                      {Object.keys(store.getState().PROFILE.userSession.data).length > 0 && store.getState().PROFILE.userSession.data.auth_token ? 
                      <Redirect from="/" to="/home" exact />
                      : <Redirect from="/home" to="/" exact />}
                      
                      <Route path="/" component={MainAuth} exact />
                      <CustomRoute path="/home" component={Home} location={location} history={history} exact />
                      <CustomRoute path="/profile_settings" component={ProfileSettings} location={location} history={history} exact />
                      <CustomRoute path="/payment_method" component={PaymentMethod} location={location} history={history} exact />
                      <CustomRoute path="/account" component={Account} location={location} history={history} exact />
                      <CustomRoute path="/payment_history" component={PaymentHistory} location={location} history={history} exact />
                      <CustomRoute path="/prepaid" component={Prepaid} location={location} history={history} exact />
                      <CustomRoute path="/:type/pay" component={Payment} location={location} history={history} exact />
                      {/* <Route path="/prepaid/pay" component={PrepaidPay} exact /> */}
                      <CustomRoute path="/postpaid" component={Postpaid} location={location} history={history} exact />
                      {/* <Route path="/postpaid/pay" component={PostpaidPay} exact /> */}
                      <CustomRoute path="/paip" component={AddAccount} location={location} history={history} exact />
                      <CustomRoute path="/paip/account" component={PaipAccount} location={location} history={history} exact />
                      <CustomRoute path="/paip/pay" component={PaipPay} location={location} history={history} exact />
                      <CustomRoute path="/bill" component={Bill} location={location} history={history} exact />
                      <CustomRoute path="/bill/pay" component={BillPay} location={location} history={history} exact />
                      <CustomRoute path="/pay" component={Pay} location={location} history={history} exact />
                      <CustomRoute path="/:type/payment_form" component={PaymentForm} location={location} history={history} exact />
                      <CustomRoute path="/pay/:type" component={PaySelection} location={location} history={history} exact />
                      <CustomRoute path="/pay/:type/:payment_type/table" component={PayViewTable} location={location} history={history} exact />
                      <CustomRoute path="/pay/:type/:payment_type/form" component={PayForm} location={location} history={history} exact />
                      <CustomRoute path="/pay/:type/:payment_type/payment" component={PayDetails} location={location} history={history} exact />
                      <CustomRoute path="/bus" component={BusForm} location={location} history={history} exact />
                      <CustomRoute path="/payment/:type" component={ReturnPage} location={location} history={history} exact />
                      <CustomRoute path="/education" component={EducationForm} location={location} history={history} exact />
                      <CustomRoute path="/roadtax" component={RoadtaxForm} location={location} history={history} exact />
                      <CustomRoute path="/roadtax/summary" component={RoadtaxSummary} location={location} history={history} exact />
                      <CustomRoute path="/zakat" component={Zakat} location={location} history={history} exact />
                      <Route component={NotFound} />
                    </Switch>
                  </Analytics>
                  {/* </div>
                </div> */}
                {/* </div> */}
              </>
            )}
          ></Route>
        </PersistGate>
      </Provider>      
      </BrowserRouter>
    </div>
  );
}

export default App;
