import {all, fork, takeLatest, call,select, put} from 'redux-saga/effects';
import Action from '../../actions';
import * as api from '../../api';


const getSessionData = store => store.PROFILE.userSession;

function* submitPay({payment_type, slug, body , callback}) {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    const { response, error } = yield call(api.payManualPbt, payment_type, slug, body, headers);

    console.log(response,error);

    if (response) {

        yield put({
            type: Action.PAY_SUCCESS,
          });

        callback && callback(response.data);
    } else {
        yield put({
            type: Action.PAY_FAILED,
          });
        
    }
}

function* watchSubmitPay() {
    yield takeLatest(Action.START_PAY, submitPay)

}

export default function* favourite() {
    yield all([
        fork(watchSubmitPay),
    ])
}