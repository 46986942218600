import * as login from "./login";
import * as register from "./register";
import * as otp from "./otp";
// import * as resendOTP from "./resendOTP";
// import * as sendOTP from "./sendOTP";
import * as logout from "./logout";
import * as userExistence from "./userExistence";
import * as reset from "./reset";

export default {
  ...login,
  ...register,
  ...otp,
//   ...sendOTP,
//   ...resendOTP,
  ...logout,
  ...userExistence,
  ...reset
};
