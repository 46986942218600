export const NAME = 'AUTH'

export const CHECK_USER_EXISTENCE = `${NAME}/CHECK_USER_EXISTENCE`;
export const CHECK_USER_EXISTENCE_SUCCESS = `${NAME}/CHECK_USER_EXISTENCE_SUCCESS`;
export const CHECK_USER_EXISTENCE_FAIL = `${NAME}/CHECK_USER_EXISTENCE_FAIL`;
export const RESET_CHECK_USER_EXISTENCE = `${NAME}/RESET_CHECK_USER_EXISTENCE`;

export const getUserExistence = store => store[NAME].userExistence;

export const checkUserExistence = data => ({
    type: CHECK_USER_EXISTENCE,
    data
});

export const checkUserExistenceSuccess = data => ({
    type: CHECK_USER_EXISTENCE_SUCCESS,
    data
});

export const checkUserExistenceFail = error => ({
    type: CHECK_USER_EXISTENCE_FAIL,
    error
});

export const resetCheckUserExistence = () => ({
    type: RESET_CHECK_USER_EXISTENCE
})