import React from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector, connect } from "react-redux";

import Actions from "../actions";

import TextInput from "../components/TextInput.js";

let ProfileSettings = ({handleSubmit}) => {
    const [gender, setGender] = React.useState("")
    const [success, setSuccess] = React.useState(false)
    const [picture, setPicture] = React.useState(null)
    const dispatch = useDispatch();
    const profileData = useSelector(state => state.PROFILE.userProfile);

    React.useEffect(() => {
        if (profileData.data.gender !== null)
            setGender(profileData.data.gender)
        if (profileData.data.picture !== null)
            setPicture(profileData.data.picture)

        // if (!profileData.isLoading)
            // dispatch(Actions.resetUserProfile())

        // console.log(profileData)
    }, [profileData])

    const handleUpdate = d => {
        setSuccess(false)
        dispatch(Actions.updateUserProfile({
            name: d.name,
            picture,
            ic: d.ic ? d.ic : "",
            gender
        }))
        setSuccess(true)
        setTimeout(() => {
            setSuccess(false)
        }, 3000)
    }

    const lala = e => {
        const files = Array.from(e.target.files)

        // setPicture(URL.createObjectURL(files[0]))
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onloadend = () => {
            setPicture(reader.result)
        }
    }

    // return (
    //     <div className="content profile-settings">
    //         <h1 className="content-title">Profile</h1>

    //         <div className="content-card">
    //             <form onSubmit={handleSubmit(handleUpdate)}>
    //                 <div className="form-wrapper">
    //                     <div className="profile-picture-holder">
    //                         <div className="profile-pic" style={picture !== null ? {backgroundImage: `url(${picture})`} : {}}>
    //                             <div className="edit-holder">Edit</div>
    //                             {/* <Field type="file" component={props => <input {...props} />} accept="image/*" onChange={(e) => console.log(e)} name="pic" /> */}
    //                             <input type="file" name="pic" onChange={lala} />
    //                         </div>
    //                     </div>
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="Name"
    //                         name="name"
    //                         placeholder="Enter username"
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="Email"
    //                         name="email"
    //                         placeholder="Enter email"
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="Phone Number"
    //                         name="phone"
    //                         placeholder="Enter phone no"
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="MyKad"
    //                         name="ic"
    //                         placeholder="Enter MyKad"
    //                     />
    //                     <div className="radio-select-holder inline">
    //                         <p className="title">Gender</p>
    //                         <div className={`radio-select hide-radio ${gender === "male" ? 'active' : ''}`}>
    //                             <input type="radio" name="gender" id="male" onClick={() => setGender("male")} />
    //                             <label htmlFor="male"> Male </label>
    //                         </div>

    //                         <div className={`radio-select hide-radio ${gender === "female" ? 'active' : ''}`}>
    //                             <input type="radio" name="gender" id="female" onClick={() => setGender("female")} />
    //                             <label htmlFor="female"> Female </label>
    //                         </div>
    //                     </div>

    //                     {/* <div className="orange-btn">
    //                         UPDATE
    //                     </div> */}
    //                     <button>UPDATE</button>
    //                 </div>
    //             </form>
    //         </div>
    //     </div>
    // );

    return (
        <div className="flex" id="content">
            <div className="h-100 d-flex padding" id="page-container">
                <div className="block flex mb-0">
                    <div className="page-title padding pb-0 ">
                        <h2 className="text-md mb-0">Profile</h2>
                    </div>
                    <div className="padding">
                        <div className="row">
                            <div className="col-sm-4 col-md-8">
                                {success && <div className={`alert bg-success mb-4 fade ${success ? 'show' : ''}`} role="alert">
                                    You’ve succesfully updated your profile.
                                </div>}
                                <div className="pb-5 pt-3 d-flex justify-content-center">
                                    <div className="avatar circle profilepic pointer" style={picture !== null ? {backgroundImage: `url(${picture})`} : {}}>
                                        <div className="edit-holder">Edit</div>
                                        {/* <Field type="file" component={props => <input {...props} />} accept="image/*" onChange={(e) => console.log(e)} name="pic" /> */}
                                        <input type="file" name="pic" className="file" onChange={lala} />
                                    </div>
                                </div>
                                <Field
                                    component={TextInput}
                                    type="text"
                                    label="Name"
                                    name="name"
                                    placeholder="Enter username"
                                />
                                <Field
                                    component={TextInput}
                                    type="text"
                                    label="Email"
                                    name="email"
                                    placeholder="Enter email"
                                />
                                <Field
                                    component={TextInput}
                                    type="text"
                                    label="Phone Number"
                                    name="phone"
                                    placeholder="Enter phone no"
                                />
                                <Field
                                    component={TextInput}
                                    type="text"
                                    label="MyKad"
                                    name="ic"
                                    placeholder="Enter MyKad"
                                />
                                <div className="form-group">
                                    <label htmlFor="">Gender</label>
                                    <div className="row row-sm pt-2" style={{width: "50%"}}>
                                        <div className="col-sm-3">
                                            <div className={`card border pointer no-shadow ${gender === "male" ? 'border-primary' : ''}`} onClick={() => setGender("male")}>
                                                <div className="p-2 text-center d-flex flex-column align-items-center">
                                                    Male
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className={`card border pointer no-shadow ${gender === "female" ? 'border-primary' : ''}`} onClick={() => setGender("female")}>
                                                <div className="p-2 text-center d-flex flex-column align-items-center">
                                                    Female
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary w-100 mt-4" onClick={handleSubmit(handleUpdate)}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// export default reduxForm({
//     form: "profileSettingForm",
//     // validate
//   })(ProfileSettings);

ProfileSettings = reduxForm({
    form: "profileSettingForm",
    enableReinitialize: true,
})(ProfileSettings);

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.data,
        initialValues: {
            ...state.PROFILE.userProfile.data
        }
    };
};

export default connect(mapStateToProps)(ProfileSettings);