import { combineReducers } from "redux";

import userSession from "./userSession";
import userProfile from "./userProfile";
import paymentMethods from "./paymentMethods";
// import updateUserPhone from "./updateUserPhone";
// import updatePhoneVerify from "./updatePhoneVerify";

export default combineReducers({
  userSession,
  userProfile,
  paymentMethods,
//   updateUserPhone,
//   updatePhoneVerify
});
