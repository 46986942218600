export const NAME = "FEEDERS";

export const GET_COMPOUND_LIST = `${NAME}/GET_COMPOUND_LIST`;
export const GET_COMPOUND_LIST_SUCCESS = `${NAME}/GET_COMPOUND_LIST_SUCCESS`;
export const GET_COMPOUND_LIST_FAIL = `${NAME}/GET_COMPOUND_LIST_FAIL`;
export const RESET_GET_COMPOUND_LIST = `${NAME}/RESET_GET_COMPOUND_LIST`;

export const getCompoundListData = store => store[NAME].getCompoundList;

export const getCompoundList = data => ({
  type: GET_COMPOUND_LIST,
  data
});

export const getCompoundListSuccess = data => ({
  type: GET_COMPOUND_LIST_SUCCESS,
  data
});

export const getCompoundListFail = error => ({
  type: GET_COMPOUND_LIST_FAIL,
  error
});

export const resetGetCompoundList = () => ({
  type: RESET_GET_COMPOUND_LIST
});
