import Actions from '../../actions';

const getDefaultState = () => ({ 
    list: [],
    selected: null
});

function address(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.GET_PREPAID_LIST_SUCCESS:
            let shownList = action.data.filter(function(list){ return list.is_shown == 1; });
            return {
                ...state,
                list: shownList,
            };
        case Actions.SELECT_PREPAID:
            return {
                ...state,
                selected: action.data,
            };                      
        default:
            return state;
    }
}

export default address;