import * as prepaid from './prepaid';
import * as utilities from './utilities';
import * as postpaid from './postpaid';
import * as product from './product';
import * as bill from './bill';

export default {
    ...utilities,
    ...prepaid,
    ...postpaid,
    ...product,
    ...bill,
}