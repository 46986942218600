import Actions from '../../actions';

const getDefaultState = () => ({ 
    list: [],
});

function options(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.GET_ZAKAT_OPTIONS_SUCCESS:
            return {
                list: action.data
            };         
        default:
            return state;
    }
}

export default options;