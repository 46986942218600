import React from "react";
import axios from "axios";

const AddressModal = props => {
  const [inputAddr, setInputAddr] = React.useState({
      unit: "",
      street: "",
      postcode: "",
      country: "",
      state: "",
      city: ""
  })

  const handleChange = e => {
    const {name, value} = e.target
    setInputAddr((state) => ({...state, [name]: value}))
    if (name === "postcode" && value.length == 5) {
      axios.get(`https://api.zippopotam.us/my/${value}`).then(({data}) => {
        setInputAddr((state) => ({...state, country: data.country, city: data.places[0]['place name'], state: data.places[0]['state']}))
      })
    }
  }

  return (
    <>
      <div
        className={`modal fade ${props.showPopup ? "show" : ""}`}
        style={props.showPopup ? { display: "block" } : {}}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body m-lg">
              <div className="form-group">
                <label htmlFor="">Unit/Building/Lot</label>
                <input
                  type="text"
                  className="form-control"
                  name="unit"
                  value={inputAddr.unit}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Street</label>
                <input
                  type="text"
                  className="form-control"
                  name="street"
                  value={inputAddr.street}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Postcode</label>
                <input
                  type="text"
                  className="form-control"
                  name="postcode"
                  value={inputAddr.postcode}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={inputAddr.city}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">State</label>
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  value={inputAddr.state}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">Country</label>
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  value={inputAddr.country}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-white"
                onClick={() => {
                  props.setShowPopup(false)
                  setInputAddr({
                    unit: "",
                    street: "",
                    postcode: "",
                    country: "",
                    state: "",
                    city: ""
                  })
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  // props.setAddress(
                  //   `${inputAddr.unit}, ${inputAddr.street}, ${inputAddr.postcode}, ${inputAddr.city}, ${inputAddr.state}, ${inputAddr.country}`
                  // );
                  props.setAddress(inputAddr)
                  props.setShowPopup(false);
                }}
                disabled={!inputAddr.unit || !inputAddr.street || !inputAddr.postcode || !inputAddr.country || !inputAddr.state || !inputAddr.city}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.showPopup && (
        <div className={`modal-backdrop ${props.showPopup ? "show" : ""} `}></div>
      )}
    </>
  );
};

export default AddressModal;
