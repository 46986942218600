export const NAME = "ZAKAT";

export const SUBMIT_ZAKAT = `${NAME}/SUBMIT_ZAKAT`;
export const SUBMIT_ZAKAT_SUCCESS = `${NAME}/SUBMIT_ZAKAT_SUCCESS`;
export const SUBMIT_ZAKAT_FAILED = `${NAME}/SUBMIT_ZAKAT_FAILED`;

export const GET_ZAKAT_OPTIONS = `${NAME}/GET_ZAKAT_OPTIONS`;
export const GET_ZAKAT_OPTIONS_SUCCESS = `${NAME}/GET_ZAKAT_OPTIONS_SUCCESS`;
export const GET_ZAKAT_OPTIONS_FAILED = `${NAME}/GET_ZAKAT_OPTIONS_FAILED`;

export const submitZakat = (data, callback) => ({
  type: SUBMIT_ZAKAT,
  data,
  callback
});

export const submitZakatSuccess = data => ({
  type: SUBMIT_ZAKAT_SUCCESS,
  data
});

export const submitZakatFailed = error => ({
  type: SUBMIT_ZAKAT_FAILED,
  error
});

export const getZakatOptions = () => ({
  type: GET_ZAKAT_OPTIONS
});

export const getZakatOptionsSuccess = data => ({
  type: GET_ZAKAT_OPTIONS_SUCCESS,
  data
});

