import React from 'react';
import LandTaxPayment from '../../components/Pay/LandTaxPayment'
import ParkingCompoundPayment from '../../components/Pay/ParkingCompoundPayment'
import OtherCompoundPayment from '../../components/Pay/OtherCompoundPayment'
import {useParams, Link} from "react-router-dom";

import BackButton from "../../components/BackButton"

const PaySelection = ({navigation, location, history}) => {
    let { payment_type } = useParams()

    React.useEffect(() => {
      if (location.state === undefined) {
        history.push('/pay')
      }
    })

    return (
        <div id="content" className="flex">
            <div className="h-100 d-flex padding" id="page-container">
                <div className="block flex mb-0">
                    <div className="padding pb-0 d-flex align-items-center">
                        <BackButton history={history} />
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-white mb-0 p-0">
                                <li class="breadcrumb-item"><Link to="/pay">Pay Pahang</Link></li>
                                <li class="breadcrumb-item"><Link to="/pay/mpk">Payment Type</Link></li>
                                <li class="breadcrumb-item active" aria-current="page">Assessment Tax</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="page-title padding pb-0 ">
                        <h2 className="text-md mb-0">Check & Pay</h2>
                    </div>
                    <div className="padding">
                        {payment_type === 'land_tax' && (
                            <LandTaxPayment location={location} history={history}/>
                        )}
                        
                        {payment_type === 'parking_compound' && (
                            <ParkingCompoundPayment location={location}/>
                        )}

                        {payment_type === 'other_compound' && (
                            <OtherCompoundPayment location={location} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )

    // return (
    //     <div className="content pay">
    //         <h1 className="content-title">Check & Pay</h1>

    //         <div className="payment-form-wrap partial-dropdown">
    //             {payment_type === 'land_tax' && (
    //                 <LandTaxPayment location={location}/>
    //             )}
                
    //             {payment_type === 'parking_compound' && (
    //                 <ParkingCompoundPayment location={location}/>
    //             )}

    //             {payment_type === 'other_compound' && (
    //                 <OtherCompoundPayment/>
    //             )}
    //         </div>

    //     </div>
    // );
};

export default PaySelection;