import React from 'react';
import {Link} from "react-router-dom";

const NotFound = () => {
    return (
        <div className="d-flex flex align-items-center text-center h-v info theme">
            <div className="text-center p-5 w-100">
            <h1 className="display-5 text-center my-5">Sorry! the page you are looking for doesn't exist.</h1>
            <p className="text-center">Go back to <Link to="/home" className="b-b b-white">home</Link>.</p>
            <p className="my-5 text-center text-muted h4">
                -- 404 --
            </p>
            </div>
        </div>
    );
};

export default NotFound;