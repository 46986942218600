import React from 'react';
import LandTaxDropdown from '../../components/Pay/LandTaxDropdown'
import ParkingCompoundDropdown from '../../components/Pay/ParkingCompoundDropdown'
import OtherCompoundDropdown from '../../components/Pay/OtherCompoundDropdown'
import {useParams, Link} from "react-router-dom";

import BackButton from "../../components/BackButton"

const PaySelection = ({location, history}) => {
    const [active, setActive] = React.useState(0)
    let { type } = useParams()

    React.useEffect(() => {
      if (location.state === undefined) {
        history.push('/pay')
      }
    })
    // console.log(props)

    // return (
    //     <div className="content pay">
    //         <h1 className="content-title">Check & Pay</h1>

    //         <div className="type-row">
    //             <div className="logo-wrap">
    //                 <img src={location.state.icon} alt="" />
    //             </div>
    //             {location.state.pbtid === "ptg" ? (
    //                 <p>{location.state.pbttitle}</p>
    //             ) : (
    //                 <p>Majlis Perbandaran {location.state.pbttitle}</p>
    //             )}
    //         </div>

    //         <div className="selection-wrap">
    //             {location.state.cukai && <div
    //                 className={`selection ${active === 0 ? 'active' : ''}`}
    //                 onClick={() => setActive(0)}
    //             >
    //                 <div className="selection-content">
    //                     <img src={require(`../../assets/images/cukai.png`)} alt="" />
    //                     <p>Cukai Taksiran</p>
    //                 </div>
    //             </div>}
    //            {location.state.parking && location.state.pbtid && <div
    //                 className={`selection ${active === 1 ? 'active' : ''}`}
    //                 onClick={() => setActive(1)}
    //             >
    //                 <div className="selection-content">
    //                     <img src={require(`../../assets/images/parking.png`)} alt="" />
    //                     <p>Kompaun Parking</p>
    //                 </div>
    //             </div>}
    //            {location.state.pelbagai && <div
    //                 className={`selection ${active === 2 ? 'active' : ''}`}
    //                 onClick={() => setActive(2)}
    //             >
    //                 <div className="selection-content">
    //                     <img src={require(`../../assets/images/others.png`)} alt="" />
    //                     <p>Kompaun Pelbagai</p>
    //                 </div>
    //             </div>}
    //         </div>

    //         {active === 0 && (
    //             // <div className="selection-dropdown partial-dropdown">
    //             // </div>
    //             <LandTaxDropdown pbtid={location.state.pbtid} history={history} manual={location.state.manual}/>
    //         )}

    //         {active === 1 && (
    //             // <div className="selection-dropdown partial-dropdown">
    //             // </div>
    //             <ParkingCompoundDropdown pbtid={location.state.pbtid} history={history} manual={location.state.manual} />
    //         )}

    //         {active === 2 && (
    //             <OtherCompoundDropdown pbtid={location.state.pbtid} history={history} manual={location.state.manual} />
    //             // <div className="selection-dropdown partial-dropdown">
    //             // </div>
    //         )}
    //     </div>
    // );

    return (
        <div id="content" className="flex">
            <div className="h-100 d-flex padding">
                <div className="flex block mb-0">
                {/* <div className="padding pb-0">
                    
                </div> */}
                <div className="padding pb-0 d-flex align-items-center">
                    <BackButton history={history} />
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb bg-white mb-0 p-0">
                            <li class="breadcrumb-item"><Link to="/pay">Pay Pahang</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">Payment Type</li>
                        </ol>
                    </nav>
                </div>
                <div className="page-title padding pb-0 ">
                    <h2 className="text-md mb-0">Check & Pay</h2>
                </div>
                {/* <div className="btn-group padding pb-0">
                    <button className="btn btn-white">Pay Pahang</button>
                    <button className="btn btn-secondary-lt" disabled>Payment Type</button>
                </div> */}
                <div className="padding">
                    <div className="row m-0 align-items-center">
                        <span className="avatar shadow shadow-md w-36 bg--white">
                            <img src={location.state.icon} alt="" />
                        </span>
                        <p className="text-muted ml-2 mb-0">
                        {location.state.pbtid === "ptg" ? (
                            location.state.pbttitle
                        ) : (
                            `Majlis Perbandaran ${location.state.pbttitle}`
                        )}    
                        </p>
                    </div>
                    <div className="row mt-4">
                        {/* <div className="col p-0"> */}
                            {location.state.cukai && <div className="col-auto" onClick={() => setActive(0)}>
                                <div className={`card border ${active === 0 ? 'border-primary' : ''} interactive pointer`}>
                                    <div className="p-4 text-center">
                                        <img src={require(`../../assets/images/cukai.png`)} style={{width: "30px"}} alt="" />
                                        <p className="text-center mb-0 mt-2">Assessment Tax</p>
                                    </div>
                                </div>
                            </div>}
                            {location.state.parking && location.state.pbtid && <div className="col-auto" onClick={() => setActive(1)}>
                                <div className={`card border ${active === 1 ? 'border-primary' : ''} interactive pointer`}>
                                    <div className="p-4 text-center">
                                        <img src={require(`../../assets/images/parking.png`)} style={{width: "30px"}} alt="" />
                                        <p className="text-center mb-0 mt-2">Parking Compound</p>
                                    </div>
                                </div>
                            </div>}
                            {location.state.pelbagai && <div className="col-auto" onClick={() => setActive(2)}>
                                <div className={`card border ${active === 2 ? 'border-primary' : ''} interactive pointer`}>
                                    <div className="p-4 text-center">
                                        <img src={require(`../../assets/images/others.png`)} style={{width: "30px"}} alt="" />
                                        <p className="text-center mb-0 mt-2">Other Compounds</p>
                                    </div>
                                </div>
                            </div>}
                        {/* </div> */}
                    </div>
                    {active === 0 && <LandTaxDropdown pbtid={location.state.pbtid} history={history} manual={location.state.manual} />}
                    {active === 1 && <ParkingCompoundDropdown pbtid={location.state.pbtid} history={history} manual={location.state.manual} />}
                    {active === 2 && <OtherCompoundDropdown pbtid={location.state.pbtid} history={history} manual={location.state.manual} />}
                </div>
            </div>
            </div>
        </div>
    );
};

export default PaySelection;