export const NAME = 'UTILITIES'

export const GET_UTILITIES_LIST = `${NAME}/GET_UTILITIES_LIST`;

export const GET_UTILITIES_LIST_SUCCESS = `${NAME}/GET_UTILITIES_LIST_SUCCESS`;

export const GET_UTILITIES_LIST_FAILED = `${NAME}/GET_UTILITIES_LIST_FAILED`;


export const getUtilitiesList = () => ({
    type: GET_UTILITIES_LIST
});

export const getUtilitiesListSuccess = (data) => ({
    type: GET_UTILITIES_LIST_SUCCESS,
    data
});

export const getUtilitiesListFailed = () => ({
    type: GET_UTILITIES_LIST_FAILED
});
