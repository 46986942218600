import { combineReducers } from "redux";

import login from "./login";
import register from "./register";
import otp from "./otp";
// import sendOTP from "./sendOTP";
// import resendOTP from "./resendOTP";
import logout from "./logout";
import userExistence from "./userExistence";
import reset from "./reset";

export default combineReducers({
  login,
  register,
  otp,
//   sendOTP,
//   resendOTP,
  logout,
  userExistence,
  reset
});
