import React from 'react';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux"

import Actions from "../../actions"

import LoadingOverlay from "../../components/LoadingOverlay"

import FavouriteModal from "../../components/FavouriteModal"

const OtherCompoundDropdown = props => {
    const [favouriteActive, setFavouriteActive] = React.useState(null);
    const [favouriteModal, setFavouriteModal] = React.useState(false)
    const [number, setNumber] = React.useState("")
    const {fav_accounts} = useSelector(state => state.FAVOURITE.list)
    const dispatch = useDispatch()
    const [input, setInput] = React.useState({
        name: "",
        ic: "",
        compound: "",
        company: "",
        amount: ""
    })
    const {isLoading, error, data} = useSelector(state => state.FEEDERS.getOtherCompoundList)

    const handleSearch = () => {
        if (!props.manual) {
            const paramString = `${props.pbtid}/compound/other?query=${input.compound}&query_type=1`;
            dispatch(Actions.getOtherCompoundList({paramString}))
            return
        }
        
        props.history.push({pathname: '/other_compound/pay', state: {
            paymentData: [
                {
                    title: "Name",
                    value: input.name
                },
                {
                    title: "MyKad Number",
                    value: input.ic
                },
                {
                    title: "Compound Number",
                    value: input.compound
                },
                {
                    title: "Company Number",
                    value: input.company
                },
            ],
            name: input.name,
            ic: input.ic,
            compound: input.compound,
            company: input.company,
            amount: input.amount,
            manual: props.manual,
            id: props.pbtid
        }})
    }

    React.useEffect(() => {
        dispatch(Actions.fetchListFavourites(props.pbtid, "other"))
    }, [])

    const handleChange = e => {
        const {name, value} = e.target
        setInput(state => ({...state, [name]: value}))
    }

    React.useEffect(() => {
        if (!isLoading && Array.isArray(data)) {
            if (data.length > 0) {
                data.pbtid = props.pbtid
                props.history.push({pathname: '/pay/kuantan/other_compound/table', state: data})
            } else if (error) {
                alert("Lolo")
            } else {
                alert("Lulu")
            }
            // if (Object.keys(data).length > 0) {
            //     // props.history.push({pathname: `/pay/${props.pbtid}/land_tax/form`, state: data})
            //     alert("lala")
            // } else if (error) {
            //     alert("Lolo")
            // } else {
            //     alert("Lulu")
            // }
        }
    }, [isLoading, data, error])

    return (
        <div className="row">
            {isLoading && <LoadingOverlay />}
            <FavouriteModal slug={props.pbtid} category='other' favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} label="Compound / company number" />
            <div className="col-sm-4 col-md-8">
                {/* <div className="card border shadow-md">
                    <div className="p-4"> */}
                    {props.manual && (
                        <>
                            <div className="form-group">
                                <label htmlFor="">Name</label>
                                <input type="text" className="form-control" name="name" onChange={handleChange} value={input.name} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">MyKad Number</label>
                                <input type="number" className="form-control" name="ic" onChange={handleChange} value={input.ic} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Compound Number</label>
                                <input type="number" className="form-control" name="compound" onChange={handleChange} value={input.compound} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Company Number</label>
                                <input type="number" className="form-control" name="company" onChange={handleChange} value={input.company} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <input type="number" className="form-control" name="amount" onChange={handleChange} value={input.amount} />
                            </div>
                        </>
                    )}
                    {!props.manual && (
                    <>
                        <div className="form-group">
                            <label htmlFor="">Compound / Company Number</label>
                            <input type="text" className="form-control" name="compound" value={input.compound} disabled={favouriteActive !== null} onChange={handleChange} />
                        </div>
                        <div className="row m-0 justify-content-between">
                            <p className="text-muted">My Favourite</p>
                            <div className="i-con-h-a" onClick={() => setFavouriteModal(true)}>
                            <i className="i-con i-con-plus"><i></i></i>
                            </div>
                        </div>
                        <div className="row row-sm">
                            <div className="col">
                            {fav_accounts.hasOwnProperty(`${props.pbtid}_other`) && fav_accounts[`${props.pbtid}_other`].map((fav, i) => (
                                <div className={`card border no-shadow i-con-h-a pointer ${favouriteActive === i ? 'border-primary' : ''}`} onClick={() => {
                                    setFavouriteActive(favouriteActive === null ? i : favouriteActive === i ? null : i)
                                    setInput(state => favouriteActive === null ? ({...state, compound: fav.phoneNumber}) : favouriteActive === i ? ({...state, compound: ""}) : ({...state, compound: fav.phoneNumber}))
                                }}>
                                <div className="p-2">
                                    <div className="row row-sm">
                                    <div className="col-auto">
                                    <i className="i-con i-con-ok"><i></i></i>
                                    </div>
                                    <div className="col">
                                        {fav.name} - {fav.phoneNumber}
                                    </div>
                                    <div className="col-auto i-con-h-a">
                                        <i className={`i-con i-con-trash`} onClick={(e) => {
                                        e.stopPropagation()
                                        dispatch(Actions.deleteFavourite(props.pbtid, fav.name, "other"))
                                        }}><i></i></i>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </>
                    )}
                    <button className="btn btn-primary w-100 mt-2" disabled={!props.manual ? !input.compound : !input.name || !input.compound || !input.company || !input.ic || !input.amount} onClick={handleSearch}>NEXT</button>
                    {/* </div>
                </div> */}
            </div>
        </div>
    );

    // return (
    //     <div className="content-form other-compound">
    //         {isLoading && <LoadingOverlay />}
    //         <div className="form-wrapper">
    //             {props.manual && (
    //                 <>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Name</label>
    //                         <input type="text" placeholder="Name" name="name" onChange={handleChange} value={input.name} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">MyKad Number</label>
    //                         <input type="text" placeholder="Name" name="ic" onChange={handleChange} value={input.ic} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Compound Number</label>
    //                         <input type="text" placeholder="Name" name="compound" onChange={handleChange} value={input.compound} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Company Number</label>
    //                         <input type="text" placeholder="Name" name="company" onChange={handleChange} value={input.company} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="">Amount</label>
    //                         <div className="amount-input">
    //                             <h4>RM</h4>
    //                             {/* <Field type="text" placeholder="0" name="amount" /> */}
    //                             <input type="text" name="amount" placeholder="0" onChange={handleChange} value={input.amount} />
    //                         </div>
    //                     </div>
    //                 </>
    //             )}

    //             {!props.manual && (
    //                 <>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Compound / Company Number</label>
    //                         <input type="text" placeholder="Enter compound/company number" onChange={e => setNumber(e.target.value)} name="name" value={number} />
    //                     </div>

    //                     <div className="favourite-holder">
    //                         <h4>My Favourite</h4>
    //                         <div className="holder" onClick={() => setFavouriteActive(favouriteActive === null ? 0 : null)}>
    //                             <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt="" />
    //                             <p>My favourite phone - 01244445423</p>
    //                         </div>
    //                         <div className="holder" onClick={() => setFavouriteActive(favouriteActive === null ? 1 : null)}>
    //                             <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt="" />
    //                             <p>Mom - 01244445423</p>
    //                         </div>
    //                     </div>
    //                 </>
    //             )}

    //             {/* <Link to="/pay/kuantan/other_compound/table"> */}
    //                 <button onClick={handleSearch}>{props.manual ? 'Next' : 'Search'}</button>
    //             {/* </Link> */}
    //         </div>
    //     </div>
    // );
};

export default OtherCompoundDropdown;