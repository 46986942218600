import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getCompoundList({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.getCompoundList, headers, data.paramString);
    if (response) {
        yield put(Actions.getCompoundListSuccess(response.data.data));
    } else {
        if (error.code === "ECONNABORTED") {
            return yield put(Actions.getCompoundListFail(error))
        }
        yield put(Actions.getCompoundListFail(error.response.data));
    }
}

function* watchGetCompoundList() {
    yield takeLatest(Actions.GET_COMPOUND_LIST, getCompoundList);
}

export default function* submit() {
    yield all([
        fork(watchGetCompoundList)
    ])
}