export const NAME = 'POSTPAID'

export const GET_POSTPAID_LIST = `${NAME}/GET_POSTPAID_LIST`;

export const GET_POSTPAID_LIST_SUCCESS = `${NAME}/GET_POSTPAID_LIST_SUCCESS`;

export const GET_POSTPAID_LIST_FAILED = `${NAME}/GET_POSTPAID_LIST_FAILED`;


export const getPostpaidList = () => ({
    type: GET_POSTPAID_LIST
});

export const getPostpaidListSuccess = (data) => ({
    type: GET_POSTPAID_LIST_SUCCESS,
    data
});

export const getPostpaidListFailed = () => ({
    type: GET_POSTPAID_LIST_FAILED
});
