import { combineReducers } from 'redux';

import utilities from './utilities';
import prepaid from "./prepaid";
import postpaid from "./postpaid"
import product from "./product"
import bill from "./bill"

export default combineReducers({
  utilities,
  prepaid,
  postpaid,
  product,
  bill
})