export const NAME = "HISTORY";

export const HISTORY = `${NAME}/HISTORY`;
export const HISTORY_SUCCESS = `${NAME}/HISTORY_SUCCESS`;
export const HISTORY_FAILED = `${NAME}/HISTORY_FAILED`;
export const RESET_HISTORY = `${NAME}/RESET_HISTORY`;

export const getHistoryData = store => store[NAME].history;

export const history = data => ({
  type: HISTORY,
  data
});

export const historySuccess = data => ({
  type: HISTORY_SUCCESS,
  data
});

export const historyFailed = error => ({
  type: HISTORY_FAILED,
  error
});

export const resetHistory = () => ({
  type: RESET_HISTORY
});
