export const NAME = "FEEDERS";

export const GET_OTHER_COMPOUND_LIST = `${NAME}/GET_OTHER_COMPOUND_LIST`;
export const GET_OTHER_COMPOUND_LIST_SUCCESS = `${NAME}/GET_OTHER_COMPOUND_LIST_SUCCESS`;
export const GET_OTHER_COMPOUND_LIST_FAIL = `${NAME}/GET_OTHER_COMPOUND_LIST_FAIL`;
export const RESET_GET_OTHER_COMPOUND_LIST = `${NAME}/RESET_GET_OTHER_COMPOUND_LIST`;

export const getOtherCompoundListData = store =>
  store[NAME].getOtherCompoundList;

export const getOtherCompoundList = data => ({
  type: GET_OTHER_COMPOUND_LIST,
  data
});

export const getOtherCompoundListSuccess = data => ({
  type: GET_OTHER_COMPOUND_LIST_SUCCESS,
  data
});

export const getOtherCompoundListFail = error => ({
  type: GET_OTHER_COMPOUND_LIST_FAIL,
  error
});

export const resetGetOtherCompoundList = () => ({
  type: RESET_GET_OTHER_COMPOUND_LIST
});
