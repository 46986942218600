import React from "react";
import {useSelector, useDispatch} from "react-redux"

import Actions from "../../actions";
import * as api from "../../api"

import FavouriteModal from "../../components/FavouriteModal"

const Prepaid = props => {
  const [cardActive, setCardActive] = React.useState(null);
  const [amountActive, setAmountActive] = React.useState(null);
  const [favouriteActive, setFavouriteActive] = React.useState(null);
  const [favouriteModal, setFavouriteModal] = React.useState(false)
  const [number, setNumber] = React.useState("");
  const {fav_accounts} = useSelector(state => state.FAVOURITE.list)
  const {data: {auth_token}} = useSelector(state => state.PROFILE.userSession)
  const [slug, setSlug] = React.useState(null)
  const [amount, setAmount] = React.useState(0)
  const dispatch = useDispatch()
  const [error, setError] = React.useState("");
  const {list} = useSelector(state => state.IIMMPACT.prepaid)

  React.useEffect(() => {
    dispatch(Actions.getPrepaidList())
  }, [])

  React.useEffect(() => {
    console.log(list)
  }, [list])

  const handleNext = () => {
    // const cardArray = ['YES', 'DiGi', 'Maxis', 'XPAX', 'Umobile', 'Tune Talk', 'XOX', 'ALTEL'];
    // const amountArray = ['RM30.00', 'RM50.00', 'RM100.00'];
    // const favouriteArray = ['0124444543', '0124423322']

    console.log(list[cardActive].merchant_code)
    const payload = {
      account_no: number,
      product_code: list[cardActive].merchant_code
    }

    dispatch(Actions.validateProduct(payload, () => {
      props.history.push({
        pathname: '/prepaid/pay',
        state: {card: list[cardActive].name, amount: amount, number: number, merchant_code: list[cardActive].merchant_code}
      })
    }, () => {
      setError(`This number is not valid for ${list[cardActive].name}`)
    }))

  }

  // return (
  //   <div className="content home">
  //     <h1 className="content-title">Prepaid</h1>
  //     {favouriteModal && <FavouriteModal slug={slug} handleClose={() => setFavouriteModal(false)} />}
  //     <div className="content-card">
  //       <h4>Select Telco</h4>

  //       {list.length === 0 && (
  //         <div className="loading_holder">
  //           <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  //         </div>
  //       )}

  //       {list.length > 0 && (
  //         <div className="card-holder">
  //           {list.map((l, i) => (
  //             <div
  //             key={l.id}
  //             className={cardActive === i ? "active" : ""}
  //             onClick={async () => {
  //               setCardActive(null)
  //               var headers = { Authorization: `Bearer ${auth_token}` }
  //               const {response: {data: {data}}} = await api.getMerchantsById(headers, l.merchant_id)
  //               setCardActive(cardActive !== null ? cardActive === i ? null : i : i)
  //               setSlug(data.slug)
  //               dispatch(Actions.fetchListFavourites(data.slug, null))
  //             }}
  //             >
  //               <img
  //                 src={l.picture}
  //                 style={{ marginTop: "10px" }}
  //                 alt=""
  //               />
  //               <p>{l.name.split(" ")[0]}</p>
  //             </div>
  //           ))}
  //           {/* <div
  //             className={cardActive === 0 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 0 ? null : 0 : 0)}
  //           >
  //             <img
  //               src={require("../../assets/images/yes-logo@2x.png")}
  //               style={{ marginTop: "10px" }}
  //               alt=""
  //             />
  //             <p>YES</p>
  //           </div>
  //           <div
  //             className={cardActive === 1 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 1 ? null : 1 : 1)}
  //           >
  //             <img src={require("../../assets/images/digi-logo@2x.png")} alt="" />
  //             <p>DiGi</p>
  //           </div>
  //           <div
  //             className={cardActive === 2 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 2 ? null : 2 : 2)}
  //           >
  //             <img
  //               src={require("../../assets/images/maxis-logo@2x.png")}
  //               alt=""
  //             />
  //             <p>Maxis</p>
  //           </div>
  //           <div
  //             className={cardActive === 3 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 3 ? null : 3 : 3)}
  //           >
  //             <img src={require("../../assets/images/xpax-logo@2x.png")} alt="" />
  //             <p>XPAX</p>
  //           </div>
  //           <div
  //             className={cardActive === 4 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 4 ? null : 4 : 4)}
  //           >
  //             <img
  //               src={require("../../assets/images/umobile-logo@2x.png")}
  //               alt=""
  //             />
  //             <p>Umobile</p>
  //           </div>
  //           <div
  //             className={cardActive === 5 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 5 ? null : 5 : 5)}
  //           >
  //             <img
  //               src={require("../../assets/images/tunetalk-logo@2x.png")}
  //               alt=""
  //             />
  //             <p>Tune Talk</p>
  //           </div>
  //           <div
  //             className={cardActive === 6 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 6 ? null : 6 : 6)}
  //           >
  //             <img
  //               src={require("../../assets/images/xox-logo@2x.png")}
  //               style={{ marginTop: "10px" }}
  //               alt=""
  //             />
  //             <p>XOX</p>
  //           </div>
  //           <div
  //             className={cardActive === 7 ? "active" : ""}
  //             onClick={() => setCardActive(cardActive !== null ? cardActive === 7 ? null : 7 : 7)}
  //           >
  //             <img
  //               src={require("../../assets/images/altel-logo@2x.png")}
  //               alt=""
  //             />
  //             <p>ALTEL</p>
  //           </div> */}
  //         </div>
  //       )}
  //     </div>
  //     {cardActive !== null && (
  //       <div className="content-form">
  //       <div className="form-wrapper">
  //         <div className={`input-holder ${favouriteActive !== null ? 'hidden' : ''}`}>
  //           <label htmlFor="">Phone Number</label>
  //           <input type="text" placeholder="Enter phone number" disabled={favouriteActive !== null} onChange={e => setNumber(e.target.value)} />
  //         </div>
  //         <div className="amount-holder">
  //           {list[cardActive].denomination.split(',').map((d, i) => (
  //             <div
  //               className={amountActive === i ? "active" : ""}
  //               onClick={() => {
  //                 setAmountActive(amountActive !== null ? amountActive === i ? null : i : i)
  //                 setAmount(d)
  //               }}
  //             >
  //               RM{d}
  //             </div>
  //           ))}
  //           {/* <div
  //             className={amountActive === 0 ? "active" : ""}
  //             onClick={() => setAmountActive(amountActive !== null ? amountActive === 0 ? null : 0 : 0)}
  //           >
  //             RM30
  //           </div>
  //           <div
  //             className={amountActive === 1 ? "active" : ""}
  //             onClick={() => setAmountActive(amountActive !== null ? amountActive === 1 ? null : 1 : 1)}
  //           >
  //             RM50
  //           </div>
  //           <div
  //             className={amountActive === 2 ? "active" : ""}
  //             onClick={() => setAmountActive(amountActive !== null ? amountActive === 2 ? null : 2 : 2)}
  //           >
  //             RM100
  //           </div> */}
  //         </div>
  //         <div className={`favourite-holder ${number ? 'hidden' : ''}`}>
  //           <div className="favourite-title">
  //             <h4>My Favourite</h4>
  //             <img src={require('../../assets/images/add.png')} onClick={() => setFavouriteModal(true)} alt=""/>
  //           </div>
  //           {fav_accounts.hasOwnProperty(slug) && fav_accounts[slug].map(fav => (
  //             <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
  //               <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //               <p>{fav.name} - {fav.phoneNumber}</p>
  //             </div>
  //           ))}
  //           {/* <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
  //             <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //             <p>My favourite phone - 0124444543</p>
  //           </div>
  //           <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 1 : favouriteActive === 1 ? null : 1)}>
  //             <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //             <p>Mom - 0124423322</p>
  //           </div> */}
  //         </div>
  //         <button onClick={handleNext}>NEXT</button>
  //       </div>
  //     </div>
  //     )}
  //     </div>
  // );
  
  return (
    <div id="content" className="flex">
      <FavouriteModal slug={slug} favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} label="Phone number" />
      <div className="h-100 d-flex padding" id="page-container">
        <div className="block flex mb-0">
        <div className="page-title padding pb-0 ">
            <h2 className="text-md mb-0">Prepaid</h2>
        </div>
        <div className="padding">
          <div className="row">
            <div className="col-sm-4 col-md-8">
              {/* <div className="card shadow-md border"> */}
                <div className="text-muted">Select telco</div>
                <div className="pt-4">
                    {list.length === 0 && (
                        <div className="text-center d-flex py-4 justify-content-center">
                            <div className="loading w-48"></div>
                        </div>
                    )}
                  <div className="row">
                    {list.map((l, i) => (
                      <div className="col-3" key={l.id}>
                        <div className={`card pointer border no-shadow ${cardActive === i ? "border-primary" : ""} interactive`} onClick={async () => {
                        setCardActive(null)
                        var headers = { Authorization: `Bearer ${auth_token}` }
                        const {response: {data: {data}}} = await api.getMerchantsById(headers, l.merchant_id)
                        setCardActive(cardActive !== null ? cardActive === i ? null : i : i)
                        setAmountActive(null)
                        setNumber("")
                        setError("")
                        setSlug(data.slug)
                        dispatch(Actions.fetchListFavourites(data.slug, null))
                      }
                      }>
                          <div className="p-3 text-center">
                            <img height={50} src={l.picture} alt=""/>
                            <p className="text-center m-2">{l.name.split(" ")[0]}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              {/* </div> */}
            </div>
          </div>
          {cardActive !== null && (
            <div className="row">
              <div className="col-sm-4 col-md-8">
                  {/* <div className="card shadow-md border"> */}
                    {/* <div className="p-4"> */}
                      <div className="form-group">
                        <label htmlFor="">Phone Number</label>
                        <input type="number" placeholder="0123456789" className={`form-control ${error ? 'is-invalid' : ''}`} disabled={favouriteActive !== null} value={number} onChange={e => setNumber(e.target.value)} />
                        {error && <ul className="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
                        <li className="parsley-required">{error}</li>
                        </ul>}
                      </div>
                      <div className="row">
                        {list[cardActive].denomination.split(',').map((d, i) => (
                          <div className="col-auto">
                            <div className={`card text-center no-shadow border pointer ${amountActive === i ? "bg-primary" : "bg-light--lt"} interactive`} onClick={() => {
                              setAmountActive(amountActive !== null ? amountActive === i ? null : i : i)
                              setAmount(d)
                            }}>
                              <div className="p-2">
                                RM {d}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="row m-0 justify-content-between">
                        <p className="text-muted">My Favourite</p>
                        <div className="i-con-h-a" onClick={() => setFavouriteModal(true)}>
                          <i className="i-con i-con-plus"><i></i></i>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col">
                          {fav_accounts.hasOwnProperty(slug) && fav_accounts[slug].map((fav, i) => (
                            <div className={`card border no-shadow pointer interactive ${favouriteActive === i ? 'border-primary' : ''}`} onClick={() => {
                              setFavouriteActive(favouriteActive === null ? i : favouriteActive === i ? null : i)
                              setNumber(favouriteActive === null ? fav.phoneNumber : favouriteActive === i ? null : fav.phoneNumber)
                            }}>
                              <div className="p-2">
                                <div className="row row-sm">
                                  <div className="col-auto i-con-h-a">
                                  <i className={`i-con i-con-ok ${favouriteActive === i ? 'text-primary' : ''}`}><i></i></i>
                                  </div>
                                  <div className="col">
                                    {fav.name} - {fav.phoneNumber}
                                  </div>
                                  <div className="col-auto i-con-h-a">
                                    <i className={`i-con i-con-trash`} onClick={(e) => {
                                      e.stopPropagation()
                                      dispatch(Actions.deleteFavourite(slug, fav.name, null))
                                    }}><i></i></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button className="btn btn-primary w-100 mt-2" disabled={!number || amountActive === null} onClick={handleNext}>Next</button>
                    {/* </div>
                  </div> */}
              </div>
            </div>
          )}
          </div>
      </div>
    </div>

    </div>
  )
};

export default Prepaid;
