import React from 'react';
import { Link } from 'react-router-dom';

const ParkingCompoundTable = ({location}) => {
    console.log(location)
    
    const formatCurrency = d => {
        let t = d / 100
        return `${t.toFixed(2)}`
    }

    return (
        <div className="pt-4">
            <div className="table-responsive">
            <table className="table table-theme table-row v-middle">
                <thead>
                <tr>
                    <th className="text-muted">Plat Number</th>
                    <th className="text-muted">Compound Code</th>
                    <th className="text-muted">Reason</th>
                    <th className="text-muted">Amount(RM)</th>
                    <th className="text-muted"></th>
                </tr>
                </thead>
                <tbody>
                    {location.state.map(data => {
                        data.pbtid = location.state.pbtid
                        return (
                            <tr className="v-middle">
                                <td>{data.registration_number}</td>
                                <td>{data.service_reference_1}</td>
                                <td>{data.violation_type}</td>
                                <td>{formatCurrency(data.compound_amount)}</td>
                                <td>
                                    <Link to={{pathname: '/pay/kuantan/parking_compound/form', state: data}}>
                                        <button className="btn btn-primary w-100">PAY</button>
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
        </div>
    )

    // return (
    //     <div className="compound-payment-form">
    //         <div className="table-wrapper">
    //             <table>
    //                 <thead>
    //                     <tr>
    //                         <th>Plat Number</th>
    //                         <th>Compound Code</th>
    //                         <th>Reason</th>
    //                         <th>Amount(RM)</th>
    //                         <th></th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {location.state.map(data => {
    //                         data.pbtid = location.state.pbtid
    //                         return <tr>
    //                             <td>{data.registration_number}</td>
    //                             <td>{data.service_reference_1}</td>
    //                             <td>{data.violation_type}</td>
    //                             <td>{formatCurrency(data.compound_amount)}</td>
    //                             <td>
    //                                 <Link to={{pathname: '/pay/kuantan/parking_compound/form', state: data}}>
    //                                     <div className="compound-pay-button">
    //                                         <p>PAY</p>
    //                                     </div>
    //                                 </Link>
    //                             </td>
    //                         </tr>
    //                     })}
    //                     {/* <tr>
    //                         <td>CDC9268</td>
    //                         <td>L6024116789</td>
    //                         <td>Gagal mempamerkan kupon letak kereta yang sah</td>
    //                         <td>10</td>
    //                         <td>
    //                             <Link to="/pay/kuantan/parking_compound/form">
    //                                 <div className="compound-pay-button">
    //                                     <p>PAY</p>
    //                                 </div>
    //                             </Link>
    //                         </td>
    //                     </tr> */}
    //                 </tbody>
    //             </table>
    //         </div>
    //     </div>
    // );
};

export default ParkingCompoundTable;