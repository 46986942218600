import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";

const getSessionData = store => store.PROFILE.userSession;

function* addCardPaymentMethod({ data }) {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}` };

  const { response, error } = yield call(
    api.addCardPaymentMethod,
    data,
    headers
  );
  if (response) {
    yield put(Actions.addCardPaymentMethodSuccess(response.data));
  } else {
    yield put(Actions.addCardPaymentMethodFail(error.response.data));
  }
}

function* watchAddCardPaymentMethod() {
  yield takeLatest(Actions.ADD_CARD_PAYMENT_METHOD, addCardPaymentMethod);
}

export default function* submit() {
  yield all([fork(watchAddCardPaymentMethod)]);
}
