import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from "../../actions";

import * as api from '../../api';

function* checkUserExistence({ data }) {
    const { response, error } = yield call(api.checkUserExistence, { email: data.inputEmail });

    if (response) {
        yield put(Actions.checkUserExistenceSuccess(response.data.data));
    } else {
        yield put(Actions.checkUserExistenceFail(error.response.data));
    }
}

function* watchCheckUserExistence() {
    yield takeLatest(Actions.CHECK_USER_EXISTENCE, checkUserExistence)
}

export default function* submit() {
    yield all([
        fork(watchCheckUserExistence)
    ])
}