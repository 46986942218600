import React from 'react';
import {useDispatch} from "react-redux"

import Actions from "../actions"

const BackButton = ({history}) => {
    const dispatch = useDispatch()

    const handleBack = () => {
        history.goBack()
        dispatch(Actions.resetGetCompoundList())
        dispatch(Actions.resetGetAssessmentTax())
        dispatch(Actions.resetGetPaipList())
    }

    return (
        <button className="btn bg-secondary--lt i-con-h-a mr-4" onClick={handleBack}>
            <div className="d-flex align-items-center">
                <i className="i-con mr-1 i-con-up-left"><i></i></i>
                <p className="mb-0">Back</p>
            </div>
        </button>
    );
};

export default BackButton;