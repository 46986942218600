import React from 'react';
import PaipGraph from './PaipGraph';

const AccountStats = () => {
    return (
        <>
            <div className="top-data">
                <div className="row">
                    <div className="block">
                        <h3>Paip Pecah - Jalan Gambang, Kuantan</h3>
                        <p>
                            <span className="icon">
                                <img src={require('../../assets/images/location.png')} alt="" />
                            </span>
                            No.1, Jalan SS 2/32, ss2
                        </p>
                        <p>
                            <span className="icon">
                                <img src={require('../../assets/images/calendar.png')} alt="" />
                            </span>
                            22 April 2020, 01:00pm - 22 April 2020, 4:30 pm
                        </p>
                        <p className="read-more-link">Read More</p>
                    </div>
                    <div className="block">
                        <h3>Paip Pecah - Jalan Gambang, Kuantan</h3>
                        <p>
                            <span className="icon">
                                <img src={require('../../assets/images/location.png')} alt="" />
                            </span>
                            No.1, Jalan SS 2/32, ss2
                        </p>
                        <p>
                            <span className="icon">
                                <img src={require('../../assets/images/calendar.png')} alt="" />
                            </span>
                            22 April 2020, 01:00pm - 22 April 2020, 4:30 pm
                        </p>
                        <p className="read-more-link">Read More</p>
                    </div>
                </div>
                <div className="row">
                    <div className="block">
                        <div className="row">
                            <div className="left">
                                Payment Amount
                            </div>
                            <div className="right price">
                                RM 140.45
                            </div>
                        </div>
                        <div className="row">
                            <div className="left">
                                Bayar Sebelum
                            </div>
                            <div className="right">
                                <p>Bayar Sebelum 12/12/2020</p> 
                                <p>12 hari lagi (24/12/2020)</p> 
                            </div>
                        </div>
                        <div className="pay-button">
                            PAY
                        </div>
                    </div>
                    <div className="block">
                        <div className="graph-data">
                            <div className="water-usage graph-data-block">
                                <p className="title">Pengunaan Air (m3)</p>
                                <p className="total">65.0</p>
                                <div className="stats">
                                    <div className="arrow-down"/>
                                    <p>-14.00</p>
                                </div>
                            </div>
                            <div className="total-bill graph-data-block">
                                <p className="title">Jumlah Bil (RM)</p>
                                <p className="total">75.0</p>
                                <div className="stats">
                                    <div className="arrow-up"/>
                                    <p>+12.36</p>
                                </div>
                            </div>
                        </div>

                        <div className="graph-wrap">
                            <PaipGraph/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th className="left-align">Bill Issued</th>
                            <th className="right-align">Amount(RM)</th>
                            <th className="left-align">Last Payment</th>
                            <th className="left-align">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="left-align">12/11/2020</td>
                            <td className="right-align">
                                <p className="price">
                                    140.45
                                </p>
                            </td>
                            <td className="left-align">16/8/2020</td>
                            <td className="left-align">Bill Overdue</td>
                        </tr>
                    </tbody>
                </table>
            </div>            
        </>
    );
};

export default AccountStats;