import React from "react";
import {useDispatch} from "react-redux";
import { reduxForm, Field, SubmissionError, reset } from "redux-form";

import Actions from "../actions"

import TextInput from "./TextInput";

const FavouriteModal = props => {
  const dispatch = useDispatch();

  const handleSave = d => {
    var data = {
      phoneNumber: d.number,
      name: d.name,
      slug: props.slug,
      category: props.category,
      old_name: d.name
    }

    dispatch(Actions.addFavourite(data, () => {
      props.handleClose()
      dispatch(reset('favouriteForm'))
    }))
  }

  // return (
  //   <div className="favourite-modal">
  //     <div className="favourite-overlay"></div>
  //     <div className="favourite-content">
  //       <img src={require("../assets/images/close.png")} onClick={props.handleClose} alt="" />
  //       <h2>Add Favourite Account</h2>
  //       <form onSubmit={props.handleSubmit(handleSave)}>
  //         <div className="form-wrapper">
  //           <Field 
  //             component={TextInput}
  //             type="text"
  //             label="Favourite Name"
  //             name="name"
  //             placeholder="Enter favourite name"
  //           />
  //           <Field 
  //             component={TextInput}
  //             type="text"
  //             label="Account Number"
  //             name="number"
  //             placeholder="Enter account number"
  //           />
  //           <button>SAVE</button>
  //         </div>
  //       </form>
  //     </div>
  //   </div>
  // );

  return (
    <>
      <div className={`modal fade ${props.favouriteModal ? 'show' : ''}`} style={props.favouriteModal ? {display: 'block'} : {}}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add favourite account</h5>
            </div>
            <div className="modal-body m-lg">
              <Field
              component={TextInput}
              label="Favourite name"
              name="name"
              type="text"
              />
              <Field
              component={TextInput}
              label={props.label}
              type="text"
              name="number"
              />
            </div>
            <div className="modal-footer">
              <button className="btn btn-white" onClick={() => {
                props.handleClose()
                dispatch(reset('favouriteForm'))
              }}>Cancel</button>
              <button className="btn btn-primary" onClick={props.handleSubmit(handleSave)}>Add</button>
            </div>
          </div>
        </div>
      </div>
      {props.favouriteModal && <div className={`modal-backdrop ${props.favouriteModal ? 'show' : ''}`}></div>}
    </>
  )
};

export default reduxForm({
  form: "favouriteForm"
})(FavouriteModal)
// export default FavouriteModal;
