import * as paymentMethodOptions from "./paymentMethodOptions";
import * as addPaymentMethod from "./addPaymentMethod";
import * as deletePaymentMethod from "./deletePaymentMethod";
import * as getPaymentMethods from "./getPaymentMethods";
import * as setDefaultPaymentMethod from "./setDefaultPaymentMethod";
import * as paymentService from "./paymentService";
import * as addCardPaymentMethod from "./addCardPaymentMethod";
import * as addeWalletPaymentMethod from "./addeWalletPaymentMethod";
import * as verifyPayment from "./verifyPayment"

export default {
  ...paymentMethodOptions,
  ...addPaymentMethod,
  ...deletePaymentMethod,
  ...getPaymentMethods,
  ...setDefaultPaymentMethod,
  ...paymentService,
  ...addCardPaymentMethod,
  ...addeWalletPaymentMethod,
  ...verifyPayment
};
