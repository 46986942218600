import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment"
import DatePicker from "react-datepicker"
 
import "react-datepicker/dist/react-datepicker.css";

import Actions from "../actions";

import LoadingOverlay from "../components/LoadingOverlay"

const PaymentHistory = () => {
  const before = React.useRef(null)
  const dispatch = useDispatch();
  const [input, setInput] = React.useState({
      before: "",
      after: ""
  })
  const { isLoading, data, error } = useSelector(
    (state) => state.HISTORY.history
  );
  const exportData = useSelector(state => state.HISTORY.exportHistory)

  React.useEffect(() => {
    dispatch(Actions.history({"after": input.after ? moment(input.after).format('YYYY-MM-DD') : "", "before": !input.before ? "" : moment(input.before).format('YYYY-MM-DD')}));
  }, []);

  React.useEffect(() => {
    if (input.before !== "" && input.after !== "") {
      dispatch(Actions.history({"after": input.after ? moment(input.after).format('YYYY-MM-DD') : "", "before": !input.before ? "" : moment(input.before).format('YYYY-MM-DD')}))
    }
  }, [input])

  // React.useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const handleChange = e => {
    console.log(e)
      // const {name, value} = e.target

      // if (name === "after") {
      //   before.current.focus()
      //   before.current.focus()
      // }

      // setInput(state => ({...state, [name]: value}))
  }

  const handleFocus = e => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    
    if (!input[e]) {
      if (e === "after") {
        setInput(state => ({...state, [e]: firstDay}))
      } else {
        setInput(state => ({...state, [e]: date}))
      }
    }

    // e.target.type = "date"
  }

  const handleExport = () => {
      dispatch(Actions.exportHistory(input))
  }

  // return (
  //   <div className="content home">
  //     {isLoading && <LoadingOverlay />}
  //     <h1 className="content-title">Payment History</h1>

  //     {/* <div className="content-link">
  //             <div className="active">Pay</div>
  //             <div>Bills</div>
  //             <div>PAIP</div>
  //             <div>Parking</div>
  //             <div>Prepaid</div>
  //             <div>Postpaid</div>
  //             <div>Bus</div>
  //             <div>Zakat</div>
  //             <div>Education</div>
  //           </div> */}

  //     <div className="table-wrapper">
  //       <table>
  //         <thead>
  //           <tr>
  //             <th>Product</th>
  //             <th>Time</th>
  //             <th>Payment Method</th>
  //             <th>Amount</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {!isLoading &&
  //             !error &&
  //             data.length > 0 &&
  //             data.map((d) => (
  //               <tr key={d.id}>
  //                 <td>{d.title}</td>
  //                 <td>{d.created_at}</td>
  //                 <td>{d.resource.payment_method}</td>
  //                 <td>{`RM${d.resource.amount}`}</td>
  //               </tr>
  //             ))}
  //           {/* <tr>
  //             <td>Cukai Tafsiran</td>
  //             <td>24/08/17, 12:00pm - 4:00pm</td>
  //             <td>Hot Desk</td>
  //             <td>RM10</td>
  //           </tr>
  //           <tr>
  //             <td>Cukai Tafsiran</td>
  //             <td>24/08/17, 12:00pm - 4:00pm</td>
  //             <td>Hot Desk</td>
  //             <td>RM10</td>
  //           </tr>
  //           <tr>
  //             <td>Cukai Tafsiran</td>
  //             <td>24/08/17, 12:00pm - 4:00pm</td>
  //             <td>Hot Desk</td>
  //             <td>RM10</td>
  //           </tr> */}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );
  return (
    <div id="content" className="flex">
      {exportData.isLoading && <LoadingOverlay />}
      <div className="h-100 padding" id="page-container">
        {/* <div className="block flex mb-0"> */}
        <div className="page-title pb-0 d-flex align-items-center justify-content-between">
            <h2 className="text-md mb-0">Payment history</h2>
            <div className="toolbar d-flex align-items-center">
                {/* <select className="form-control w-auto">
                    <option value="">All types</option>
                    <option value="">This month</option>
                    <option value="">This year</option>
                </select> */}
                <DatePicker className="form-control" placeholderText="Date From" name="after" dateFormat="dd/MM/yyyy" selected={input.after} onChange={e => setInput(state => ({...state, "after": e}))} maxDate={new Date()} onFocus={e => handleFocus("after")} />
                {/* <input type="text" placeholder="Date From" onFocus={handleFocus} name="after" max={moment().format('YYYY-MM-DD')} className="form-control" onChange={handleChange} /> */}
                <p className="mb-0 separator">-</p>
                <DatePicker className="form-control" placeholderText="Date To" name="before" dateFormat="dd/MM/yyyy" selected={input.before} onChange={e => setInput(state => ({...state, "before": e}))} maxDate={new Date()} onFocus={e => handleFocus("before")} />
                {/* <input type="text" ref={before} placeholder="Date After" onFocus={handleFocus} name="before" max={moment().format('YYYY-MM-DD')} className="form-control" onChange={handleChange}/> */}
                <button className="btn btn-primary i-con-ha" disabled={!input.before || !input.after || data.length === 0} onClick={handleExport}>
                    <i className="i-con i-con-download mr-2"><i></i></i>
                    Export Statement
                </button>
                {/* <form className="flex" style={{width: '300px'}}>
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search..." />
                        <span className="input-group-append">
                            <button className="btn btn-white no-shadow i-con-ha">
                                <i className="i-con i-con-search text-muted"></i>
                            </button>
                        </span>
                    </div>
                </form> */}
            </div>
        </div>
        <div className="pt-4">
            <div className="table-responsive">
              {!isLoading &&
              !error &&
              data.length > 0 &&
              <table className="table table-theme table-row v-middle">
                <thead>
                  <tr>
                    <th className="text-muted">Description</th>
                    <th className="text-muted">Time</th>
                    <th className="text-muted">Payment method</th>
                    <th className="text-muted">District</th>
                    <th className="text-muted">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d) => (
                    <tr className="v-middle" key={d.id}>
                      <td>{d.description}</td>
                      <td>{d.created_at}</td>
                      <td>{d.payment_method ? d.payment_method.replace('_', ' ') : '-'}</td>
                      <td>{d.payment_method === "parking_token" ? d.district_name : '-'}</td>
                      <td>{`RM ${d.amount}`}</td>
                    </tr>
                  ))}
                </tbody>
              </table>}
                {isLoading && <div className="d-flex py-4 w-100 justify-content-center">
                    <div className="loading"></div>
                </div>}
                {!isLoading &&
                  !error &&
                  data.length === 0 && <div className="flex-grow-1 h-100">
                  <h2 className="text-center mt-5 text-md">No result found</h2>
                </div>}
            </div>
          </div>
        </div>
      {/* </div> */}
    </div>
  )
};

export default PaymentHistory;
