import React from 'react';
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux"
import {Link} from "react-router-dom"

import Actions from "../actions";

import BackButton from "../components/BackButton"

const PaymentForm = ({navigation, location, history}) => {
    const dispatch = useDispatch()
    const result = useSelector(state => state.IIMMPACT.bill.result)
    const postpaid = useSelector(state => state.IIMMPACT.bill.querying)
    let {type} = useParams()

    const formatCurrency = d => {
        let t = d / 100
        return `${t.toFixed(2)}`
    }

    // console.log(postpaid)

    React.useEffect(() => {
      console.log(result)
    }, [result])

    const handleNext = () => {
      if (type !== "postpaid") {
        if (location.state.paip) {
          history.push({pathname: '/paip/pay', state: {
            paymentData: [
              {
                  title: "Name",
                  value: location.state.owner_name
              },
              {
                  title: "Title Number",
                  value: location.state.account_number
              },
            ],
            data: {
              account_number: location.state.account_number,
              amount: location.state.payable_total_amount
            },
            amount: (location.state.payable_amount/100).toFixed(2)
          }})
        } else {
          history.push({pathname: '/bill/pay', state: {
            paymentData: [
              {
                title: "PAY",
                value: location.state.account_no
              }
            ],
            number: location.state.account_no,
            merchant_code: location.state.product_code,
            amount: Math.ceil(result.outstanding)
          }})
        }
      } else {
        history.push({pathname: '/postpaid/pay', state: {
          paymentData: [
            {
              title: "PAY",
              value: location.state.phone
            }
          ],
          card: location.state.card,
          number: location.state.phone,
          merchant_code: location.state.merchant_code,
          amount: Math.ceil(result.outstanding)
        }})
      }
    }

    // return (
    //     <div className="content pay">
    //         <h1 className="content-title">Bills</h1>

    //         <div className="payment-form-wrap partial-dropdown">
    //           <div className="form-wrapper">
    //             <div className="input-holder">
    //                 <label htmlFor="name">Name</label>
    //                 <input type="text" placeholder="Name" name="ownerName" disabled value={location.state.paip ? location.state.owner_name : result.name} />
    //             </div>
    //             <div className="input-holder">
    //                 <label htmlFor="name">Account Number</label>
    //                 <input type="text" placeholder="Account Number" name="ownerName" disabled value={type !== "postpaid" ? location.state.paip ? location.state.account_number : location.state.account_no : location.state.phone} />
    //             </div>
    //             <div className="input-holder data-display">
    //                 <label htmlFor="name">Payment Amount</label>
    //                 <input type="text" placeholder="Payment Amount" name="ownerName" disabled value={location.state.paip ? formatCurrency(location.state.payable_amount) : `RM ${result.outstanding.toFixed(2)}`} />
    //             </div>
    //             <div className="input-holder">
    //               <input type="submit" onClick={handleNext} value="PAY" />
    //             </div>
    //           </div>
    //         </div>
    
    //     </div>
    // );

    return (
      <div id="content" className="flex">
        <div className="h-100 d-flex padding">
          <div className="flex block mb-0">
            <div className="padding pb-0 d-flex align-items-center">
                <BackButton history={history} />
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-white mb-0 p-0">
                        <li class="breadcrumb-item"><Link to="/bill">Bills</Link></li>
                        <li class="breadcrumb-item active" aria-current="page">Payment Form</li>
                    </ol>
                </nav>
            </div>
             {/* <div className="padding pb-0">

            </div> */}
            <div className="page-title padding pb-0 ">
                <h2 className="text-md mb-0">Bills</h2>
            </div>
            <div className="padding">
              <div className="row">
                <div className="col-sm-4 col-md-8">
                  <div className="form-group">
                    <label htmlFor="">Name</label>
                    <input type="text" className="form-control" value={location.state.paip ? location.state.owner_name : result.name} disabled />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Account Number</label>
                    <input type="text" className="form-control" value={type !== "postpaid" ? location.state.paip ? location.state.account_number : location.state.account_no : location.state.phone} disabled />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Payment Amount</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                          <span className="input-group-text">RM</span>
                      </div>
                      <input type="text" className="form-control" value={location.state.paip ? formatCurrency(location.state.payable_amount) : `${result.outstanding.toFixed(2)}`} disabled />
                    </div>
                  </div>
                  <button className={`btn btn-primary w-100 mt-2`} onClick={handleNext} disabled={location.state.paip ? formatCurrency(location.state.payable_amount) <= 0.00 : result.outstanding.toFixed(2) <= 0.00}>Pay</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default PaymentForm;