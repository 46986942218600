import React from "react";

const TextInput = ({
    input, 
    label, 
    name, 
    type, 
    placeholder, 
    disabled = false, 
    forgot,
    e,
    meta: { error, submitFailed, touched },
    children,
    className,
    setMode
  }) => {
  // return (
  //   <div className={`input-holder ${submitFailed && error  && 'error'} ${className ? className : ''}`}>
  //     {label === "none"? (
  //       <></>
  //     ):(
  //       <label htmlFor={name}>{label}</label>
  //     )}
  //     {className == 'amount' ? (
  //       <div className="amount-input">
  //         {children}
  //         <input 
  //           {...input} 
  //           type={type} 
  //           placeholder={placeholder} 
  //           disabled={disabled}
  //           // style={{backgroundColor: disabled ? '#DFE3E9' : 'white'}}
  //           className={`${disabled ? 'disabled' : ''}` }
  //         />
  //       </div>
  //     ):(
  //       <>
  //         <input 
  //           {...input} 
  //           type={type} 
  //           placeholder={placeholder} 
  //           disabled={disabled}
  //           // style={{backgroundColor: disabled ? '#DFE3E9' : 'white'}}
  //           className={`${disabled ? 'disabled' : ''}` }
  //         />
  //         {submitFailed && error && <p className="error">{error}</p>}
  //         {children}
  //       </>
  //     )}

  //     {submitFailed && error && <p>{error}</p>}
  //   </div>
  // );
  return (
    <div className="form-group">
      {label && <label htmlFor="">{label}</label>}
      <input {...input} type={type} className={`form-control ${e ? 'is-invalid' : ''} `} placeholder={placeholder} />
      {forgot && <div class="my-3 text-right"><span class="text-muted pointer" onClick={setMode}>Forgot password?</span></div>}
      {e && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
        <li class="parsley-required">{e}</li>
      </ul>}
    </div>
  )
};

export default TextInput;
