import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getUtilitiesList() {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.getUtilitiesList, headers);
    
    if (response) {
        yield put(Actions.getUtilitiesListSuccess(response.data.data));
    } else {
        yield put(Actions.getUtilitiesListFailed());
    }
}

function* watch() {
    yield takeLatest(Actions.GET_UTILITIES_LIST, getUtilitiesList);
}

export default function* search() {
    yield all([
        fork(watch)
    ])
}