import Actions from "../../actions";

const getDefaultState = () => ({ 
  isLoading: false, 
  error: null, 
  email: null,
  resetCode: null,
  newPassword: null, 
});

function login(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        error: null,
        email: action.data.email
      };
    case Actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.CHECK_RESET_CODE:
      return {
        ...state,
        isLoading: true,
        resetCode: action.data.reset_code
      };

    case Actions.CHECK_RESET_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false      
      };

    case Actions.CHECK_RESET_CODE_FAILED:
      return {
        ...state,
        isLoading: false      
      };

    case Actions.UPDATE_PASSWORD:
      return {
        ...state,
        isLoading: true
      };

    case Actions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false      
      };

    case Actions.UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false      
      };
    case Actions.CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.CHANGE_PASSWORD_SUCCESS:
    case Actions.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
      };      
    default:
      return state;
  }
}

export default login;
