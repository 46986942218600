import axios from "axios";
import fetchApi from "./helper";
import { getFullUrl } from "./helper";

// Dashboard Service
export const getServices = headers => {
  return fetchApi("get", "api/services", null, headers);
};

export const getDefaultPayment = headers => {
  return fetchApi("get", "api/payment_methods", null, headers);
};

// Profile Retrieve
export const getUserProfile = headers => {
  return fetchApi("get", "api/users", null, headers);
};

export const getCount = (headers, filter) => {
  return fetchApi("get", `api/dashboard/counts/all?count_type=${filter}`, null, headers)
}

// Profile Update
export const updateUserProfile = (data, headers) => {
  return fetchApi("put", "api/users", data, headers);
};

// Profile email update
export const updateUserEmail = (data, headers) => {
  return fetchApi("post", "api/users/email_change/apply", data, headers);
};

// Profile update phone
export const updateUserPhone = (data, headers) => {
  return fetchApi("post", "api/users/phone_change/request", data, headers);
};

//Profile update phone OTP
export const updatePhoneVerify = (data, headers) => {
  return fetchApi("post", "api/users/phone_change/apply", data, headers);
};

export const checkUserExistence = data => {
  // TODO: Enhance api helper function
  return axios
    .get(getFullUrl("/api/users/existence"), {
      params: data
    })
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

// Profile Register
export const register = (data, headers) => {
  return fetchApi("post", "api/users", data, headers);
};

// Profile Register OAuth
export const registerSocial = data => {
  return fetchApi("post", "api/users/social_registration", data);
};

// Profile Login
export const login = data => {
  return fetchApi("post", "api/users/login", data);
};

// Profile Login Social
export const loginFromSocial = data => {
  return fetchApi("post", "api/users/login/social", data);
};

// Profile Logout
export const logout = headers => {
  return fetchApi("post", "api/users/logout", null, headers);
};

// Verify OTP
export const otp = (data, headers) => {
  return fetchApi("post", "api/users/activate", data, headers);
};

// Forgot Password use general token
export const forgot = data => {
  return fetchApi("post", "api/users/forgot_password", data, null);
};

// Check reset code use general token
export const check = data => {
  return fetchApi("post", "api/users/forgot_password/check", data, null);
};

// Update/reset password use general token
export const update = data => {
  return fetchApi("post", "api/users/update_password", data, null);
};

// Change password inside app
export const changePassword = (data, headers) => {
  return fetchApi(
    "post",
    "api/users/update_password_using_old_password",
    data,
    headers
  );
};

// Send OTP
export const sendOTP = data => {
  return fetchApi("post", "api/users/send_activation", data);
};

// Resend OTP
export const resendOTP = data => {
  return fetchApi("post", "api/users/resend_activation", data);
};

export const getUtilitiesList = (headers) => {
  return fetchApi("get", "api/products/with_category/impact-utilities", null, headers);
};

//parking pbt
export const pbtList = headers => {
  return fetchApi("post", "api/parking/pbt", null, headers);
};

//parking zone
export const zoneList = (data, headers) => {
  return fetchApi("post", "api/parking/zone", data, headers);
};

//parking nearest
export const nearest = (data, headers) => {
  return fetchApi("post", "api/parking/nearest", data, headers);
};

//parking add
export const addParking = (data, headers) => {
  return fetchApi("post", "api/parking/add", data, headers);
};

//parking active
export const activeParking = headers => {
  return fetchApi("post", "api/parking/active", null, headers);
};

//parking nearest
export const duration = (data, headers) => {
  return fetchApi("post", "api/parking/duration", data, headers);
};

//vehicle add
export const vehicleAdd = (data, headers) => {
  return fetchApi("post", "api/vehicle/add", data, headers);
};

//vehicle update
export const vehicleUpdate = (data, headers) => {
  return fetchApi("post", "api/vehicle/update", data, headers);
};

//vehicle set default
export const vehicleSetDefault = (data, headers) => {
  return fetchApi("post", "api/vehicle/default/set", data, headers);
};

//vehicle remove
export const vehicleRemove = (data, headers) => {
  return fetchApi("post", "api/vehicle/remove", data, headers);
};

//vehicle list
export const vehicleList = headers => {
  return fetchApi("post", "api/vehicle/list", null, headers);
};

//parking topup
export const topup = (data, headers) => {
  return fetchApi("post", "api/topup/third-party", data, headers);
};

//user token
export const getToken = headers => {
  return fetchApi("post", "api/account/token", null, headers);
};

//get token list for topup
export const getTokenList = headers => {
  return fetchApi("get", "api/token_bundles", null, headers);
};

//submit order insurance (roadtax, motor and car)

export const vehicleOrder = (data, headers) => {
  return fetchApi("post", "/api/vehicle_insurance_road_taxes", data, headers);
};

//notification
export const notification = headers => {
  return fetchApi("get", "api/users/notifications", null, headers);
};

//delete notification
export const deleteNotification = (data, headers) => {
  return fetchApi("delete", `api/users/notifications/${data}`, null, headers);
};

//history
export const history = (data, headers) => {
  return fetchApi("get", "api/payments/revenue/mine", data, headers);
};

export const validateProduct = (data, headers) => {
  return fetchApi("get", `api/products/impact/validate?account_no=${data.account_no}&product_code=${data.product_code}`, null, headers); 
};

export const getBill = (data, headers) => {
  return fetchApi("get", `api/impact/bill?account_no=${data.account_no}&product_code=${data.product_code}`, null, headers); 
};

export const getTransactionHistory = (data, headers) => {
  return fetchApi("get", `api/impact/transaction-history?account_no=${data.account_no}`, null, headers); 
};

export const purchaseProduct = (data, headers) => {
  return fetchApi("post", `api/products/impact/purchase`, data, headers);
};

//delete history
export const deleteHistory = (data, headers) => {
  console.log("API", data);
  return fetchApi("delete", `api/users/histories/${data}`, null, headers);
};

// Advertisement
export const getAdvertisements = headers => {
  return fetchApi("get", "api/advertisements", null, headers);
};

export const getZakatOptions = (headers) => {
  return fetchApi("get", "api/zakat_product_options", null, headers);
};

export const submitZakat = (data, headers) => {
  return fetchApi("post", "api/zakat_payments/pay", data, headers);
};

export const checkExpiryRoadTax = (data, headers) => {
  return fetchApi("post", "api/vehicle_insurance_road_tax_expiry", data, headers);
};

export const getMerchantsById = (headers, id) => {
  return fetchApi("get", `api/merchants/${id}`, null, headers); 
};

// Payment - Services
export const makePaymentService = (headers, paramString) => {
  return fetchApi("post", `api/payments/${paramString}`, null, headers);
};

// Payment - Get Payment Method Options for Online Banking
export const getPaymentMethodOptions = (headers, paramString) => {
  return fetchApi(
    "get",
    `/api/payment_methods/options/${paramString}`,
    null,
    headers
  );
};

// Payment - Get Payment Methods
export const getPaymentMethods = headers => {
  return fetchApi("get", "api/payment_methods", null, headers);
};

// Payment - Get Payment Method By ID
export const getPaymentMethodById = (headers, paymentID) => {
  return fetchApi("get", `api/payment_methods/${paymentID}`, null, headers);
};

// Payment - Get Default Payment Method
export const getDefaultPaymentMethod = headers => {
  return fetchApi("get", "api/payment_methods/default", null, headers);
};

// Payment - Set Default Payment Method
export const setDefaultPaymentMethod = (headers, paymentID) => {
  return fetchApi(
    "post",
    `api/payment_methods/${paymentID}/set_default`,
    null,
    headers
  );
};

// Payment - Add Default Payment Method
export const addPaymentMethod = (data, headers) => {
  return fetchApi("post", "api/payment_methods", data, headers);
};

// Payment - Delete Payment Method By ID
export const deletePaymentMethod = (headers, paymentID) => {
  return fetchApi("delete", `api/payment_methods/${paymentID}`, null, headers);
};

// Payment - Add Card Payment Method
export const addCardPaymentMethod = (data, headers) => {
  return fetchApi("post", "/api/payment_methods", data, headers);
};

// Payment - Add eWallet Payment Method
export const addeWalletPaymentMethod = (data, headers) => {
  return fetchApi("post", "/api/payment_methods", data, headers);
};

// Feeders (PBT) - Get Feeder Services
export const getFeederServices = (headers, paramString) => {
  return fetchApi("get", `api/feeders/${paramString}/services`, null, headers);
};

// Feeders (PBT) - Get Compound list
export const getCompoundList = (headers, paramString) => {
  return fetchApi("get", `api/feeders/${paramString}`, null, headers);
};

// Feeders (PBT) - Pay Vehicle Compound Fine
export const payVehicleCompound = (data, headers, paramString) => {
  return fetchApi(
    "post",
    `api/vehicle_compounds/${paramString}/pay`,
    data,
    headers
  );
};

// Feeders (PBT) - Get Assessment Tax
export const getAssessmentTax = (headers, paramString) => {
  return fetchApi("get", `api/feeders/${paramString}`, null, headers);
};

// Feeders (PBT) - Pay Assessment Tax
export const payAssessmentTax = (data, headers, paramString) => {
  return fetchApi(
    "post",
    `api/assessment_taxes/${paramString}/pay`,
    data,
    headers
  );
};

// Feeders (PTG) - Get Land Tax
export const getLandTax = (headers, paramString) => {
  return fetchApi(
    "get",
    `api/feeders/ptg/tax/land?search=${paramString}`,
    null,
    headers
  );
};

// Feeders (PTG) - Pay Land Tax
export const payLandTax = (headers, paramString) => {
  console.log("paramString", paramString);
  return fetchApi("post", `api/ptgs/pay?${paramString}`, null, headers);
};

// Feeders (PAIP) - Get Paip List
export const getPaipList = (headers, paramString) => {
  return fetchApi(
    "get",
    `api/feeders/paip/utilities/paip?search=${paramString}`,
    null,
    headers
  );
};

export const prepaidPayment = (headers, data) => {
  return fetchApi("post", "api/products/telco/purchase", data, headers);
};

export const postpaidPayment = (headers, data) => {
  return fetchApi("post", "api/products/telco/purchase", data, headers);
};

// Feeders (PAIP) - Pay Paip
export const payPaip = (headers, paramString) => {
  return fetchApi(
    "post",
    `/api/paips/pay?${paramString.paramString}`,
    null,
    headers
  );
};

//Feeders (OTHER) - Get Other Compound
export const getOtherCompoundList = (headers, paramString) => {
  console.log("API", paramString);
  return fetchApi(
    "get",
    `/api/feeders/${paramString.paramString}`,
    null,
    headers
  );
};

//Feeders (OTHER) - Pay Other Compound
export const payOtherCompound = (data, headers, paramString) => {
  return fetchApi(
    "post",
    `/api/other_compounds/${paramString}/pay`,
    data,
    headers
  );
};

export const exportHistory = (data, headers) => {
    return fetchApi("post", "api/payments/export/mine", data, headers, 'pdf')
}

export const verifyPayment = (headers, paramString) => {
    return fetchApi(
        "get",
        `/api/payments/by_payment_id/${paramString}`,
        null,
        headers
    )
}

// Bus
export const getOperators = headers => {
  return fetchApi("get", "api/getOperators", null, headers);
};

export const getLocations = headers => {
  return fetchApi("get", "api/getLocations", null, headers);
};

export const getDestinations = headers => {
  return fetchApi("get", "api/getDestinations", null, headers);
};

export const searchTrips = (data, headers) => {
  const { fromCode, toCode, date } = data;

  return fetchApi(
    "get",
    `api/getTrips?fromCode=${fromCode}&toCode=${toCode}&date=${date}`,
    null,
    headers
  );
};

export const getSeats = (data, headers) => {
  console.log("data", data);
  const { fromCode, toCode, date, tripId, operatorCode } = data;

  return fetchApi(
    "get",
    `api/getSeats?fromCode=${fromCode}&toCode=${toCode}&date=${date}&tripId=${tripId}&operatorCode=${operatorCode}`,
    null,
    headers
  );
};

export const paySummary = (data, headers) => {
  return fetchApi("post", "api/getPaySummary", data, headers);
};

export const blockSeat = (data, headers) => {
  return fetchApi("post", "api/blockSeat", data, headers);
};

export const postCheckout = (data, headers) => {
  return fetchApi("post", "api/checkout", data, headers);
};

// Education
export const submitEducation = (data, headers) => {
  return fetchApi("post", "api/education_payments/pay", data, headers);
};

// Favourites
export const saveFavourite = (slug, headers, data, category = null) => {
  let url = category
    ? `/api/users/favourite_accounts/${slug}/${category}`
    : `/api/users/favourite_accounts/${slug}`;
  return fetchApi("post", url, data, headers);
};

export const fetchFavourites = (slug, headers, category = null) => {
  let url = category
    ? `/api/users/favourite_accounts/${slug}/${category}`
    : `/api/users/favourite_accounts/${slug}`;

  return fetchApi("get", url, null, headers);
};

export const deleteFavourites = (slug, name, headers, category = null) => {
  let url = category
    ? `/api/users/favourite_accounts/${slug}/${category}`
    : `/api/users/favourite_accounts/${slug}`;

  return fetchApi("delete", `${url}?name=${name}`, null, headers);
};

// Manual PBT
export const payManualPbt = (type, slug, body, headers) => {
  console.log("payManualPbt");
  console.log(type, slug, body);
  return fetchApi("post", `api/${type}/${slug}/pay/manual`, body, headers);
};

// Feedback
export const submitFeedback = (data, headers) => {
  return fetchApi("post", "api/feedback_answers", data, headers);
};

// User Devices
export const userDevices = (data, headers) => {
  return fetchApi("post", "api/users/devices", data, headers);
};

//Parking Pass Options
export const parkingPassOptions = (data, headers) => {
  let url = `api/parking_pass_options/${data}`;

  return fetchApi("get", url, null, headers);
};

// Create Parking Pass
export const createParkingPass = (data, headers) => {
  return fetchApi("post", "api/parking_passes", data, headers);
};

// Get Parking Pass
export const getParkingPass = headers => {
  return fetchApi("get", "api/parking_passes", null, headers);
};

export const getPrepaidList = (headers) => {
  return fetchApi("get", "api/products/with_category/impact-mobile-prepaid", null, headers);
};

export const getPostpaidList = (headers) => {
  return fetchApi("get", "api/products/with_category/impact-mobile-postpaid", null, headers);
};