import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import profile from './profile';
import payment from './payment';
import iimmpact from './iimmpact';
import favourite from './favourite';
import history from "./history";
import zakat from "./zakat"
import vehicle from './vehicle';
import feeders from "./feeders";
import home from "./home"
// import parking from './parking';
// import services from './services';
// import dashboard from "./dashboard";
// import notification from "./notification";
// import bus from "./bus";
// import language from './language';

export default {
    form: formReducer,
    AUTH: auth,
    PROFILE: profile,
    PAYMENT: payment,
    IIMMPACT: iimmpact,
    FAVOURITE: favourite,
    HISTORY: history,
    ZAKAT: zakat,
    VEHICLE: vehicle,
    FEEDERS: feeders,
    HOME: home,
    // PARKING: parking,
    // SERVICES: services,
    // DASHBOARD: dashboard,
    // NOTIFICATION: notification,
    // BUS: bus,
    // LANGUAGE: language
};
