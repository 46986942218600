import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ReturnPage = () => {
    const {type} = useParams()

    return (
        <div className="flex" id="content">
            <div className="h-100 d-flex padding" id="page-container">
                <div className="block flex mb-0">
                    <div className="page-title padding pb-0 d-flex flex-column justify-content-center align-items-center h-100">
                        <img src={require(`../assets/images/payment_${type}.png`)} />
                        <h2 className="text-md mb-0 mt-5">{type === "success" ? 'Payment Successful' : 'Payment Unsuccessful'}</h2>
                        <p className="mb-0 mt-3">
                            {type === "success" ? "Thank you for your payment." : "Sorry, please try again."}
                        </p>
                        <Link to="/home">
                            <button className="btn btn-primary mt-3">Go Home</button>
                        </Link>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ReturnPage;