import React from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../components/TextInput.js";

import Actions from "../actions";

const PhonePrompt = ({value, handleRegister, history}) => {
    const [mode, setMode] = React.useState(0)
    const [input, setInput] = React.useState({
        phone: "",
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: ""
    })
    const [second, setSecond] = React.useState(59)
    const {isLoading, error, data} = useSelector(state => state.AUTH.register);
    const otp = useSelector(state => state.AUTH.otp)
    const dispatch = useDispatch();
    const timer = React.useRef()
    const otp1 = React.useRef(null)
    const otp2 = React.useRef(null)
    const otp3 = React.useRef(null)
    const otp4 = React.useRef(null)
    const login = useSelector((state) => state.AUTH.login);

    React.useEffect(() => {
        console.log(data)
        if (!isLoading && !error && Object.getOwnPropertyNames(data).length != 0) {
            // if (data.success) {
            setMode(1)
            timer.current = setInterval(() => {
                setSecond(state => state-1)
            }, 1000)
            // }
        }
    }, [isLoading, error, data])

    React.useEffect(() => {
        if (!otp.isLoading && !otp.error && Object.getOwnPropertyNames(otp.data).length != 0) {
            dispatch(Actions.login(value));
        }
    }, [otp])

    React.useEffect(() => {
        if (
          !login.isLoading &&
          !login.error &&
          Object.getOwnPropertyNames(login.data).length != 0
        ) {
          history.push("/home");
          handleRegister()
          dispatch(Actions.getUserProfile())
          dispatch(Actions.resetLogin())
        }
      }, [login]);

    React.useEffect(() => {
        if (second === 0) {
            clearInterval(timer.current)
        }
    }, [second])

    const handleSendOTP = () => {
        // const values = Object.values(value);
        const keys = Object.keys(value);
        // keys.forEach((val, i) => {
        //     d[val] = values[i];
        // });
        value['inputPhone'] = input.phone
        value['phone_prefix'] = "+60"

        if (!keys.includes('inputChannel')) {
            value['inputChannel'] = 'email';
        }

        // // console.log(d)
        // // return

        dispatch(Actions.register(value))
    }

    const handleOTP = d => {
        const value = {}
        value['phone'] = input.phone
        value['otp'] = `${input.otp1}${input.otp2}${input.otp3}${input.otp4}`
        dispatch(Actions.otp(value))
    }

    const handleChange = e => {
        const {name, value} = e.target

        console.log(value.length)

        if (name === "otp1" || name === "otp2" || name === "otp3" || name === "otp4") {
            if (value.length <= 1) {
                setInput(state => ({...state, [name]: value}))
            }
            
            if (value.length === 1) {
                switch (name) {
                    case "otp1":
                        otp2.current.focus()
                        break;
                    case "otp2":
                        otp3.current.focus()
                        break;
                    case "otp3":
                        otp4.current.focus()
                        break;
                    default:
                        break;
                }
            }
        } else {
            setInput(state => ({...state, [name]: value}))
        }
    }

    return (
        <div className="col-md-4" id="content-body">
            <div className="card">
            <div className="p-4">
                <div>
                    <h5>{mode === 0 ? "Enter Phone Number" : "Enter 4-Digit PIN (OTP)"}</h5>
                    {/* <p className="text-muted my-3">
                        Enter your email below and we'll send you a verification code to reset your password.
                    </p> */}
                </div>
                    <form className="py-5 my-md-5">
                    <div className="form-group mb-0">
                        {mode === 0 ? (
                            <input type="text" className="form-control" name="phone" onChange={handleChange} value={input.phone} placeholder="0123456789"/>
                        ) : (
                            <div className="input-group px-5">
                                <input type="number" ref={otp1} className="form-control text-center" name="otp1" onChange={handleChange} maxLength={1} value={input.otp1} />
                                <input type="number" ref={otp2} className="form-control text-center" name="otp2" onChange={handleChange} maxLength={1} value={input.otp2} />
                                <input type="number" ref={otp3} className="form-control text-center" name="otp3" onChange={handleChange} maxLength={1} value={input.otp3} />
                                <input type="number" ref={otp4} className="form-control text-center" name="otp4" onChange={handleChange} maxLength={1} value={input.otp4} />
                            </div>
                        )}
                    </div>
                    </form>
                    <button className="btn btn-primary btn-block mb-2" onClick={mode === 0 ? handleSendOTP : handleOTP}>{mode === 0 ? 'Get Code' : 'Verify'}</button>
                    {mode === 1 && (
                        <div class="pt-1">
                        Having trouble? 
                        <span class="text-primary font-weight-bold ml-1 pointer" onClick={() => setMode(0)}>{second > 0 ? `${second} ${second > 1 ? 'seconds' : 'second'} left` : 'Resend code'}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    // return (
    //     <div className="form-wrapper">
    //         {mode === 0 && (
    //             <>
    //                 <div className="phone-label">
    //                     <p>Enter Phone Number</p>
    //                 </div>
    //                 <div className="side-by-side phone-format">
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="none"
    //                         name="phone_prefix"
    //                         placeholder="+60"
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="none"
    //                         name="inputPhone"
    //                         placeholder=""
    //                     />
    //                 </div>

    //                 <div className="phone-submit">
    //                     <div className="yellow-btn" onClick={handleSubmit(handleSendOTP)}>
    //                         GET CODE
    //                     </div>
    //                 </div>
    //             </>
    //         )}

    //         {mode === 1 && (
    //             <>
    //                 <div className="phone-label">
    //                     <p>Enter 4-Digit PIN (OTP)</p>
    //                 </div>
    //                 <div className="side-by-side otp">
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="none"
    //                         maxLength={1}
    //                         name="otp_1"
    //                         placeholder=""
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="none"
    //                         name="otp_2"
    //                         maxLength={1}
    //                         placeholder=""
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="none"
    //                         name="otp_3"
    //                         maxLength={1}
    //                         placeholder=""
    //                     />
    //                     <Field
    //                         component={TextInput}
    //                         type="text"
    //                         label="none"
    //                         name="otp_4"
    //                         maxLength={1}
    //                         placeholder=""
    //                     />
    //                 </div>

    //                 <div className="phone-submit">
    //                     <div className="yellow-btn" onClick={handleSubmit(handleOTP)}>
    //                         GET CODE
    //                     </div>
    //                 </div>

    //                 <div className="resend-wrap">
    //                     <div className="countdown">
    //                         <span className="yellow-highlight"> 30 </span> seconds left.
    //                     </div>
    //                     <div className="resend">
    //                         Having Trouble? <span className="yellow-highlight">Resend Code</span>
    //                     </div>
    //                 </div>
    //             </>
    //         )}
    //     </div>
    // );
};

export default reduxForm({
    form: "phoneOTPForm",
    // validate
})(PhonePrompt);