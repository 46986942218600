import React from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Link} from "react-router-dom"
import { reduxForm, Field, SubmissionError } from "redux-form";

import TextInput from "../../components/TextInput.js";

import Actions from "../../actions"
import LoadingOverlay from "../../components/LoadingOverlay"

const RoadtaxForm = ({history, handleSubmit}) => {
    const dispatch = useDispatch()
    const [input, setInput] = React.useState({
        plate: "",
        ic: ""
    })
    const [vehicleType, setVehicleType] = React.useState(1)
    const [requireInsurance, setRequireInsurance] = React.useState(null)
    const [tnc, setTnc] = React.useState(false)
    const [showPopup, setShowPopup] = React.useState(false)
    const {isLoading} = useSelector(state => state.VEHICLE.order)

    const handleSearch = () => {
        dispatch(Actions.checkExpiryRoadTax({ic: input.ic, plate_number: input.plate, vehicleType: "Car"}, () => {
            history.push({pathname: '/roadtax/summary', state: {
                plate: input.plate,
                ic: input.ic
            }})
        }))
    }

    const handleChange = e => {
        const {name, value} = e.target
        setInput(state => ({...state, [name]: value}))
    }

    // return (
    //     <div className="content road-tax">
    //         {isLoading && <LoadingOverlay />}
    //         {showPopup && (

    //             <div className="popup-backdrop">
    //                 <div className="popup small">
    //                     <div 
    //                         className="close-popup"
    //                         onClick={() => setShowPopup(false)}
    //                     >
    //                         <img src={require('../../assets/images/close.png')} alt="" />
    //                     </div>

    //                     <div className="popup-content">
    //                         <div className="form-wrapper">
    //                             <div className="input-holder">
    //                                 <label htmlFor="unitNo">Unit/Building/Lot</label>
    //                                 <input type="text" placeholder="e.g. 10" name="unitNo" value={""} />
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="street">Street</label>
    //                                 <input type="text" placeholder="e.g. Jalan Tasik" name="street" value={""} />
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="country">Country</label>
    //                                 <input type="text" placeholder="e.g. Malaysia" name="country" value={""} />
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="state">State</label>
    //                                 <input type="text" placeholder="e.g. Pahang" name="state" value={""} />
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="city">City</label>
    //                                 <input type="text" placeholder="e.g. Kuantan" name="city" value={""} />
    //                             </div>

    //                             <div className="input-holder submit-holder">
    //                                 <input type="submit" value="SAVE" onClick={() => setShowPopup(false)}/>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         )}

    //         <h1 className="content-title">Roadtax</h1>

    //         <div className="content-card">
    //             <form onSubmit={handleSubmit(handleSearch)}>
    //                 <div className="form-wrapper">
    //                     {/* <div className="input-holder">
    //                         <label htmlFor="username">Name</label>
    //                         <input type="text" placeholder="Enter name" name="name" value={""} />
    //                     </div> */}

    //                     {/* <div className="input-holder">
    //                         <label htmlFor="phoneNo">Phone Number</label>
    //                         <input type="text" placeholder="Enter phone No" name="phoneNo" value={""} />
    //                     </div> */}

    //                     {/* <div className="input-holder">
    //                         <label htmlFor="email">Email</label>
    //                         <input type="text" placeholder="Enter email" name="email" value={""} />
    //                     </div> */}

    //                     <div className="radio-select-holder inline">
    //                         <p className="title">Type of Vehicle</p>
    //                         <div className={`radio-select disabled hide-radio ${vehicleType === 0 ? 'active' : ''}`}>
    //                             <input type="radio" name="gender" id="motorcycle" />
    //                             <label htmlFor="motorcycle"> Motorcycle </label>
    //                         </div>

    //                         <div className={`radio-select hide-radio ${vehicleType === 1 ? 'active' : ''}`}>
    //                             <input type="radio" name="gender" id="Car" onClick={() => setVehicleType(1)} />
    //                             <label htmlFor="Car"> Car </label>
    //                         </div>
    //                     </div>

    //                     <Field 
    //                     component={TextInput}
    //                     type="text"
    //                     label="Plate Number"
    //                     name="plate_number"
    //                     placeholder="Enter plate number"
    //                     />

    //                     <Field 
    //                     component={TextInput}
    //                     type="text"
    //                     label="MyKad"
    //                     name="ic"
    //                     placeholder="Enter mykad"
    //                     />

    //                     {/* <div className="input-holder">
    //                         <label htmlFor="paymentAmount">MyKad</label>
    //                         <input type="text" placeholder="Enter mykad" name="paymentAmount" value={""} />
    //                     </div> */}

    //                     {/* <div className="input-holder">
    //                         <label htmlFor="roadtaxExpiryDate">Roadtax Expiry Date</label>
    //                         <input type="date" name="roadtaxExpiryDate" value={""} />
    //                     </div> */}

    //                     {/* <div className="input-holder">
    //                         <label htmlFor="address">Address</label>
    //                         <input type="text" placeholder="Enter address" name="address" value={""} onClick={() => setShowPopup(true)}/>
    //                     </div> */}

    //                     {/* <div className="radio-select-holder inline">
    //                         <p className="title">Do you require insurance?</p>
    //                         <div className={`radio-select hide-radio ${requireInsurance === 0 ? 'active' : ''}`}>
    //                             <input type="radio" name="insurance" id="yes" onClick={() => setRequireInsurance(0)} />
    //                             <label htmlFor="yes"> Yes </label>
    //                         </div>

    //                         <div className={`radio-select hide-radio ${requireInsurance === 1 ? 'active' : ''}`}>
    //                             <input type="radio" name="insurance" id="no" onClick={() => setRequireInsurance(1)} />
    //                             <label htmlFor="no"> No </label>
    //                         </div>
    //                     </div> */}

    //                     {/* <div className="input-holder">
    //                         <div className="checkbox-input">
    //                             <input type="checkbox" id="tnc" name="tnc" checked={tnc} onChange={() => setTnc(!tnc)} />
    //                             <label htmlFor="tnc"> By submitting this, you have agreed to share this information with our partner.</label>
    //                         </div>
    //                     </div> */}

    //                     {/* {vehicleType !== null && (
    //                         <>
    //                             <div className="input-holder">
    //                                 <label htmlFor="vehicleModel">Model</label>
    //                                 <input type="text" placeholder="e.g. Suzuki, Yamaha" name="vehicleModel" value={""}/>
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="manufacturedYear">Manufactured Year</label>
    //                                 <input type="text" placeholder="e.g. 2001" name="manufacturedYear" value={""}/>
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="chassisNumber">Chassis Number</label>
    //                                 <input type="text" placeholder="e.g. 2X2HA259BWD123456" name="chassisNumber" value={""}/>
    //                             </div>

    //                             <div className="input-holder">
    //                                 <label htmlFor="engineNumber">Engine Number</label>
    //                                 <input type="text" placeholder="e.g. 2X2HA259BWD123456" name="engineNumber" value={""}/>
    //                             </div>
    //                         </>
    //                     )} */}

    //                     <button>NEXT</button>

    //                     {/* <div className="input-holder submit-holder">
    //                         <input type="submit" value="SUBMIT" /> */}
    //                         {/* <Link to="/pay/kuantan/land_tax/payment">
    //                         </Link> */}
    //                     {/* </div> */}
    //                 </div>
    //             </form>
    //         </div>
    //     </div>
    // );

    return (
        <div id="content" className="flex">
            <div className="h-100 d-flex padding">
                <div className="block flex mb-0">
                    <div className="page-title padding pb-0">
                        <h2 className="text-md mb-0">Roadtax</h2>
                    </div>
                    <div className="padding">
                        <p className="text-muted">Type of vehicle</p>
                        <div className="row">
                            <div className="col-sm-4 col-md-6">
                                <div className="row row-md">
                                    <div className="col-sm-3 opacity">
                                        <div className={`card border ${vehicleType === 0 ? 'border-primary' : ''}`}>
                                            <div className="p-3 text-center d-flex flex-column align-items-center">
                                                <img src={require('../../assets/images/motor_icon.png')} style={{height: "50px"}} className="mb-2" alt=""/>
                                                Motorcycle
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className={`card border pointer ${vehicleType === 1 ? 'border-primary' : ''}`} onClick={() => setVehicleType(1)}>
                                            <div className="p-3 text-center d-flex flex-column align-items-center">
                                                <img src={require('../../assets/images/car_icon.png')} style={{height: "50px"}} className="mb-2" alt=""/>
                                                Car
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Plate Number</label>
                                    <input type="text" className="form-control" name="plate" onChange={handleChange} value={input.plate} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">MyKad Number</label>
                                    <input type="number" className="form-control" name="ic" onChange={handleChange} value={input.ic} />
                                </div>
                                <button className="btn btn-primary w-100 mt-4" disabled={!input.plate || !input.ic} onClick={handleSearch}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "roadTaxForm",
    // validate
  })(RoadtaxForm);