import React from 'react';
import OtherCompoundTable from '../../components/Pay/OtherCompoundTable'
import ParkingCompoundTable from '../../components/Pay/ParkingCompoundTable'
import {useParams} from "react-router-dom";

const PayViewTable = ({location}) => {
    let { payment_type } = useParams()

    return (
        <div id="content" className="flex">
                <div className="h-100 padding" id="page-container">
                {/* <div className="block flex mb-0"> */}
                <div className="page-title pb-0 ">
                    <h2 className="text-md mb-0">Check & Pay</h2>
                </div>

                {payment_type === 'parking_compound' && (
                    <ParkingCompoundTable location={location} />
                )}

                {payment_type === 'other_compound' && (
                    <OtherCompoundTable location={location} />
                )}
            </div>
        </div>
    );
};

export default PayViewTable;