import { all, fork } from "redux-saga/effects";
import login from "./login";
import register from "./register";
import otp from "./otp";
// import sendOTP from "./sendOTP";
// import resendOTP from "./resendOTP";
import logout from "./logout";
import userExistence from "./userExistence";
import reset from "./reset";

export default function* home() {
  yield all([
    fork(login),
    fork(register),
    fork(otp),
    // fork(sendOTP),
    // fork(resendOTP),
    fork(logout),
    fork(userExistence),
    fork(reset)
  ]);
}
