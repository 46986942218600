import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: {} });

function deletePaymentMethod(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.DELETE_PAYMENT_METHOD:
      return { isLoading: true, error: null, data: {} };
    case Actions.DELETE_PAYMENT_METHOD_SUCCESS:
      return { isLoading: false, error: null, data: action.data };
    case Actions.DELETE_PAYMENT_METHOD_FAIL:
      return { isLoading: false, error: action.error, data: {} };
    case Actions.RESET_DELETE_PAYMENT_METHOD:
      return getDefaultState();
    default:
      return state;
  }
}

export default deletePaymentMethod;
