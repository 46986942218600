import React from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/TextInput.js";
import Actions from "./../../actions";

const CreditDebit = () => {
    return (
        <>
            <p className="payment-title">Credit / Debit Card</p>

            <div className="form-wrapper full">
                <Field
                    component={TextInput}
                    type="text"
                    label="Name on Card"
                    name="cardOwner"
                    placeholder="Name on card"
                />
                <Field
                    component={TextInput}
                    type="text"
                    label=""
                    name="cardNo"
                    placeholder="Card no."
                />

                <div className="side-by-side">
                    <Field
                        component={TextInput}
                        type="text"
                        label=""
                        name="expiryDate"
                        placeholder="MM/YY"
                    />
                    <Field
                        component={TextInput}
                        type="text"
                        label=""
                        name="cvv"
                        placeholder="CVV"
                    />
                </div>

                <div className="orange-btn">
                    ADD
                </div>
            </div>
        </>
    );
};

export default reduxForm({
    form: "creditDebitForm",
    // validate
})(CreditDebit);
