import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: [] });

function history(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.HISTORY:
      return {
        isLoading: true,
        error: null,
        data: []
      };
    case Actions.HISTORY_SUCCESS:
      return {
        isLoading: false,
        error: null,
        data: action.data
      };
    case Actions.HISTORY_FAILED:
      return {
        isLoading: false,
        error: action.error,
        data: []
      };
    default:
      return state;
  }
}

export default history;
