import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getPaymentMethodOptions({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    console.log(data)

    const { response, error } = yield call(api.getPaymentMethodOptions, headers, data.paramString);
    if (response) {
        yield put(Actions.getPaymentMethodOptionsSuccess(response.data.data));
    } else {
        if (error.code === "ECONNABORTED") {
            return yield put(Actions.getPaymentMethodOptionsFail(error))
        }
        yield put(Actions.getPaymentMethodOptionsFail(error.response.data));
    }
}

function* watchGetPaymentMethodOptions() {
    yield takeLatest(Actions.GET_PAYMENT_METHOD_OPTIONS, getPaymentMethodOptions);
}

export default function* submit() {
    yield all([
        fork(watchGetPaymentMethodOptions)
    ])
}