export const NAME = "PAYMENT";

export const ADD_EWALLET_PAYMENT_METHOD = `${NAME}/ADD_EWALLET_PAYMENT_METHOD`;
export const ADD_EWALLET_PAYMENT_METHOD_SUCCESS = `${NAME}/ADD_EWALLET_PAYMENT__METHOD_SUCCESS`;
export const ADD_EWALLET_PAYMENT_METHOD_FAIL = `${NAME}/ADD_EWALLET_PAYMENT_METHOD_FAIL`;
export const RESET_ADD_EWALLET_PAYMENT_METHOD = `${NAME}/RESET_ADD_EWALLET_PAYMENT_METHOD`;

export const getAddeWalletPaymentMethodData = store =>
  store[NAME].addeWalletPaymentMethod;

export const addeWalletPaymentMethod = data => ({
  type: ADD_EWALLET_PAYMENT_METHOD,
  data
});

export const addeWalletPaymentMethodSuccess = data => ({
  type: ADD_EWALLET_PAYMENT_METHOD_SUCCESS,
  data
});

export const addeWalletPaymentMethodFail = error => ({
  type: ADD_EWALLET_PAYMENT_METHOD_FAIL,
  error
});

export const resetAddeWalletPaymentMethod = () => ({
  type: RESET_ADD_EWALLET_PAYMENT_METHOD
});
