import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import Actions from "../../actions";

const BankTransfer = ({addPaymentMethod}) => {
    const {isLoading, error, data} = useSelector(state => state.PAYMENT.paymentMethodOptions);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(Actions.getPaymentMethodOptions({paramString: ''}))
    }, [])
    
    // return (
    //     <>
    //         <p className="payment-title">Select One</p>
    //         {isLoading && <div className="loading_holder">
    //             <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    //         </div>}

    //         <div className="bank-transfer-wrap">
    //             {data.map(d => (
    //                 <div key={d.card_name} className="bank" onClick={() => addPaymentMethod(d)}>
    //                     {d.bank_name}
    //                 </div>
    //             ))}
    //             {/* <div className="bank">
    //                 Maybank
    //             </div>
    //             <div className="bank">
    //                 Public Bank
    //             </div>
    //             <div className="bank">
    //                 Hong Leong Bank
    //             </div>
    //             <div className="bank">
    //                 Bank Islam
    //             </div>
    //             <div className="bank">
    //                 Ambank
    //             </div>
    //             <div className="bank">
    //                 Affin Bank Berhad
    //             </div>
    //             <div className="bank">
    //                 Bank Kerjasama Rakyat
    //             </div>
    //             <div className="bank">
    //                 HSBC
    //             </div>
    //             <div className="bank">
    //                 Bank Muamalat
    //             </div>
    //             <div className="bank">
    //                 BSN
    //             </div>
    //             <div className="bank">
    //                 OSBC
    //             </div> */}
    //         </div>
    //     </>
    // );

    return (
        <div className="card border no-shadow p-4">
            <p className="text-muted pb-0">Select One</p>
            <div className="list list-row">
                {data.map(d => (
                    <div key={d.card_name} className="list-item pointer" onClick={() => addPaymentMethod(d)}>
                        <img src={d.logo} style={{width: "80px"}} alt=""/>
                        {d.bank_name}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default BankTransfer;