import { combineReducers } from "redux";

import history from "./history";
import exportHistory from "./export"
// import deleteHistory from "./deleteHistory";

export default combineReducers({
  history,
  exportHistory
  // deleteHistory
});
