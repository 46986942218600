import * as getCompoundList from "./getCompoundList";
// import * as getFeederServices from "./getFeederServices";
import * as payVehicleCompound from "./payVehicleCompound";
import * as getAssessmentTax from "./getAssessmentTax";
import * as payAssessmentTax from "./payAssessmentTax";
import * as getLandTax from "./getLandTax";
// import * as payLandTax from "./payLandTax";
import * as getPaipList from "./getPaipList";
import * as payPaip from "./payPaip";
import * as getOtherCompoundList from "./getOtherCompoundList";
import * as payOtherCompound from "./payOtherCompound";

export default {
  ...getCompoundList,
  // ...getFeederServices,
  ...payVehicleCompound,
  ...getAssessmentTax,
  ...payAssessmentTax,
  ...getLandTax,
  // ...payLandTax,
  ...getPaipList,
  ...payPaip,
  ...getOtherCompoundList,
  ...payOtherCompound
};
