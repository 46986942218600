export const NAME = "FEEDERS";

export const PAY_VEHICLE_COMPOUND = `${NAME}/PAY_VEHICLE_COMPOUND`;
export const PAY_VEHICLE_COMPOUND_SUCCESS = `${NAME}/PAY_VEHICLE_COMPOUND_SUCCESS`;
export const PAY_VEHICLE_COMPOUND_FAIL = `${NAME}/PAY_VEHICLE_COMPOUND_FAIL`;
export const RESET_PAY_VEHICLE_COMPOUND = `${NAME}/RESET_PAY_VEHICLE_COMPOUND`;

export const getPayVehicleCompoundData = store => store[NAME].payVehicleCompound;

export const payVehicleCompound = data => ({
  type: PAY_VEHICLE_COMPOUND,
  data
});

export const payVehicleCompoundSuccess = data => ({
  type: PAY_VEHICLE_COMPOUND_SUCCESS,
  data
});

export const payVehicleCompoundFail = error => ({
  type: PAY_VEHICLE_COMPOUND_FAIL,
  error
});

export const resetPayVehicleCompound = () => ({
  type: RESET_PAY_VEHICLE_COMPOUND
});