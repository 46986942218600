const NAME = 'FAVOURITE'

const ADD_FAVOURITE = `${NAME}/ADD`;
const ADD_FAVOURITE_SUCCESS = `${NAME}/ADD_SUCCESS`;
const FETCH_FAVOURITE_LIST = `${NAME}/FETCH_LIST`;
const FETCH_FAVOURITE_LIST_SUCCESS = `${NAME}/FETCH_LIST_SUCCESS`;
const DELETE_FAVOURITE_LIST = `${NAME}/DELETE_FAVOURITE_LIST`;
const DELETE_FAVOURITE_LIST_SUCCESS = `${NAME}/DELETE_FAVOURITE_LIST_SUCCESS`;


const addFavourite = (data, callback = null) => ({
    type: ADD_FAVOURITE,
    data,
    callback
});

const fetchListFavourites = (slug, category = null) => ({
    type: FETCH_FAVOURITE_LIST,
    slug,
    category
});

const deleteFavourite = (slug, name, category = null) => ({
    type: DELETE_FAVOURITE_LIST,
    slug,
    name,
    category
});

export default {
    addFavourite,
    fetchListFavourites,
    deleteFavourite,
    ADD_FAVOURITE, 
    ADD_FAVOURITE_SUCCESS,
    FETCH_FAVOURITE_LIST,
    FETCH_FAVOURITE_LIST_SUCCESS,
    DELETE_FAVOURITE_LIST,
    DELETE_FAVOURITE_LIST_SUCCESS
}