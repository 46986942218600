const NAME = 'MANUAL'

const START_PAY = `${NAME}/START_PAY`;
const PAY_SUCCESS = `${NAME}/PAY_SUCCESS`;
const PAY_FAILED = `${NAME}/PAY_FAILED`;

const payManual = (payment_type, slug, body, callback = null) => ({
    type: START_PAY,
    payment_type,
    slug,
    body,
    callback
});

export default {
    payManual,
    START_PAY,
    PAY_SUCCESS,
    PAY_FAILED

  
}

