export const NAME = "FEEDERS";

export const PAY_OTHER_COMPOUND = `${NAME}/PAY_OTHER_COMPOUND`;
export const PAY_OTHER_COMPOUND_SUCCESS = `${NAME}/PAY_OTHER_COMPOUND_SUCCESS`;
export const PAY_OTHER_COMPOUND_FAIL = `${NAME}/PAY_OTHER_COMPOUND_FAIL`;
export const RESET_PAY_OTHER_COMPOUND = `${NAME}/RESET_PAY_OTHER_COMPOUND`;

export const getPayOtherCompoundData = store => store[NAME].payOtherCompound;

export const payOtherCompound = data => ({
  type: PAY_OTHER_COMPOUND,
  data
});

export const payOtherCompoundSuccess = data => ({
  type: PAY_OTHER_COMPOUND_SUCCESS,
  data
});

export const payOtherCompoundFail = error => ({
  type: PAY_OTHER_COMPOUND_FAIL,
  error
});

export const resetPayOtherCompound = () => ({
  type: RESET_PAY_OTHER_COMPOUND
});
