export const NAME = 'PRODUCT'

export const PURCHASE_PRODUCT = `${NAME}/PURCHASE_PRODUCT`;

export const PURCHASE_PRODUCT_SUCCESS = `${NAME}/PURCHASE_PRODUCT_SUCCESS`;

export const PURCHASE_PRODUCT_FAILED = `${NAME}/PURCHASE_PRODUCT_FAILED`;

export const VALIDATE_PRODUCT = `${NAME}/VALIDATE_PRODUCT`;

export const VALIDATE_PRODUCT_SUCCESS = `${NAME}/VALIDATE_PRODUCT_SUCCESS`;

export const VALIDATE_PRODUCT_FAILED = `${NAME}/VALIDATE_PRODUCT_FAILED`;

export const purchaseProduct = (data, callback, callbackError) => ({
    type: PURCHASE_PRODUCT,
    data,
    callback,
    callbackError
});

export const purchaseProductSuccess = (data) => ({
    type: PURCHASE_PRODUCT_SUCCESS,
    data
});

export const purchaseProductFailed = () => ({
    type: PURCHASE_PRODUCT_FAILED
});

export const validateProduct = (data, callback, callbackError) => ({
    type: VALIDATE_PRODUCT,
    data,
    callback,
    callbackError
});

export const validateProductSuccess = (data) => ({
    type: VALIDATE_PRODUCT_SUCCESS,
    data
});

export const validateProductFailed = (data) => ({
    type: VALIDATE_PRODUCT_FAILED,
    data
});

