import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getBill({ data, callback, callbackError }) {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.getBill, data, headers);

    console.log('response', response);

    console.log('error', error);
    
    if (response) {
        yield put(Actions.getBillSuccess(response.data.data));
        callback && callback(response.data.data);
    } else {
        yield put(Actions.getBillFailed());

        if(error.response){
            callbackError && callbackError(error.response.data.message);
        } else {
            callbackError && callbackError('Please try again later');
        }
        
    }
}

function* getTransactionHistory({ data, callback, callbackError }) {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.getTransactionHistory, data, headers);

    console.log('response', response);

    console.log('error', error);
    
    if (response) {
        yield put(Actions.getTransactionHistorySuccess(response.data.data));
        callback && callback();
    } else {
        yield put(Actions.getTransactionHistoryFailed());

        if(error.response){
            callbackError && callbackError(error.response.data.data);
        } else {
            callbackError && callbackError('Please try again later');
        }
        
    }
}

function* watch() {
    yield takeLatest(Actions.GET_BILL, getBill);
    yield takeLatest(Actions.GET_TRANSACTION_HISTORY, getTransactionHistory);
}

export default function* search() {
    yield all([
        fork(watch)
    ])
}