import React from "react";

const PaipAddAccount = props => {
  const [cardActive, setCardActive] = React.useState(0);
  const [amountActive, setAmountActive] = React.useState(null);
  const [favouriteActive, setFavouriteActive] = React.useState(null);
  const [number, setNumber] = React.useState("");

  const handleNext = () => {
    const cardArray = ['YES', 'DiGi', 'Maxis', 'XPAX', 'Umobile', 'Tune Talk', 'XOX', 'ALTEL'];
    const amountArray = ['RM30.00', 'RM50.00', 'RM100.00'];
    const favouriteArray = ['0124444543', '0124423322']

    props.history.push({
      pathname: '/prepaid/pay',
      state: {card: cardArray[cardActive], amount: amountArray[amountActive], number: favouriteActive !== null ? favouriteArray[favouriteActive] : number}
    })
  }

  const handleGoToAccount = () => {
    props.history.push({
      pathname: '/paip/account',
    }) 
  }

  return (
    <div className="content home">
      <h1 className="content-title">PAIP</h1>

      <div className="content-form custom">
        <div className="form-wrapper">
          <div className={`favourite-holder ${number ? 'hidden' : ''}`}>
            <h4>Registered Account</h4>
            <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
              <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/>
              <div className="middle">
                <p className="title">Rumah Sewa Kuantan</p>
                <p className="subtitle">1234567890</p>
              </div>
              <p className="right">Pemilik</p>
            </div>
            <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 1 : favouriteActive === 1 ? null : 1)}>
              <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt=""/>
              <div className="middle">
                <p className="title">Rumah Sewa Kuantan</p>
                <p className="subtitle">1234567890</p>
              </div>
              <p className="right">Pemilik</p>
            </div>
          </div>
          <button onClick={handleGoToAccount}>NEXT</button>
        </div>
      </div>

      <div className="content-card">
        <h4>Register New Account</h4>
        <div className="card-holder">
          <div
            className={cardActive === 0 ? "active" : ""}
            onClick={() => setCardActive(cardActive !== null ? cardActive === 0 ? null : 0 : 0)}
          >
            <img
              src={require("../../assets/images/yes-logo@2x.png")}
              style={{ marginTop: "10px" }}
              alt=""
            />
            <p>Pemilik</p>
          </div>
          <div
            className={cardActive === 1 ? "active" : ""}
            onClick={() => setCardActive(cardActive !== null ? cardActive === 1 ? null : 1 : 1)}
          >
            <img src={require("../../assets/images/digi-logo@2x.png")} alt="" />
            <p>Penyewa</p>
          </div>
        </div>
        <div className="form-wrapper">
          <div className={`input-holder ${favouriteActive !== null ? 'hidden' : ''}`}>
            <label htmlFor="">Acc Number</label>
            <input type="text" placeholder="Enter acc number" onChange={e => setNumber(e.target.value)} />
          </div>
          <div className={`input-holder ${favouriteActive !== null ? 'hidden' : ''}`}>
            <label htmlFor="">Mykad Number</label>
            <input type="text" placeholder="Enter mykad number" onChange={e => setNumber(e.target.value)} />
          </div>
          {cardActive === 0 && ( 
            <div className={`input-holder ${favouriteActive !== null ? 'hidden' : ''}`}>
              <label htmlFor="">Title Name</label>
              <input type="text" placeholder="Enter title name" onChange={e => setNumber(e.target.value)} />
            </div>
          )}
          <button onClick={handleNext}>SAVE</button>
        </div>
      
      </div>
    </div>
  );
};

export default PaipAddAccount;
