import Actions from '../../actions';

const getDefaultState = () => ({ 
    list: [],
});

function address(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.GET_POSTPAID_LIST_SUCCESS:
            let shownList = action.data.filter(function(list){ return list.is_shown == 1; });
            return {
                ...state,
                list: shownList,
            };                 
        default:
            return state;
    }
}

export default address;