import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* setDefaultPaymentMethod({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.setDefaultPaymentMethod, headers, data.paramString);
    if (response) {
        yield put(Actions.setDefaultPaymentMethodSuccess(response.data.data));
    } else {
        yield put(Actions.setDefaultPaymentMethodFail(error.response.data));
    }
}

function* watchSetDefaultPaymentMethod() {
    yield takeLatest(Actions.SET_DEFAULT_PAYMENT_METHOD, setDefaultPaymentMethod);
}

export default function* submit() {
    yield all([
        fork(watchSetDefaultPaymentMethod)
    ])
}