import React from 'react';
import BusSearch from '../../components/Bus/BusSearch'
const BusForm = () => {
    const [isReturn, setIsReturn] = React.useState(false)
    const [showResults, setShowResults] = React.useState(true)

    return (
        <div className="content bus">
            <h1 className="content-title">Bus</h1>
            <div className="bus-form-wrapper">
                <div className="bus-form">
                    <div className="form-wrapper">
                        {/* <div className="radio-select-holder inline">
                            <div className={`radio-select hide-radio ${isReturn === 0 ? 'active' : ''}`}>
                                <input type="radio" name="isReturn" id="oneWay" onClick={() => setIsReturn(0)} />
                                <label htmlFor="oneWay"> One Way </label>
                            </div>

                            <div className={`radio-select hide-radio ${isReturn === 1 ? 'active' : ''}`}>
                                <input type="radio" name="isReturn" id="roundTrip" onClick={() => setIsReturn(1)} />
                                <label htmlFor="roundTrip"> Round Trip </label>
                            </div>
                        </div> */}

                        <div className="side-by-side border-right">
                            <div className="input-holder">
                                <label htmlFor="name">From</label>
                                {/* <input type="text" placeholder="Origin" name="origin" value={""} /> */}
                                <div className="select-wrap">
                                    <select name="origin" id="">
                                        <option value="">Origin</option>
                                    </select>
                                    <img src={require("../../assets/images/down-triangle.png")} alt="" />
                                </div>
                            </div>
                            <img className="left-arrow" src={require('../../assets/images/arrow-right.png')} alt="" />
                            <div className="input-holder">
                                <label htmlFor="name">To</label>
                                {/* <input type="text" placeholder="Destination" name="destination" value={""} /> */}
                                <div className="select-wrap">
                                    <select name="destination" id="">
                                        <option value="">Destination</option>
                                    </select>
                                    <img src={require("../../assets/images/down-triangle.png")} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="side-by-side">
                            <div className="input-holder">
                                <label htmlFor="name">Departure Date</label>
                                <input type="date" placeholder="Select Date" name="departureDate" value={""} />
                            </div>

                            <div className="input-holder">
                                {/* <label htmlFor="name">Return Date</label> */}
                                <div className="checkbox-input">
                                    <input type="checkbox" id="roundTrip" name="roundTrip" checked={isReturn} onChange={() => setIsReturn(!isReturn)}/>
                                    <label htmlFor="roundTrip"> Round Trip</label>
                                </div>
                                <input type="date" placeholder="Select Date" name="departureDate" value={""} />
                            </div>
                        </div>

                        <div className="input-holder submit-holder">
                            <input type="submit" value="SEARCH TRIP" onClick={() => setShowResults(isReturn)} />
                            {/* <Link to="/pay/kuantan/land_tax/payment">
                            </Link> */}
                        </div>
                    </div>
                </div>

                {showResults !== null && (
                    <BusSearch isReturn={isReturn}/>
                )}
            </div>
        </div>
    );
};

export default BusForm;