import Actions from "../../actions";
import { combineReducers } from "redux";

const INITIAL_STATE = {
    fav_accounts : {}
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Actions.FETCH_FAVOURITE_LIST_SUCCESS:
        let newState = {...state};
        let favKey;

        if (action.payload.category) {
            favKey = `${action.payload.slug}_${action.payload.category}`;

        } else {
            favKey = action.payload.slug;
        }
        newState.fav_accounts[favKey] = [];

        Object.keys(action.payload.data).forEach(function(key) {
            newState.fav_accounts[favKey].push(action.payload.data[key]);
        });

        return newState
    case Actions.ADD_FAVOURITE_SUCCESS:
        let newState2 = {...state};

        if (newState2.fav_accounts[action.payload.slug]) {

            const data2 = newState2.fav_accounts[action.payload.slug];
            let newData2 = [];

            // For edit
            data2.forEach(function(datum2, index) {
                if (datum2.name !== action.payload.name) {
                    newData2.push(datum2);
                } else {
                    newData2[index] = action.payload;
                }
            });

            newState2.fav_accounts[action.payload.slug] = newData2;

        } else {
            // For first item
        
            console.log("HEREEE");
            newState2.fav_accounts[action.payload.slug] = [];
            newState2.fav_accounts[action.payload.slug] = [action.payload]

        }
        return newState2;

   case Actions.DELETE_FAVOURITE_LIST_SUCCESS:

        const {name,slug, category} = action.payload;
        let favKeyDelete;

        if (category) {
            favKeyDelete = `${slug}_${category}`;

        } else {
            favKeyDelete = slug;
        }

        let newState3 = {...state};

        console.log(newState3, favKeyDelete);

        const data = newState3.fav_accounts[favKeyDelete];
        let newData = [];

        data.forEach(function(datum) {
            if (datum.name !== name) {
               newData.push(datum);
            } 
        });
        
        newState3.fav_accounts[favKeyDelete] = newData;

        return newState3;

    default:
      return state;
  }
}

export default combineReducers({
    list: reducer
});

