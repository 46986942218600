export const NAME = "AUTH";

export const FORGOT_PASSWORD = `${NAME}/FORGOT_PASSWORD`;
export const FORGOT_PASSWORD_SUCCESS = `${NAME}/FORGOT_PASSWORD_SUCCESS`;
export const FORGOT_PASSWORD_FAILED = `${NAME}/FORGOT_PASSWORD_FAILED`;

export const CHECK_RESET_CODE = `${NAME}/CHECK_RESET_CODE`;
export const CHECK_RESET_CODE_SUCCESS = `${NAME}/CHECK_RESET_CODE_SUCCESS`;
export const CHECK_RESET_CODE_FAILED = `${NAME}/CHECK_RESET_CODE_FAILED`;

export const UPDATE_PASSWORD = `${NAME}/UPDATE_PASSWORD`;
export const UPDATE_PASSWORD_SUCCESS = `${NAME}/UPDATE_PASSWORD_SUCCESS`;
export const UPDATE_PASSWORD_FAILED = `${NAME}/UPDATE_PASSWORD_FAILED`;

export const CHANGE_PASSWORD = `${NAME}/CHANGE_PASSWORD`;
export const CHANGE_PASSWORD_SUCCESS = `${NAME}/CHANGE_PASSWORD_SUCCESS`;
export const CHANGE_PASSWORD_FAILED = `${NAME}/CHANGE_PASSWORD_FAILED`;

export const forgotPassword = (data, callback, callbackError) => ({
    type: FORGOT_PASSWORD,
    data,
    callback,
    callbackError
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordFailed = () => ({
  type: FORGOT_PASSWORD_FAILED
});

// CHECK RESET CODE
export const checkResetCode = (data, callback, callbackError) => ({
    type: CHECK_RESET_CODE,
    data,
    callback,
    callbackError
});

export const checkResetCodeSuccess = () => ({
  type: CHECK_RESET_CODE_SUCCESS
});

export const checkResetCodeFailed = () => ({
  type: CHECK_RESET_CODE_FAILED
});
// END RESET CODE

export const updatePassword = (data, callback, callbackError) => ({
  type: UPDATE_PASSWORD,
  data,
  callback,
  callbackError
});

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS
});

export const updatePasswordFailed = () => ({
  type: UPDATE_PASSWORD_FAILED
});

export const changePassword = (data, callback, callbackError) => ({
    type: CHANGE_PASSWORD,
    data,
    callback,
    callbackError
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS
});

export const changePasswordFailed = () => ({
  type: CHANGE_PASSWORD_FAILED
});

