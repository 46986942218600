import { all, fork } from "redux-saga/effects";
import userProfile from "./userProfile";
// import updateUserPhone from "./updateUserPhone";
// import updatePhoneVerify from "./updatePhoneVerify";

export default function* home() {
  yield all([
    fork(userProfile),
    // fork(updateUserPhone),
    // fork(updatePhoneVerify)
  ]);
}
