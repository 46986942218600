export const NAME = "HOME";

export const HOME = `${NAME}/HOME`;
export const HOME_SUCCESS = `${NAME}/HOME_SUCCESS`;
export const HOME_FAILED = `${NAME}/HOME_FAILED`;
export const RESET_HOME = `${NAME}/RESET_HOME`;

export const getHomeData = store => store[NAME].home;

export const home = data => ({
  type: HOME,
  data
});

export const homeSuccess = data => ({
  type: HOME_SUCCESS,
  data
});

export const homeFailed = error => ({
  type: HOME_FAILED,
  error
});

export const resetHome = () => ({
  type: RESET_HOME
});
