import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment"

const ParkingCompoundPayment = ({location}) => {
    console.log(location)
    
    const formatCurrency = d => {
        let t = d / 100
        return `${t.toFixed(2)}`
    }

    // return (
    //     <div className="parking-compound-payment-form">
    //         <div className="form-wrapper">
    //             <div className="input-holder">
    //                 <label htmlFor="name">Date Issued</label>
    //                 <input type="text" placeholder="Date Issued" name="dateIssued" value={moment(location.state.violation_timestamp).format('DD/MM/YYYY, HH:mmA')} disabled />
    //             </div>

    //             <div className="side-by-side">
    //                 <div className="input-holder">
    //                     <label htmlFor="name">Compound Number</label>
    //                     <input type="text" placeholder="Compound Number" name="compoundNumber" value={location.state.service_reference_1} disabled />
    //                 </div>
    //                 <div className="input-holder">
    //                     <label htmlFor="name">Plate Number</label>
    //                     <input type="text" placeholder="Plate Number" name="plateNumber" disabled value={location.state.registration_number} />
    //                 </div>
    //             </div>

    //             <div className="input-holder">
    //                 <label htmlFor="name">Reason</label>
    //                 <input type="text" placeholder="section" name="section" disabled value={location.state.violation_type} />
    //             </div>

    //             <div className="input-holder">
    //                 <label htmlFor="name">Location</label>
    //                 <input type="text" placeholder="location" name="location" value={location.state.violation_location} disabled />
    //             </div>

    //             <div className="input-holder data-display">
    //                 <label htmlFor="name">Pay Amount</label>
    //                 <p className="top-right">in 14 days</p>
    //                 <input type="text" placeholder="" name="paymentAmount" value={formatCurrency(location.state.compound_amount)} disabled />
    //             </div>

    //             <div className="input-holder">
    //                 <Link
    //                     to={{
    //                         pathname: "/parking_compound/pay",
    //                         state: {
    //                             paymentData: [
    //                                 {
    //                                     title: "Compound Number",
    //                                     value: location.state.service_reference_1
    //                                 },
    //                                 {
    //                                     title: "Car Plate",
    //                                     value: location.state.registration_number
    //                                 },
    //                             ],
    //                             data: {
    //                                 service_reference_1: location.state.service_reference_1,
    //                                 service_reference_2: location.state.service_reference_2,
    //                                 registration_number: location.state.registration_number,
    //                                 compound_amount: location.state.compound_amount,
    //                                 pbtid: location.state.pbtid
    //                             },
    //                             amount: (location.state.compound_amount/100).toFixed(2)
    //                         }
    //                     }}
    //                 >
    //                     <input type="submit" value="Next" />
    //                 </Link>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className="row">
            <div className="col-sm-4 col-md-8">
                <div className="form-group">
                    <label htmlFor="">Date Issued</label>
                    <input type="text" className="form-control" name="name" disabled value={moment(location.state.violation_timestamp).format('DD/MM/YYYY, HH:mmA')} />
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Compound Number</label>
                            <input type="text" className="form-control" name="name" disabled value={location.state.service_reference_1} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Plate Number</label>
                            <input type="text" className="form-control" name="name" disabled value={location.state.registration_number} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="">Reason</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.violation_type} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Location</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.violation_location} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Amount</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">RM</span>
                        </div>
                        <input type="number" className="form-control" name="name" disabled value={formatCurrency(location.state.compound_amount)} />
                    </div>
                </div>
                <Link
                    to={{
                        pathname: "/parking_compound/pay",
                        state: {
                            paymentData: [
                                {
                                    title: "Compound Number",
                                    value: location.state.service_reference_1
                                },
                                {
                                    title: "Car Plate",
                                    value: location.state.registration_number
                                },
                            ],
                            data: {
                                service_reference_1: location.state.service_reference_1,
                                service_reference_2: location.state.service_reference_2,
                                registration_number: location.state.registration_number,
                                compound_amount: location.state.compound_amount,
                                pbtid: location.state.pbtid
                            },
                            amount: (location.state.compound_amount/100).toFixed(2)
                        }
                    }}
                >
                    <button className="btn btn-primary w-100 mt-4">Next</button>
                </Link>
            </div>
        </div>
    )
};

export default ParkingCompoundPayment;