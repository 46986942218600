import React from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux"

import Actions from "../../actions"

import LoadingOverlay from "../../components/LoadingOverlay"

import FavouriteModal from "../../components/FavouriteModal"

const LandTaxDropdown = props => {
    const [favouriteActive, setFavouriteActive] = React.useState(null);
    const [favouriteModal, setFavouriteModal] = React.useState(false)
    const [taxNumber, setTaxNumber] = React.useState("")
    const dispatch = useDispatch()
    const {fav_accounts} = useSelector(state => state.FAVOURITE.list)
    const {data: {auth_token}} = useSelector(state => state.PROFILE.userSession)
    const [inputError, setInputError] = React.useState({
        tax: ""
    });
    const {isLoading, error, data} = useSelector(state => state.FEEDERS.getAssessmentTax)
    const [input, setInput] = React.useState({
        name: "",
        ic: "",
        tax: "",
        address: "",
        amount: ""
    })
    const landTax = useSelector(state => state.FEEDERS.getLandTax)

    React.useEffect(() => {
        dispatch(Actions.fetchListFavourites(props.pbtid === "ptg" ? "ptg": props.pbtid, props.pbtid === "ptg" ? 'utilities' : "assessment"))
    }, [])
    
    const checkAssessment = () => {
        if (!props.manual) {
            if (!input.tax) {
                setInputError(state => ({...state, tax: "This value is required."}))
            } else {
                const paramString = `${props.pbtid}/tax/assessment?account_number=${input.tax}`
                dispatch(Actions.getAssessmentTax({paramString}))
            }
            return
        }

        props.history.push({pathname: '/assessment_tax/pay', state: {
            paymentData: [
                {
                    title: "Name",
                    value: input.name
                },
                {
                    title: "MyKad Number",
                    value: input.ic
                },
                {
                    title: "Tax Number",
                    value: input.tax
                },
                {
                    title: "Address",
                    value: input.address
                },
            ],
            name: input.name,
            ic: input.ic,
            tax: input.tax,
            address: input.address,
            amount: input.amount,
            manual: props.manual,
            id: props.pbtid
        }})
    }

    const checkLandTax = () => {
        dispatch(Actions.getLandTax({paramString: input.tax}))
    }

    React.useEffect(() => {
        if (!isLoading && (Object.keys(data).length > 0 || error)) {
            if (Object.keys(data).length > 0) {
                props.history.push({pathname: `/pay/${props.pbtid}/land_tax/form`, state: data})
            } else {
                setInputError(state => ({...state, tax: "Account number is invalid"}))
            }
        }
    }, [isLoading, data, error])

    React.useEffect(() => {

        if (!landTax.isLoading && (Object.keys(landTax.data).length > 0 || landTax.error)) {
            if (Object.keys(landTax.data).length > 0) {
                landTax.data['pbtid'] = props.pbtid
                props.history.push({pathname: `/pay/${props.pbtid}/land_tax/form`, state: landTax.data})
            } else {
                setInputError(state => ({...state, tax: "Account number is invalid"}))
            }
        }

    }, [landTax])

    const handleChange = e => {
        const {name, value} = e.target
        setInput(state => ({...state, [name]: value}))
    }

    return (
        <div className="row">
            {(isLoading || landTax.isLoading) && <LoadingOverlay />}
            <FavouriteModal slug={props.pbtid === "ptg" ? "ptg" : props.pbtid} category={props.pbtid === "ptg" ? 'utilities' : 'assessment'} favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} label={props.pbtid === "ptg" ? 'Title number' : 'Tax number'} />
            <div className="col-sm-4 col-md-8">
                {/* <div className="card border shadow-md">
                    <div className="p-4"> */}
                    {props.manual && (
                        <>
                            <div className="form-group">
                                <label htmlFor="">Name</label>
                                <input type="text" className="form-control" name="name" onChange={handleChange} value={input.name} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">MyKad Number</label>
                                <input type="number" className="form-control" name="ic" onChange={handleChange} value={input.ic} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Tax Number</label>
                                <input type="number" className="form-control" name="tax" onChange={handleChange} value={input.tax} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Address</label>
                                <input type="text" className="form-control" name="address" onChange={handleChange} value={input.address} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <input type="text" className="form-control" name="amount" onChange={handleChange} value={input.amount} />
                            </div>
                        </>
                    )}
                    {!props.manual && (
                        <>
                            <div className="form-group">
                                <label htmlFor="">{props.pbtid === "ptg" ? 'Title Number' : 'Tax Number'}</label>
                                <input type="text" className={`form-control ${inputError.tax ? 'is-invalid' : ''}`} name="tax" disabled={favouriteActive !== null} onChange={handleChange} value={input.tax} />
                                {inputError.tax && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
                                <li class="parsley-required">{inputError.tax}</li>
                                </ul>}
                            </div>
                            <div className="row m-0 justify-content-between">
                                <p className="text-muted">My Favourite</p>
                                <div className="i-con-h-a" onClick={() => setFavouriteModal(true)}>
                                <i className="i-con i-con-plus"><i></i></i>
                                </div>
                            </div>
                            <div className="row row-sm">
                                <div className="col">
                                {fav_accounts.hasOwnProperty(props.pbtid === "ptg" ? 'ptg_utilities' : `${props.pbtid}_assessment`) && fav_accounts[props.pbtid === "ptg" ? "ptg_utilities" : `${props.pbtid}_assessment`].map((fav, i) => (
                                    <div className={`card border no-shadow i-con-h-a pointer ${favouriteActive === i ? 'border-primary' : ''}`} onClick={() => {
                                        setFavouriteActive(favouriteActive === null ? i : favouriteActive === i ? null : i)
                                        setInput(state => favouriteActive === null ? ({...state, tax: fav.phoneNumber}) : favouriteActive === i ? ({...state, tax: ""}) : ({...state, tax: fav.phoneNumber}))
                                    }}>
                                    <div className="p-2">
                                        <div className="row row-sm">
                                        <div className="col-auto">
                                        <i className="i-con i-con-ok"><i></i></i>
                                        </div>
                                        <div className="col">
                                            {fav.name} - {fav.phoneNumber}
                                        </div>
                                        <div className="col-auto i-con-h-a">
                                            <i className={`i-con i-con-trash`} onClick={(e) => {
                                            e.stopPropagation()
                                            dispatch(Actions.deleteFavourite(props.pbtid === "ptg" ? "ptg" : props.pbtid, fav.name, props.pbtid === "ptg" ? 'utilities' : "assessment"))
                                            }}><i></i></i>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </>
                    )}
                    <button className="btn btn-primary w-100 mt-2" disabled={!props.manual ? !input.tax : !input.tax || !input.name || !input.ic || !input.address || !input.amount} onClick={props.pbtid === "ptg" ? checkLandTax : checkAssessment}>NEXT</button>
                    {/* </div>
                </div> */}
            </div>
        </div>
    );

    // return (
    //     <div className="content-form land-tax">
    //         {isLoading || landTax.isLoading && <LoadingOverlay />}
    //         <div className="form-wrapper">
    //             {props.manual && (
    //                 <>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Name</label>
    //                         <input type="text" onChange={handleChange} placeholder="Name" name="name" value={input.name} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">MyKad Number</label>
    //                         <input type="text" onChange={handleChange} placeholder="Name" name="ic" value={input.ic} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Tax Number</label>
    //                         <input type="text" onChange={handleChange} placeholder="Name" name="tax" value={input.tax} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Address</label>
    //                         <input type="text" onChange={handleChange} placeholder="Name" name="address" value={input.address} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="">Amount</label>
    //                         <div className="amount-input">
    //                             <h4>RM</h4>
    //                             <input type="text" name="amount" placeholder="0" onChange={handleChange} value={input.amount} />
    //                         </div>
    //                     </div>
    //                 </>
    //             )}

    //             {!props.manual && (
    //                 <>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Tax Number</label>
    //                         <input type="text" onChange={e => setTaxNumber(e.target.value)} placeholder="Name" name="name" value={taxNumber} />
    //                     </div>
    //                     <div className="favourite-holder">
    //                         <h4>My Favourite</h4>
    //                         <div className="holder" onClick={() => setFavouriteActive(favouriteActive === null ? 0 : null)}>
    //                             <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt="" />
    //                             <p>My favourite - 01244445423</p>
    //                         </div>
    //                         <div className="holder" onClick={() => setFavouriteActive(favouriteActive === null ? 1 : null)}>
    //                             <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt="" />
    //                             <p>Mom - 01244445423</p>
    //                         </div>
    //                     </div>
    //                 </>
    //             )}

    //             {/* <Link to="/pay/kuantan/land_tax/form"> */}
    //                 <button onClick={props.pbtid === "ptg" ? checkLandTax : checkAssessment}>{props.manual ? 'Next' : 'Search'}</button>
    //             {/* </Link> */}
    //         </div>
    //     </div>        
    // );
};

export default LandTaxDropdown;