import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getPaymentMethods() {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    console.log("lala")

    // dynamically create method array later
    const method_types = ["credit_card", "online_banking", "ewallet"];
    let methodByTypes = [];
    for (let i=0; i<method_types.length; i++) {
        methodByTypes.push({ type: method_types[i], methods: [] })
    }

    const { response, error } = yield call(api.getPaymentMethods, headers);
    if (response) {
        if (response.data.data) {
            const methods = response.data.data;
            for (const method of methods) {
                for (const [i, type] of methodByTypes.entries()) {
                    if (type.type === method.type) {
                        methodByTypes[i].methods = [...methodByTypes[i].methods, method]
                    }
                }
            }
            yield put(Actions.getPaymentMethodsSuccess(methodByTypes));
            // yield put(Actions.updateSavedPaymentMethods(methodByTypes));
        }
    } else {
        yield put(Actions.getPaymentMethodsFail(error.response.data));
    }
}

function* watchGetPaymentMethods() {
    yield takeLatest(Actions.GET_PAYMENT_METHODS, getPaymentMethods);
}

export default function* submit() {
    yield all([
        fork(watchGetPaymentMethods)
    ])
}