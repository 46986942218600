import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"

import Actions from "../actions";

const Header = props => {
    const [showDropdown, setShowDropdown] = React.useState(false)
    const dispatch = useDispatch()
    const profileData = useSelector(state => state.PROFILE.userProfile);
    const userSession = useSelector(state => state.PROFILE.userSession)
    
    React.useEffect(() => {
        // if (Object.keys(userSession.data).length === 0) {
        //     dispatch(Actions.getUserProfile())
        // }
    },[])

    React.useEffect(() => {
        if (Object.keys(userSession.data).length === 0) {
            props.history.push('/')
        }
    }, [userSession])

    // return (
    //     <div className="header-wrap">
    //         <div className="header-inner">
    //             <div className="left">
    //                 <Link to="/" className="home-link">
    //                     <img src={require('../assets/images/logo.png')} />
    //                 </Link>
    //             </div>
    //             {/* <div className="middle">
    //                 <input type="text" placeholder="Search..." />
    //                 <img src={require('../assets/images/search.png')} alt="search"/>
    //             </div> */}
    //             <div className="right">
    //                 <Link to="/payment_history" style={{textDecoration: 'none'}}><div style={{marginRight: "40px", color: "#9FA9BA"}}>Payment History</div></Link>
    //                 <div className="dropdown-nav" onClick={() => setShowDropdown(!showDropdown)}>
    //                     <img src={profileData.data.picture !== null ? profileData.data.picture : require('../assets/images/profile-picture.png')} alt=""/>
    //                     <div className="profile-dropdown-wrap">
    //                         <p>Hello, {profileData.data.hasOwnProperty('name') && profileData.data.name.split(" ")[0]}</p>
    //                         {showDropdown && (
    //                             <div className="profile-dropdown">
    //                                 <Link to="/profile_settings" className="dropdown-link">
    //                                     Profile
    //                                 </Link>
    //                                 <Link to="/account" className="dropdown-link">
    //                                     Account Setting
    //                                 </Link>
    //                                 <Link to="/payment_method" className="dropdown-link">
    //                                     Payment Method
    //                                 </Link>
    //                                 <Link to="/" className="dropdown-link">
    //                                     Support
    //                                 </Link>
    //                                 <button className="dropdown-link border-top" onClick={() => {
    //                                     dispatch(Actions.clearUserProfile())
    //                                     dispatch(Actions.resetUserSession())
    //                                     props.history.push('/')
    //                                 }}>
    //                                     Log out
    //                                 </button>
    //                             </div>
    //                         )}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <header id="header" className="page-header bg-white box-shadow animate fadeInDown sticky">
            <div className="navbar navbar-expand-lg">
                <a href="#" className="navbar-brand">
                    <img src={require('../assets/images/pahanggo-logo.svg')} />
                </a>

                {/* <div className="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <a className="nav-link" href="page.profile.html">
                                <span className="nav-text">Payment History</span>
                            </a>
                        </li>
                    </ul>
                </div> */}

                <ul className="nav navbar-menu order-1 order-lg-2">
                    <li className={`nav-item ${props.location.pathname === "/payment_history" && 'active'}`}>
                        <Link to="/payment_history" className="nav-link">
                            <span className="nav-text">Payment History</span>
                        </Link>
                    </li>
                    <li className="nav-item dropdown">
                        <div onClick={() => setShowDropdown(state => !state)} className="nav-link d-flex align-items-center py-0 px-lg-0 px-2 text-color" style={{cursor: "pointer"}}>
                            <span className="mx-2 d-none l-h-1x d-lg-block text-right">
                                <small className='text-fade d-block mb-1'>Hello, Welcome</small>
                                <span>{profileData.data.hasOwnProperty('name') && profileData.data.name}</span>
                            </span>
                            <span className="avatar w-36">
                                <img src={profileData.data.picture !== null ? profileData.data.picture : require('../assets/images/profile-picture.png')} alt="dp" style={{height: "100%"}}/>
                            </span>
                        </div>
                        <div className={`dropdown-menu dropdown-menu-right w mt-3 animate fadeIn ${showDropdown ? 'show' : ''}`}>
                            <Link className="dropdown-item" to="/profile_settings" onClick={() => setShowDropdown(false)}>
                                <span>Profile</span>
                            </Link>
                            <Link className="dropdown-item" to="/account" onClick={() => setShowDropdown(false)}>
                                <span>Account Settings</span>
                            </Link>
                            <Link className="dropdown-item" to="/payment_method" onClick={() => setShowDropdown(false)}>
                                <span>Payment Method</span>
                            </Link>
                            <div className="dropdown-divider"></div>
                            {/* <a className="dropdown-item" href="page.setting.html">
                                <span>Support</span>
                            </a> */}
                            <div className="dropdown-item pointer" onClick={() => {
                                        dispatch(Actions.logout())
                                        // props.history.push('/')
                            }}>
                                <span>Sign out</span>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        </header>
    )
};

export default Header;