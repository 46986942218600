import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
// import Fade from 'react-reveal/Fade';
// import Flip from 'react-reveal/Flip';

import Actions from "../actions"

const Home = () => {
    const dispatch = useDispatch()
    const {isLoading, error, data} = useSelector(state => state.HOME)

    React.useEffect(() => {
        if(Object.keys(data).length === 0)
            dispatch(Actions.home("THIS_MONTH"))
    }, [])

    React.useEffect(() => {
        console.log(data)
    }, [data])

    const handleChange = e => {
        dispatch(Actions.home(e.target.value))
    }

    return (
        <div id="content" className="flex">
            <div className="h-100 padding">
                {/* <div className="block flex mb-0"> */}
                    <div className="page-title pb-0 d-flex align-items-center justify-content-between">
                        <h2 className="text-md mb-0">Home</h2>
                        <div className="toolbar">
                            <select className="form-control w-auto" onChange={handleChange}>
                                <option value="THIS_MONTH">This month</option>
                                <option value="LAST_MONTH">Last month</option>
                                <option value="THIS_YEAR">This year</option>
                            </select>
                            {/* <select className="form-control w-auto">
                                <option value="">All types</option>
                                <option value="">This month</option>
                                <option value="">This year</option>
                            </select> */}
                            {/* <button className="btn btn-primary i-con-ha">
                                <i className="i-con i-con-download mr-2"><i></i></i>
                                Export Statement
                            </button> */}
                            {/* <form className="flex" style={{width: '300px'}}>
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search..." />
                                    <span className="input-group-append">
                                        <button className="btn btn-white no-shadow i-con-ha">
                                            <i className="i-con i-con-search text-muted"></i>
                                        </button>
                                    </span>
                                </div>
                            </form> */}
                        </div>
                    </div>
                    <div className="pt-4">
                    <div className="block px-4 py-4">
                        {isLoading && <div className="d-flex align-items-center justify-content-center py-4">
                            <div className="loading w-48">
                            </div>
                        </div>}
                        {Object.keys(data).length > 0 && (
                            <div className="row row-sm">
                                {/* <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-comment b-2x text-primary"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <a href="app.message.html" className="d-block ajax"><strong>60</strong></a>
                                    <small className="text-muted">Parking</small>
                                    </div>
                                </div>
                                </div> */}
                                <div className="col-lg-3 col-6 pb-4">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-droplet b-2x text-success"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.paip).toFixed(2)}</strong></p>
                                    <small className="text-muted">PAIP</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-phone b-2x text-warning"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.prepaid).toFixed(2)}</strong></p>
                                    <small className="text-muted">Prepaid</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-phone b-2x text-danger"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.postpaid).toFixed(2)}</strong></p>
                                    <small className="text-muted">Postpaid</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-file b-2x text-primary"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.roadtax).toFixed(2)}</strong></p>
                                    <small className="text-muted">Roadtax</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-archive b-2x text-danger"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.education).toFixed(2)}</strong></p>
                                    <small className="text-muted">Education</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-globe b-2x text-success"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.zakat).toFixed(2)}</strong></p>
                                    <small className="text-muted">Zakat</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-pin b-2x text-success"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.parking).toFixed(2)}</strong></p>
                                    <small className="text-muted">Parking</small>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-3 col-6">
                                <div className="d-flex align-items-center i-con-h-a my-1">
                                    <div>
                                    <span className="avatar w-40 b-a b-2x">
                                        <i className="i-con i-con-grid b-2x text-success"><i></i></i>
                                    </span>
                                    </div>
                                    <div className="mx-3">
                                    <p className="mb-0"><strong>RM{parseFloat(data.bus_ticket).toFixed(2)}</strong></p>
                                    <small className="text-muted">Bus</small>
                                    </div>
                                </div>
                                </div>
                            </div>
                        
                        )}
                        </div>
                </div>
                </div>
            {/* </div> */}
        </div>
    );
};

export default Home;   