import React from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector, connect } from "react-redux";

import TextInput from "../components/TextInput.js";

import Actions from "../actions";

import CreditDebit from "../components/PaymentMethod/CreditDebit"
import EWallet from "../components/PaymentMethod/EWallet"
import BankTransfer from "../components/PaymentMethod/BankTransfer"
import { addCardPaymentMethod } from '../actions/payment/addCardPaymentMethod.js';

import LoadingOverlay from "../components/LoadingOverlay"

const PaymentMethod = () => {
    const [paymentType, setPaymentType] = React.useState(null)
    const [methods, setMethods] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const {isLoading, error, data} = useSelector(state => state.PAYMENT.getPaymentMethods);
    const defaultPayment = useSelector(state => state.PAYMENT.setDefaultPaymentMethod);
    const addData = useSelector(state => state.PAYMENT.addPaymentMethod);
    const deleteData = useSelector(state => state.PAYMENT.deletePaymentMethod)
    const addCardData = useSelector(state => state.PAYMENT.addCardPaymentMethod)
    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(Actions.getPaymentMethods())
    }, [])

    React.useEffect(() => {
        if (addData.data && !addData.error && !addData.isLoading)
            dispatch(Actions.getPaymentMethods())
            setPaymentType(null)
    }, [addData])

    React.useEffect(() => {
        // console.log(data)
        if (!error && data.length > 0)
            setMethods(data)
    }, [data])

    React.useEffect(() => {
        if (deleteData.data.hasOwnProperty("success") && !deleteData.error && !deleteData.isLoading)
            dispatch(Actions.getPaymentMethods())
    }, [deleteData])

    const deletePayment = id => {
        dispatch(Actions.deletePaymentMethod({paramString: id}))
    }

    React.useEffect(() => {
        // console.log(addCardData)
        if (!addCardData.error && Object.keys(addCardData.data).length > 0)
            window.open(addCardData.data.redirectUrl)
    }, [addCardData])

    React.useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    const addPaymentMethod = d => {
        var data = {}

        if (d.type === "online_banking") {
            data = {
                is_online_banking: true,
                bank_name: d.bank_name,
                card_name: d.card_name,
                country: "MY"
            }
        } else {
            data = {
                is_ewallet: true,
                bank_name: d.bank_name,
                card_name: d.card_name,
            }
        }

        dispatch(Actions.addPaymentMethod(data))
    }

    const addCardPaymentMethod = () => {
        dispatch(Actions.addCardPaymentMethod({is_credit_card: true}))
    }

    React.useEffect(() => {
        if (defaultPayment.data && !defaultPayment.error && !defaultPayment.isLoading)
            dispatch(Actions.getPaymentMethods())

    }, [defaultPayment])

    const setDefault = id => {
        dispatch(Actions.setDefaultPaymentMethod({paramString: id}))
    }

    // return (
    //     <div className="content payment-method">
    //         {loading && <LoadingOverlay />}

    //         <h1 className="content-title">Payment Method</h1>

    //         <div className="content-row">

    //             <div className="content-card half">
    //                 <table>
    //                     <tbody>
    //                         <tr>
    //                             <td className="align-left">
    //                                 <div className="cards">
    //                                     <img src={require('../assets/images/visa.png')} alt="" />
    //                                     <img src={require('../assets/images/mastercard.png')} alt="" />
    //                                 </div>
    //                             </td>

    //                             <td className="align-left">
    //                                 <p>Credit / Debit Card</p>
    //                             </td>

    //                             <td className="align-right">
    //                                 <img src={require(paymentType !== null && paymentType !== 0 ? '../assets/images/add-gray.png' : paymentType === 0 ? '../assets/images/minus.png' : '../assets/images/add.png')} alt="" className="add-method" onClick={() => setPaymentType(state => state === null ? 0 : null)} alt="" className="add-method" onClick={addCardPaymentMethod}/>
    //                             </td>
    //                         </tr>
    //                         {methods.length > 0 && methods[0].methods.map(d => (
    //                             <tr key={d.card_name}>
    //                                 <td className="align-left">
    //                                 </td>

    //                                 <td className="align-left">
    //                                     <div className="card-detail">
    //                                         <img src={d.logo} alt="" />
    //                                         <p>{d.bank_name}</p>
    //                                         {d.is_default === 1 && 
    //                                             <div className="default">
    //                                                 Default
    //                                             </div>
    //                                         }
    //                                     </div>
    //                                 </td>

    //                                 <td className="align-right">
    //                                 <button onClick={() => deletePayment(d.id)}>Delete</button>
    //                                 </td>
    //                             </tr>
    //                         ))}

    //                         <tr>
    //                             <td className="align-left">
    //                                 <img src={require('../assets/images/mastercard.png')} alt="" />
    //                             </td>

    //                             <td className="align-left">
    //                                 <p>Online Banking</p>
    //                             </td>

    //                             <td className="align-right">
    //                                 <img src={require(paymentType !== null && paymentType !== 1 ? '../assets/images/add-gray.png' : paymentType === 1 ? '../assets/images/minus.png' : '../assets/images/add.png')} alt="" className="add-method" onClick={() => setPaymentType(state => state === null ? 1 : null)} alt="" className="add-method"/>
    //                             </td>
    //                         </tr>
    //                         {methods.length > 0 && methods[1].methods.map(d => (
    //                             <tr key={d.card_name}>
    //                                 <td className="align-left">
    //                                 </td>

    //                                 <td className="align-left">
    //                                     <div className="card-detail">
    //                                         <img src={d.logo} alt="" />
    //                                         <p>{d.bank_name}</p>
    //                                     </div>
    //                                 </td>

    //                                 <td className="align-right">
    //                                     {/* {d.is_default === 1 &&  */}
    //                                     <div className={`default ${d.is_default === 1 ? 'active' : ''}`} onClick={() => setDefault(d.id)}>
    //                                         Default
    //                                     </div>
    //                                     {/* } */}
    //                                     <button onClick={() => deletePayment(d.id)}>Delete</button>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                         <tr>
    //                             <td className="align-left">
    //                                 <img src={require('../assets/images/mastercard.png')} alt="" />
    //                             </td>

    //                             <td className="align-left">
    //                                 <p>E-wallet</p>
    //                             </td>

    //                             <td className="align-right">
    //                                 <img src={require(paymentType !== null && paymentType !== 2 ? '../assets/images/add-gray.png' : paymentType === 2 ? '../assets/images/minus.png' : '../assets/images/add.png')} alt="" className="add-method" onClick={() => setPaymentType(state => state === null ? 2 : null)}/>
    //                             </td>
    //                         </tr>
    //                         {methods.length > 0 && methods[2].methods.map(d => (
    //                             <tr key={d.card_name}>
    //                                 <td className="align-left">
    //                                 </td>

    //                                 <td className="align-left">
    //                                     <div className="card-detail">
    //                                         <img src={d.logo} alt="" />
    //                                         <p>{d.bank_name}</p>
    //                                     </div>
    //                                 </td>

    //                                 <td className="align-right">
    //                                 <div className={`default ${d.is_default === 1 ? 'active' : ''}`} onClick={() => setDefault(d.id)}>
    //                                     Default
    //                                 </div>
    //                                 <button onClick={() => deletePayment(d.id)}>Delete</button>
    //                                 </td>
    //                             </tr>
    //                         ))}
    //                     </tbody>

    //                 </table>
    //             </div>
    //             {paymentType != null && (
    //                 <div className="content-card half smaller-padding">
    //                     {paymentType == 0 && (
    //                         <CreditDebit/>
    //                     )}
    //                     {paymentType == 1 && (
    //                         <BankTransfer addPaymentMethod={addPaymentMethod}/>
    //                     )}
    //                     {paymentType == 2 && (
    //                         <EWallet addPaymentMethod={addPaymentMethod}/>
    //                     )}
    //                 </div>
    //             )}
    //         </div>
    //     </div>
    // );

    return (
        <div className="flex" id="content">
            <div className="h-100 d-flex padding" id="page-container">
                <div className="block flex mb-0">
                    <div className="page-title padding pb-0 ">
                        <h2 className="text-md mb-0">Payment Method</h2>
                    </div>
                    <div className="padding">
                        {/* <div className="row"> */}
                            <div className="d-flex">
                                <div className="col-sm-4 pl-0">
                                    <div className="card no-shadow border p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            Credit / Debit Card
                                            <div className="pointer i-con-h-a" onClick={addCardPaymentMethod}>
                                                <i className={`i-con ${paymentType !== null && paymentType !== 0 ? 'i-con-plus' : paymentType === 0 ? 'i-con-minus text-primary' : 'i-con-plus text-primary'}`}><i></i></i>
                                            </div>
                                        </div>
                                        <div className="pt-3 list list-row">
                                            {methods.length > 0 && methods[0].methods.map(d => (
                                                <div className="d-flex list-item justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <img src={d.logo} className="pr-2" alt="" style={{width: "40px"}}/>
                                                        {d.bank_name}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className={`card px-3 py-1 pointer mb-0 mr-2 ${d.is_default === 1 ? 'bg-primary' : 'bg-light'} no-shadow`} onClick={() => setDefault(d.id)}>Default</div>
                                                        <div className="pointer i-con-h-a" onClick={() => deletePayment(d.id)}>
                                                            <i className="i-con i-con-trash"><i></i></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="card no-shadow border p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            Online Banking
                                            <div className="pointer i-con-h-a" onClick={() => setPaymentType(state => state === null ? 1 : null)}>
                                                <i className={`i-con ${paymentType !== null && paymentType !== 1 ? 'i-con-plus' : paymentType === 1 ? 'i-con-minus text-primary' : 'i-con-plus text-primary'}`}><i></i></i>
                                            </div>
                                        </div>
                                        <div className="pt-3 list list-row">
                                            {methods.length > 0 && methods[1].methods.map(d => (
                                                <div className="d-flex list-item justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <img src={d.logo} className="pr-2" alt="" style={{width: "40px"}}/>
                                                        {d.bank_name}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className={`card px-3 py-1 pointer mb-0 mr-2 ${d.is_default === 1 ? 'bg-primary' : 'bg-light'} no-shadow`} onClick={() => setDefault(d.id)}>Default</div>
                                                        <div className="pointer i-con-h-a" onClick={() => deletePayment(d.id)}>
                                                            <i className="i-con i-con-trash"><i></i></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="card no-shadow border p-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            E-Wallet
                                            <div className="pointer i-con-h-a" onClick={() => setPaymentType(state => state === null ? 2 : null)}>
                                                <i className={`i-con ${paymentType !== null && paymentType !== 2 ? 'i-con-plus' : paymentType === 2 ? 'i-con-minus text-primary' : 'i-con-plus text-primary'}`}><i></i></i>
                                            </div>
                                        </div>
                                        <div className="pt-3 list list-row">
                                            {methods.length > 0 && methods[2].methods.map(d => (
                                                <div className="d-flex list-item justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <img src={d.logo} className="pr-2" alt="" style={{width: "40px"}}/>
                                                        {d.bank_name}
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <div className={`card px-3 py-1 pointer mb-0 mr-2 ${d.is_default === 1 ? 'bg-primary' : 'bg-light'} no-shadow`} onClick={() => setDefault(d.id)}>Default</div>
                                                        <div className="pointer i-con-h-a" onClick={() => deletePayment(d.id)}>
                                                            <i className="i-con i-con-trash"><i></i></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex">
                                    {paymentType === 1 && (
                                        <BankTransfer addPaymentMethod={addPaymentMethod}/>
                                    )}
                                    {paymentType === 2 && (
                                        <EWallet addPaymentMethod={addPaymentMethod}/>
                                    )}
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default reduxForm({
    form: "paymentMethodForm",
    // validate
})(PaymentMethod);
