import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';


const getSessionData = store => store.PROFILE.userSession;

function* getZakatOptions() {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    const { response, error } = yield call(api.getZakatOptions, headers);
    if (response) {
        yield put(Actions.getZakatOptionsSuccess(response.data.data));
    } else {
       console.log('error', error);
    }
}


function* submitZakat({ data, callback }) {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    const { response, error } = yield call(api.submitZakat, data, headers);

    if (response) {
        callback && callback(response.data.data);
    } else {
       console.log('error', error);
    }
}

function* watchZakat() {
    yield takeLatest(Actions.SUBMIT_ZAKAT, submitZakat);
    yield takeLatest(Actions.GET_ZAKAT_OPTIONS, getZakatOptions);
}

export default function* zakat() {
    yield all([
        fork(watchZakat)
    ])
}