import React from "react";
import TextInput from "../../components/TextInput.js";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector, connect } from "react-redux";

const EducationForm = props => {
  const [cardActive, setCardActive] = React.useState(null);
  const [favouriteActive, setFavouriteActive] = React.useState(null);
  const [input, setInput] = React.useState({
    name: "",
    ic: "",
    amount: ""
  })

  const data = [
    {
      id: 26,
      fee: 1
    },
    {
      id: 27,
      fee: 1
    },
    {
      id: 28,
      fee: 1
    },
    {
      id: 29,
      fee: 1.5
    }
  ]

  const handleNext = d => {
    // console.log(d)
    // return
    props.history.push({
      pathname: '/education/pay',
      state: {
        paymentData: [
          {
            title: "Student Name",
            value: input.name
          },
          {
            title: "Student MyKad No.",
            value: input.ic
          },
          {
            title: "Admin Fee",
            value: `RM ${data[cardActive].fee.toFixed(2)}`
          }
        ],
        name: input.name,
        ic: input.ic,
        amount: +input.amount + data[cardActive].fee,
        id: data[cardActive].id
       }
    })
  }
  
  const handleChange = e => {
    const {name, value} = e.target
    setInput((state) => ({...state, [name]: value}))
  }

  // return (
  //   <div className="content home">
  //     <h1 className="content-title">Education</h1>

  //     <div className="content-card">
  //       <h4>Select</h4>
  //       <div className="card-holder">
  //         <div
  //           className={cardActive === 0 ? "active" : ""}
  //           onClick={() => setCardActive(cardActive !== null ? cardActive === 0 ? null : 0 : 0)}
  //         >
  //           <img
  //             src={require("../../assets/images/yayasan-logo.png")}
  //             style={{ marginTop: "10px" }}
  //             alt=""
  //           />
  //           <p>YAYASAN</p>
  //         </div>
  //         <div
  //           className={cardActive === 1 ? "active" : ""}
  //           onClick={() => setCardActive(cardActive !== null ? cardActive === 1 ? null : 1 : 1)}
  //         >
  //           <img src={require("../../assets/images/ucyp-logo.png")} alt="" />
  //           <p>UCYP</p>
  //         </div>
  //         <div
  //           className={cardActive === 2 ? "active" : ""}
  //           onClick={() => setCardActive(cardActive !== null ? cardActive === 2 ? null : 2 : 2)}
  //         >
  //           <img
  //             src={require("../../assets/images/kuipsas-logo.png")}
  //             alt=""
  //           />
  //           <p>KUIPSAS</p>
  //         </div>
  //         <div
  //           className={cardActive === 3 ? "active" : ""}
  //           onClick={() => setCardActive(cardActive !== null ? cardActive === 3 ? null : 3 : 3)}
  //         >
  //           <img src={require("../../assets/images/pahang_skills.png")} alt="" />
  //           <p>Pahang Skills</p>
  //         </div>
  //       </div>
  //     </div>

  //     {cardActive !== null && (
  //       <div className="content-form">
  //         <form onSubmit={props.handleSubmit(handleNext)}>
  //           <div className="form-wrapper">
  //             <Field
  //               component={TextInput}
  //               type="text"
  //               label="Student Name"
  //               name="studentName"
  //               placeholder="Enter fullname as per mykad"
  //             />

  //             <Field
  //               component={TextInput}
  //               type="text"
  //               label="Student Mykad Number"
  //               name="studentMykad"
  //               placeholder="Enter your MyKad number"
  //             />

  //             {/* <Field
  //               component={TextInput}
  //               type="text"
  //               label="Student ID"
  //               name="studentID"
  //               placeholder="Enter your student ID"
  //             /> */}

  //             <Field
  //               component={TextInput}
  //               type="text"
  //               label="Amount"
  //               name="amount"
  //               placeholder="0"
  //               className="amount"
  //             >
  //               <h4>RM</h4>
  //             </Field>

  //             {/* <div className={`favourite-holder ${number ? 'hidden' : ''}`}>
  //               <h4>My Favourite</h4>
  //               <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
  //                 <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt="" />
  //                 <p>Mine - 123456789</p>
  //               </div>
  //               <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 1 : favouriteActive === 1 ? null : 1)}>
  //                 <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt="" />
  //                 <p>Son - 3456789012</p>
  //               </div>
  //             </div> */}
  //             <button>NEXT</button>
  //           </div>
  //         </form>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div id="content" className="flex">
      {/* <FavouriteModal slug={slug} favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} /> */}
      <div className="h-100 d-flex padding" id="page-container">
        <div className="block flex mb-0">
        <div className="page-title padding pb-0 ">
            <h2 className="text-md mb-0">Education</h2>
        </div>
        <div className="padding">
          <div className="row">
            <div className="col-sm-4 col-md-8">
              {/* <div className="card shadow-md border"> */}
                <div className="text-muted">Select institution</div>
                <div className="pt-4">
                  <div className="row">
                    <div className="col-3">
                      <div className={`card pointer border no-shadow ${cardActive === 0 ? "border-primary" : ""} interactive`} onClick={() => setCardActive(cardActive !== null ? cardActive === 0 ? null : 0 : 0)}>
                        <div className="p-3 text-center">
                          <img height={50} src={require("../../assets/images/yayasan-logo.png")} alt=""/>
                          <p className="text-center m-2">YAYASAN</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className={`card pointer border no-shadow ${cardActive === 1 ? "border-primary" : ""} interactive`} onClick={() => setCardActive(cardActive !== null ? cardActive === 1 ? null : 1 : 1)}>
                        <div className="p-3 text-center">
                          <img height={50} src={require("../../assets/images/ucyp-logo.png")} alt=""/>
                          <p className="text-center m-2">UCYP</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className={`card pointer border no-shadow ${cardActive === 2 ? "border-primary" : ""} interactive`} onClick={() => setCardActive(cardActive !== null ? cardActive === 2 ? null : 2 : 2)}>
                        <div className="p-3 text-center">
                          <img height={50} src={require("../../assets/images/kuipsas-logo.png")} alt=""/>
                          <p className="text-center m-2">KUIPSAS</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className={`card pointer border no-shadow ${cardActive === 3 ? "border-primary" : ""} interactive`} onClick={() => setCardActive(cardActive !== null ? cardActive === 3 ? null : 3 : 3)}>
                        <div className="p-3 text-center">
                          <img height={50} src={require("../../assets/images/pahang_skills.png")} alt=""/>
                          <p className="text-center m-2">Pahang Skills</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          {cardActive !== null && (
            <div className="row">
              <div className="col-sm-4 col-md-8">
                  {/* <div className="card shadow-md border"> */}
                    {/* <div className="p-4"> */}
                      <div className="form-group">
                        <label htmlFor="">Student Name</label>
                        <input type="text" className="form-control" name="name" onChange={handleChange} value={input.name}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="">Student MyKad Number</label>
                        <input type="text" className="form-control" name="ic" onChange={handleChange} value={input.ic}/>
                      </div>
                      {/* {list[cardActive].have_bills === 0 && ( */}
                        <div className="form-group">
                          <label htmlFor="">Amount</label>
                          <div className="input-group">
                              <div className="input-group-prepend">
                                  <span className="input-group-text">RM</span>
                              </div>
                              <input type="number" className="form-control" name="amount" onChange={handleChange} value={input.amount} />
                          </div>
                        </div>
                      {/* )} */}
                      <button className="btn btn-primary w-100 mt-2" disabled={!input.name || !input.ic || !input.amount} onClick={handleNext}>Next</button>
                    {/* </div> */}
                  {/* </div> */}
              </div>
            </div>
          )}
          </div>
      </div>
    </div>

    </div>
  );
};

export default reduxForm({
  form: "educationForm",
  // validate
})(EducationForm);
