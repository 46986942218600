export const NAME = "PAYMENT";

export const ADD_CARD_PAYMENT_METHOD = `${NAME}/ADD_CARD_PAYMENT_METHOD`;
export const ADD_CARD_PAYMENT_METHOD_SUCCESS = `${NAME}/ADD_CARD_PAYMENT__METHOD_SUCCESS`;
export const ADD_CARD_PAYMENT_METHOD_FAIL = `${NAME}/ADD_CARD_PAYMENT_METHOD_FAIL`;
export const RESET_ADD_CARD_PAYMENT_METHOD = `${NAME}/RESET_ADD_CARD_PAYMENT_METHOD`;

export const getAddCardPaymentMethodData = store =>
  store[NAME].addCardPaymentMethod;

export const addCardPaymentMethod = data => ({
  type: ADD_CARD_PAYMENT_METHOD,
  data
});

export const addCardPaymentMethodSuccess = data => ({
  type: ADD_CARD_PAYMENT_METHOD_SUCCESS,
  data
});

export const addCardPaymentMethodFail = error => ({
  type: ADD_CARD_PAYMENT_METHOD_FAIL,
  error
});

export const resetAddCardPaymentMethod = () => ({
  type: RESET_ADD_CARD_PAYMENT_METHOD
});
