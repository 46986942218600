import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* logout() {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.logout, headers);
    if (response) {
        yield put(Actions.logoutSuccess(response.data.data));
        yield put(Actions.resetUserSession());
        yield put(Actions.clearUserProfile());
    } else {
        yield put(Actions.logoutFail(error.response.data));
    }
}

function* logoutWithoutAPI() {
    yield put(Actions.logoutSuccess({}));
    yield put(Actions.resetUserSession());
    yield put(Actions.clearUserProfile());
    yield put(Actions.resetGetPaymentMethods())
    yield put(Actions.clearSavedPaymentMethods());
}

function* watchLogout() {
    yield takeLatest(Actions.LOGOUT, logoutWithoutAPI);
}

export default function* submit() {
    yield all([
        fork(watchLogout)
    ])
}