export const NAME = "FEEDERS";

export const GET_PAIP_LIST = `${NAME}/GET_PAIP_LIST`;
export const GET_PAIP_LIST_SUCCESS = `${NAME}/GET_PAIP_LIST_SUCCESS`;
export const GET_PAIP_LIST_FAIL = `${NAME}/GET_PAIP_LIST_FAIL`;
export const RESET_GET_PAIP_LIST = `${NAME}/RESET_GET_PAIP_LIST`;

export const getPaipListData = store => store[NAME].getPaipList;

export const getPaipList = data => ({
  type: GET_PAIP_LIST,
  data
});

export const getPaipListSuccess = data => ({
  type: GET_PAIP_LIST_SUCCESS,
  data
});

export const getPaipListFail = error => ({
  type: GET_PAIP_LIST_FAIL,
  error
});

export const resetGetPaipList = () => ({
  type: RESET_GET_PAIP_LIST
});
