export const NAME = 'PREPAID'

export const GET_PREPAID_LIST = `${NAME}/GET_PREPAID_LIST`;

export const GET_PREPAID_LIST_SUCCESS = `${NAME}/GET_PREPAID_LIST_SUCCESS`;

export const GET_PREPAID_LIST_FAILED = `${NAME}/GET_PREPAID_LIST_FAILED`;

export const SELECT_PREPAID = `${NAME}/SELECT_PREPAID`;

export const getPrepaidList = () => ({
    type: GET_PREPAID_LIST
});

export const getPrepaidListSuccess = (data) => ({
    type: GET_PREPAID_LIST_SUCCESS,
    data
});

export const getPrepaidListFailed = () => ({
    type: GET_PREPAID_LIST_FAILED
});

export const selectPrepaid = (data) => ({
    type: SELECT_PREPAID,
    data
});

