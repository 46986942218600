import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: {} });

function getAssessmentTax(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.GET_ASSESSMENT_TAX:
      return { isLoading: true, error: null, data: {} };
    case Actions.GET_ASSESSMENT_TAX_SUCCESS:
      return { isLoading: false, error: null, data: action.data };
    case Actions.GET_ASSESSMENT_TAX_FAIL:
      return { isLoading: false, error: action.error, data: {} };
    case Actions.RESET_GET_ASSESSMENT_TAX:
      return getDefaultState();
    default:
      return state;
  }
}

export default getAssessmentTax;