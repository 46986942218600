import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay-holder">
        <div className="loading-inner">
            {/* <div className="lds-ring"><div></div><div></div><div></div><div></div></div> */}
            <div className="loading w-64"></div>
        </div>
    </div>
  );
};

export default LoadingOverlay;