export const NAME = "EXPORT_HISTORY";

export const EXPORT_HISTORY = `${NAME}/EXPORT_HISTORY`;
export const EXPORT_HISTORY_SUCCESS = `${NAME}/EXPORT_HISTORY_SUCCESS`;
export const EXPORT_HISTORY_FAILED = `${NAME}/EXPORT_HISTORY_FAILED`;
export const RESET_EXPORT_HISTORY = `${NAME}/RESET_EXPORT_HISTORY`;

export const getHistoryData = store => store[NAME].history;

export const exportHistory = data => ({
  type: EXPORT_HISTORY,
  data
});

export const exportHistorySuccess = data => ({
  type: EXPORT_HISTORY_SUCCESS,
  data
});

export const exportHistoryFailed = error => ({
  type: EXPORT_HISTORY_FAILED,
  error
});

export const resetExportHistory = () => ({
  type: RESET_EXPORT_HISTORY
});
