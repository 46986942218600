import React from 'react';
import renderHTML from "react-render-html"

const MessageModal = props => {
    const [type, setType] = React.useState("password");

    const renderMsg = () => {
      switch (props.step) {
        case 0:
          return {msg: "Please enter your old 4-digit PIN", input: ["old1", "old2", "old3", "old4"]}
        case 1:
          return {msg: "Please enter your new 4-digit PIN", input: ["pin1", "pin2", "pin3", "pin4"]}
        default:
          return {msg: "Please re-enter your new 4-digit PIN", input: ["reenter1", "reenter2", "reenter3", "reenter4"]}
      }
    }

    return (
        <>
          <div className={`modal fade ${props.show ? 'show' : ''}`} style={props.show ? {display: 'block'} : {}}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{props.title}</h5>
                </div>
                <div className="modal-body m-lg">
                    {props.message || props.isMessage ? <p className="mb-0">{renderHTML(props.message)}</p> : (
                      <div className="form-group mb-0">
                        <p className="text-center">{renderMsg().msg}</p>
                        <div className="input-group px-2">
                          <input type={type} name={renderMsg().input[0]} onChange={props.handleChange} value={props.input[renderMsg().input[0]]} className="form-control" />
                          <input type={type} name={renderMsg().input[1]} onChange={props.handleChange} value={props.input[renderMsg().input[1]]} className="form-control" />
                          <input type={type} name={renderMsg().input[2]} onChange={props.handleChange} value={props.input[renderMsg().input[2]]} className="form-control" />
                          <input type={type} name={renderMsg().input[3]} onChange={props.handleChange} value={props.input[renderMsg().input[3]]} className="form-control" />
                          <button className="btn btn-outline-secondary ml-2" onClick={() => setType(state => state === "password" ? "number" : "password")}>Show</button>
                        </div>
                      </div>
                    )}
                </div>
                <div className="modal-footer">
                  {props.handleCancel && <button className="btn btn-white" onClick={props.handleCancel}>Cancel</button>}
                  <button className="btn btn-primary" disabled={props.pin && (!props.input[renderMsg().input[0]] || !props.input[renderMsg().input[1]] || !props.input[renderMsg().input[2]] || !props.input[renderMsg().input[3]])} onClick={props.handleClose}>{props.button}</button>
                </div>
              </div>
            </div>
          </div>
          {props.show && <div className={`modal-backdrop ${props.show ? 'show' : ''}`}></div>}
        </>
      )
};

export default MessageModal;