import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* submitEducation({ data, callback }) {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    const { response, error } = yield call(api.submitEducation, data, headers);

    if (response) {
        //yield put(Actions.submitEducationSuccess());
        callback && callback(response.data.data);
    } else {
       console.log('error', error);
    }
}

function* watchEducation() {
    yield takeLatest(Actions.SUBMIT_EDUCATION, submitEducation);
}

export default function* education() {
    yield all([
        fork(watchEducation)
    ])
}