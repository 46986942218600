export const NAME = "PAYMENT";

export const GET_PAYMENT_METHODS = `${NAME}/GET_PAYMENT_METHODS`;
export const GET_PAYMENT_METHODS_SUCCESS = `${NAME}/GET_PAYMENT_METHODS_SUCCESS`;
export const GET_PAYMENT_METHODS_FAIL = `${NAME}/GET_PAYMENT_METHODS_FAIL`;
export const RESET_GET_PAYMENT_METHODS = `${NAME}/RESET_GET_PAYMENT_METHODS`;

export const getPaymentMethodsData = store => store[NAME].getPaymentMethods;

export const getPaymentMethods = () => ({
  type: GET_PAYMENT_METHODS
});

export const getPaymentMethodsSuccess = data => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  data
});

export const getPaymentMethodsFail = error => ({
  type: GET_PAYMENT_METHODS_FAIL,
  error
});

export const resetGetPaymentMethods = () => ({
  type: RESET_GET_PAYMENT_METHODS
});