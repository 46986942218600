export const NAME = "EDUCATION";

export const SUBMIT_EDUCATION = `${NAME}/EDUCATION`;
export const SUBMIT_EDUCATION_SUCCESS = `${NAME}/EDUCATION_SUCCESS`;
export const SUBMIT_EDUCATION_FAILED = `${NAME}/EDUCATION_FAILED`;


export const submitEducation = (data, callback) => ({
  type: SUBMIT_EDUCATION,
  data,
  callback
});

export const submitEducationSuccess = data => ({
  type: SUBMIT_EDUCATION_SUCCESS,
  data
});

export const submitEducationFailed = error => ({
  type: SUBMIT_EDUCATION_FAILED,
  error
});
