import Actions from '../../actions';

const getDefaultState = () => ({
    data: [
        { type: "credit_card", methods: [] },
        { type: "online_banking", methods: [] },
        { type: "ewallet", methods: [] },
    ]
});

function paymentMethods(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }

    switch (action.type) {
        case Actions.UPDATE_SAVED_PAYMENT_METHODS:
            return { data: action.data };
        case Actions.CLEAR_SAVED_PAYMENT_METHODS:
            return getDefaultState();
        default:
            return state;
    }
}

export default paymentMethods;