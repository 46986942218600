import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import Actions from "../../actions";

const EWallet = ({addPaymentMethod}) => {
    const {isLoading, error, data} = useSelector(state => state.PAYMENT.paymentMethodOptions);
    const dispatch = useDispatch();
    // const [methods, setMethods] = React.useState([])

    React.useEffect(() => {
        dispatch(Actions.getPaymentMethodOptions({paramString: 'ewallet'}))
    }, [])

    // return (
    //     <>
    //         <p className="payment-title">Select One</p>

    //         {isLoading && <div className="loading_holder">
    //             <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    //         </div>}

    //         <div className="eWallet-wrap">
    //             {data.map(d => (
    //                 <div className="eWallet" key={d.card_name} onClick={() => addPaymentMethod(d)}>
    //                     <img src={d.logo} alt=""/>
    //                     <p>{d.bank_name}</p>
    //                 </div>
    //             ))}
    //             {/* <div className="eWallet">
    //                 <img src={require(`../../assets/images/touchgo-icon.png`)} alt=""/>
    //                 <p>Touch n' Go</p>
    //             </div>
    //             <div className="eWallet">
    //                 <img src={require(`../../assets/images/grabpay-icon.png`)} alt=""/>
    //                 <p>GrabPay</p>
    //             </div> */}
    //         </div>
    //     </>
    // );

    return (
        <div className="card border no-shadow p-4">
            <p className="text-muted pb-0">Select One</p>
            <div className="list list-row">
                {data.map(d => (
                    <div key={d.card_name} className="list-item pointer" onClick={() => addPaymentMethod(d)}>
                        <img src={d.logo} style={{width: "80px"}} alt=""/>
                        {d.bank_name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EWallet;