import { all, fork } from 'redux-saga/effects';
import postpaid from './postpaid';
import bill from './bill';
import product from './product';
import utilities from './utilities';
import prepaid from './prepaid';

export default function* home() {
    yield all([
        fork(postpaid),
        fork(bill),
        fork(product),
        fork(utilities),
        fork(prepaid)
    ]);
}