import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment"

const OtherCompoundPayment = ({location}) => {
    
    const formatCurrency = d => {
        let t = d / 100
        return `${t.toFixed(2)}`
    }
    
    return (
        <div className="row">
            <div className="col-sm-4 col-md-8">
                <div className="form-group">
                    <label htmlFor="">Company Name</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.offender_name} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Date Issued</label>
                    <input type="text" className="form-control" name="name" disabled value={moment(location.state.violation_timestamp).format('DD/MM/YYYY, HH:mmA')} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Company Number</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.company_number} />
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Compound Number</label>
                            <input type="text" className="form-control" name="name" disabled value={location.state.service_reference_1} />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">Violation Code</label>
                            <input type="text" className="form-control" name="name" disabled value={location.state.offender_id} />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="">Section</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.violation_type} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Location</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.violation_location} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Amount</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">RM</span>
                        </div>
                        <input type="number" className="form-control" name="name" disabled value={formatCurrency(location.state.compound_amount)} />
                    </div>
                </div>
                <Link
                    to={{
                        pathname: "/other_compound/pay",
                        state: {
                            paymentData: [
                                {
                                    title: "Name",
                                    value: location.state.offender_name
                                },
                                {
                                    title: "Compound Number",
                                    value: location.state.service_reference_1
                                },
                                {
                                    title: "Company Number",
                                    value: location.state.company_number
                                }
                            ],
                            data: {
                                service_reference_1: location.state.service_reference_1,
                                service_reference_2: location.state.service_reference_2,
                                compound_amount: location.state.compound_amount,
                                pbtid: location.state.pbtid
                            },
                            amount: (location.state.compound_amount/100).toFixed(2)
                        }
                    }}
                >
                    <button className="btn btn-primary w-100 mt-4">Next</button>
                </Link>
            </div>
        </div>
    )

    // return (
    //     <div className="other-compound-payment-form">
    //         <div className="form-wrapper">
    //             <div className="input-holder">
    //                 <label htmlFor="name">Date Issued</label>
    //                 <input type="text" placeholder="Date Issued" name="dateIssued" value={""} />
    //             </div>

    //             <div className="side-by-side">
    //                 <div className="input-holder">
    //                     <label htmlFor="name">Compound Number</label>
    //                     <input type="text" placeholder="Compound Number" name="compoundNumber" value={""} />
    //                 </div>
    //                 <div className="input-holder">
    //                     <label htmlFor="name">Kod Kesalahan</label>
    //                     <input type="text" placeholder="Kod Kesalahan" name="plateNumber" value={""} />
    //                 </div>
    //             </div>

    //             <div className="input-holder">
    //                 <label htmlFor="name">Section</label>
    //                 <input type="text" placeholder="section" name="section" value={""} />
    //             </div>

    //             <div className="input-holder">
    //                 <label htmlFor="name">Location</label>
    //                 <input type="text" placeholder="location" name="location" value={""} />
    //             </div>

    //             <div className="input-holder data-display">
    //                 <label htmlFor="name">Pay Amount</label>
    //                 <p className="top-right">in 14 days</p>
    //                 <input type="text" placeholder="" name="paymentAmount" value={"RM 117.30"} disabled/>
    //             </div>
 
    //             <div className="input-holder">
    //                 <Link 
    //                     to={{
    //                         pathname : "/pay/kuantan/other_compound/payment",
    //                         state : {
    //                             paymentData : [
    //                                 {
    //                                     title : "Compound Number",
    //                                     value : "L6024116789"
    //                                 },
    //                                 {
    //                                     title : "Company Number",
    //                                     value : "A123456-K"
    //                                 },
    //                             ],
    //                             totalAmount: "RM250.00"
    //                         }                            
    //                     }}
    //                 >
    //                     <input type="submit" value="Next"/>
    //                 </Link>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default OtherCompoundPayment;