import React from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux"

import Actions from "../../actions"

import LoadingOverlay from "../../components/LoadingOverlay"

import FavouriteModal from "../../components/FavouriteModal"
import MessageModal from "../../components/MessageModal"

const ParkingCompoundDropdown = props => {
    const [favouriteActive, setFavouriteActive] = React.useState(null);
    const [favouriteModal, setFavouriteModal] = React.useState(false)
    const {fav_accounts} = useSelector(state => state.FAVOURITE.list)
    const {isLoading, error, data} = useSelector(state => state.FEEDERS.getCompoundList)
    const [messageModal, setMessageModal] = React.useState(false)
    const [input, setInput] = React.useState({
        name: "",
        ic: "",
        compound: "",
        plate: "",
        amount: ""
    })
    const [inputError, setInputError] = React.useState({
        plate: ""
    })
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!isLoading && data && !error) {
            if (Object.keys(data).length > 0) {
                // props.history.push({pathname: `/pay/${props.pbtid}/land_tax/form`, state: data})
                var newData = data.filter((el) => { return el.status !== "paid" })
                if (newData.length > 0) {
                    newData.pbtid = props.pbtid
                    props.history.push({pathname: '/pay/kuantan/parking_compound/table', state: data})
                } else {
                    setMessageModal(true)
                }
                // console.log(newData)
            }
        } else if (!isLoading) {
            console.log("here no data")
            setMessageModal(true)
        }
    }, [isLoading])

    React.useEffect(() => {
        dispatch(Actions.fetchListFavourites(props.pbtid, "vehicle"))
    }, [])

    const handleSearch = () => {
        if (!props.manual) {
            if (!input.plate) {
                setInputError(state => ({...state, plate: "This value is required."}))
            } else {
                let paramString = `${props.pbtid}/compound/vehicle?registration_number=${input.plate}`
                dispatch(Actions.getCompoundList({paramString}))
            }
            return
        }

        props.history.push({pathname: '/parking_compound/pay', state: {
            paymentData: [
                {
                    title: "Name",
                    value: input.name
                },
                {
                    title: "MyKad Number",
                    value: input.ic
                },
                {
                    title: "Compound Number",
                    value: input.compound
                },
                {
                    title: "Plate Number",
                    value: input.plate
                },
            ],
            name: input.name,
            ic: input.ic,
            compound: input.compound,
            plate: input.plate,
            amount: input.amount,
            manual: props.manual,
            id: props.pbtid
        }})
    }

    const handleChange = e => {
        const {name, value} = e.target
        setInput(state => ({...state, [name]: value}))
    }

    return (
        <div className="row">
            <MessageModal show={messageModal} message="You have no compound tickets!" button="Okay" title="Congratulations!" handleClose={() => setMessageModal(false)} />
            {isLoading && <LoadingOverlay />}
            <FavouriteModal slug={props.pbtid} category='vehicle' favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} label="Vehicle plate number" />
            <div className="col-sm-4 col-md-8">
                {/* <div className="card border shadow-md">
                    <div className="p-4"> */}
                    {props.manual && (
                        <>
                            <div className="form-group">
                                <label htmlFor="">Name</label>
                                <input type="text" className="form-control" name="name" onChange={handleChange} value={input.name} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">MyKad Number</label>
                                <input type="number" className="form-control" name="ic" onChange={handleChange} value={input.ic} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Compound Number</label>
                                <input type="text" className="form-control" name="compound" onChange={handleChange} value={input.compound} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Vehicle Plate Number</label>
                                <input type="text" className="form-control" name="plate" onChange={handleChange} value={input.plate} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Amount</label>
                                <input type="number" className="form-control" name="amount" onChange={handleChange} value={input.amount} />
                            </div>
                        </>
                    )}
                    {!props.manual && (
                        <>
                            <div className="form-group">
                                <label htmlFor="">Vehicle Plate Number</label>
                                <input type="text" className={`form-control ${inputError.plate ? 'is-invalid' : ''}`} name="plate" disabled={favouriteActive !== null} onChange={handleChange} value={input.plate}/>
                                {inputError.plate && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
                                <li class="parsley-required">{inputError.plate}</li>
                                </ul>}
                            </div>
                            <div className="row m-0 justify-content-between">
                                <p className="text-muted">My Favourite</p>
                                <div className="i-con-h-a" onClick={() => setFavouriteModal(true)}>
                                <i className="i-con i-con-plus"><i></i></i>
                                </div>
                            </div>
                            <div className="row row-sm">
                                <div className="col">
                                {fav_accounts.hasOwnProperty(`${props.pbtid}_vehicle`) && fav_accounts[`${props.pbtid}_vehicle`].map((fav, i) => (
                                    <div className={`card border no-shadow i-con-h-a pointer ${favouriteActive === i ? 'border-primary' : ''}`} onClick={() => {
                                        setFavouriteActive(favouriteActive === null ? i : favouriteActive === i ? null : i)
                                        setInput(state => favouriteActive === null ? ({...state, plate: fav.phoneNumber}) : favouriteActive === i ? ({...state, plate: ""}) : ({...state, plate: fav.phoneNumber}))
                                    }}>
                                    <div className="p-2">
                                        <div className="row row-sm">
                                        <div className="col-auto">
                                        <i className="i-con i-con-ok"><i></i></i>
                                        </div>
                                        <div className="col">
                                            {fav.name} - {fav.phoneNumber}
                                        </div>
                                        <div className="col-auto i-con-h-a">
                                            <i className={`i-con i-con-trash`} onClick={(e) => {
                                            e.stopPropagation()
                                            dispatch(Actions.deleteFavourite(props.pbtid, fav.name, "vehicle"))
                                            }}><i></i></i>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </>
                    )}
                    <button className="btn btn-primary w-100 mt-2" disabled={!props.manual ? !input.plate : !input.plate || !input.ic || !input.name || !input.amount || !input.compound} onClick={handleSearch}>NEXT</button>
                    {/* </div>
                </div> */}
            </div>
        </div>
    );

    // return (
    //     <div className="content-form land-tax">
    //         {isLoading && <LoadingOverlay />}
    //         <div className="form-wrapper">
    //             {props.manual && (
    //                 <>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Name</label>
    //                         <input type="text" placeholder="Name" name="name" onChange={handleChange} value={input.name} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">MyKad Number</label>
    //                         <input type="text" placeholder="Name" name="ic" onChange={handleChange} value={input.ic} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Compound Number</label>
    //                         <input type="text" placeholder="Name" name="compound" onChange={handleChange} value={input.compound} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Plate Number</label>
    //                         <input type="text" placeholder="Name" name="plate" onChange={handleChange} value={input.plate} />
    //                     </div>
    //                     <div className="input-holder">
    //                         <label htmlFor="">Amount</label>
    //                         <div className="amount-input">
    //                             <h4>RM</h4>
    //                             {/* <Field type="text" placeholder="0" name="amount" /> */}
    //                             <input type="text" name="amount" placeholder="0" onChange={handleChange} value={input.amount} />
    //                         </div>
    //                     </div>
    //                 </>
    //             )}

    //            {!props.manual && (
    //                <>
    //                     <div className="input-holder">
    //                         <label htmlFor="name">Vehicle Plate Number</label>
    //                         <input type="text" placeholder="Enter vehicle plate number" name="name" onChange={e => setPlateNumber(e.target.value)} value={plateNumber} />
    //                     </div>

    //                     <div className="favourite-holder">
    //                         <h4>My Favourite</h4>
    //                         <div className="holder" onClick={() => setFavouriteActive(favouriteActive === null ? 0 : null)}>
    //                             <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt="" />
    //                             <p>My favourite - CDC9268</p>
    //                         </div>
    //                         <div className="holder" onClick={() => setFavouriteActive(favouriteActive === null ? 1 : null)}>
    //                             <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt="" />
    //                             <p>Mom - ABS1234</p>
    //                         </div>
    //                     </div>
    //                 </>
    //             )}

    //             {/* <Link to="/pay/kuantan/parking_compound/table"> */}
    //             <button onClick={handleSearch}>{props.manual ? 'Next' : 'Search'}</button>
    //             {/* </Link> */}
    //         </div>
    //     </div>
    // );
};

export default ParkingCompoundDropdown;