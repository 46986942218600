import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";

const getSessionData = store => store.PROFILE.userSession;

function* getUserProfile() {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  var headers = {
    common: {
      'Authorization': `Bearer ${token}`,
    },
    'Content-Type': 'application/json',
    // 'Accept': 'application/json',
    // 'Access-Control-Allow-Origin': '*'
    // "Access-Control-Allow-Headers": Authorization
  }
  console.log("token", token);

  const { response, error } = yield call(api.getUserProfile, headers);
  console.log(response)
  if (response) {
    yield put(Actions.getUserProfileSuccess(response.data.data));
  } else {
    yield put(Actions.getUserProfileFail(error.response.data));
  }
}

function* updateUserProfile(data) {
  // console.log("SAGA PROFILE", data);
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}` };

  const { response, error } = yield call(
    api.updateUserProfile,
    data.data,
    headers
  );
  if (response) {
    if (response.data.success) {
      yield put(Actions.updateUserProfileSuccess(response.data.data));
    }
  } else {
    if (error.response.status === 413) {
      yield put(
        Actions.updateUserProfileFail({
          status: "Image size is too large"
        })
      );
      return;
    }
    yield put(Actions.updateUserProfileFail(error.response.data));
  }
}

function* updateUserEmail(data) {
  console.log("SAGA EMAIL: ", data);
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json"
  };

  const { response, error } = yield call(
    api.updateUserEmail,
    data.data,
    headers
  );

  console.log("saga update email:", response);
  if (response) {
    if (response.data.success) {
      yield put(Actions.updateUserEmailSuccess(response.data.data));
    }
  } else {
    yield put(Actions.updateUserEmailFail(error.response.data));
  }
}

function* watchUserProfile() {
  yield takeLatest(Actions.GET_USER_PROFILE, getUserProfile);
  yield takeLatest(Actions.UPDATE_USER_PROFILE, updateUserProfile);
  yield takeLatest(Actions.UPDATE_USER_EMAIL, updateUserEmail);
}

export default function* submit() {
  yield all([fork(watchUserProfile)]);
}
