import Actions from '../../actions';

const getDefaultState = () => ({ 
    isLoading: false,
    address1: '',
    address2: '',
    postcode: '',
    state: '',
    city: '',
    country: '',
});

function address(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.ADDRESS:
            return {
                ...state,
                address1: action.data.unit,
                address2: action.data.street,
                postcode: action.data.postcode,
                city: action.data.city,
                state: action.data.state,
                country: action.data.country
            };         
        default:
            return state;
    }
}

export default address;