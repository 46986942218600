import React from "react";
import {useSelector, useDispatch} from "react-redux"
import { reduxForm, Field, SubmissionError } from "redux-form";

import TextInput from "../../components/TextInput.js";

import Actions from "../../actions";
import * as api from "../../api"

import LoadingOverlay from "../../components/LoadingOverlay"
import FavouriteModal from "../../components/FavouriteModal"

const validate = values => {
  const errors = {}
  if (!values.phoneno) {
    errors.phoneno = "Please enter something"
  } else if (isNaN(Number(values.phoneno))) {
    errors.phoneno = "Phone number can only be number"
  }
  return errors
}

const Postpaid = props => {
  const [cardActive, setCardActive] = React.useState(null);
  const [favouriteActive, setFavouriteActive] = React.useState(null);
  const [favouriteModal, setFavouriteModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [number, setNumber] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [invalidError, setInvalidError] = React.useState(null)
  const {fav_accounts} = useSelector(state => state.FAVOURITE.list)
  const {data: {auth_token}} = useSelector(state => state.PROFILE.userSession)
  const [slug, setSlug] = React.useState(null)
  const dispatch = useDispatch()
  const test = useSelector(state => state.IIMMPACT)
  const {list} = useSelector(state => state.IIMMPACT.postpaid)
  const [error, setError] = React.useState("")

  React.useEffect(() => {
    dispatch(Actions.getPostpaidList())
  }, [])

  React.useEffect(() => {
    console.log(props)
  },[])
 
  React.useEffect(() => {
    console.log(list)
  }, [list])

  React.useEffect(() => {
    console.log(test)
  }, [test])

  const handleNext = () => {
    const errors = {}
    const payload = {
      account_no: number,
      product_code: list[cardActive].merchant_code
    }
    if (list[cardActive].have_bills) {
      setLoading(true)
      dispatch(Actions.getBill(payload, () => {
        setLoading(false)
        props.history.push({pathname: 'postpaid/payment_form', state: {phone: number, merchant_code: list[cardActive].merchant_code, paip: false, card: list[cardActive].name}})
      }, (error) => {
        setLoading(false)
        setError(`This number is not valid for ${list[cardActive].name}`)
      }))
    } else {
      setLoading(true)
      dispatch(Actions.validateProduct(payload, () => {
        setLoading(false)
        props.history.push({
          pathname: '/postpaid/pay',
          state: {card: list[cardActive].name, amount: amount, merchant_code: list[cardActive].merchant_code, number: number}
        })
      }, (error) => {
        setLoading(false)
        setError(`This number is not valid for ${list[cardActive].name}`)
      }))
    }
    return errors
    // console.log(test)
    // const cardArray = ['YES', 'DiGi', 'Maxis', 'XPAX', 'Umobile', 'Tune Talk', 'XOX', 'ALTEL'];
    // const favouriteArray = ['0124444543', '0124423322']

    // props.history.push({
    //   pathname: '/postpaid/pay',
    //   state: {card: cardArray[cardActive], amount, number: favouriteActive !== null ? favouriteArray[favouriteActive] : number}
    // })
  }

  // return (
  //   <div className="content home">
  //     {loading && <LoadingOverlay />}
  //     <h1 className="content-title">Postpaid</h1>

  //     <div className="content-card">
  //       <h4>Select Telco</h4>

  //       {list.length === 0 && (
  //         <div className="loading_holder">
  //           <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  //         </div>
  //       )}

  //     {list.length > 0 && (
  //       <div className="card-holder">
  //         {list.map((l, i) => (
  //             <div
  //             key={l.id}
  //             className={cardActive === i ? "active" : ""}
  //             onClick={async () => {
  //               setCardActive(cardActive !== null ? cardActive === i ? null : i : i)
  //               var headers = { Authorization: `Bearer ${auth_token}` }
  //               const {response: {data: {data}}} = await api.getMerchantsById(headers, l.merchant_id)
  //               setSlug(data.slug)
  //               dispatch(Actions.fetchListFavourites(data.slug, null))
  //             }}
  //             >
  //               <img
  //                 src={l.picture}
  //                 style={{ marginTop: "10px" }}
  //                 alt=""
  //               />
  //               <p>{l.name.split(" ")[0]}</p>
  //             </div>
  //           ))}
  //       </div>
  //     )}
  //     </div>

  //     {cardActive !== null && (
  //       <div className="content-form">
  //         <form onSubmit={props.handleSubmit(handleNext)}>
  //           <div className="form-wrapper">
  //             <Field 
  //             component={TextInput}
  //             type="text"
  //             label="Phone Number"
  //             name="phoneno"
  //             placeholder="Enter phone number"
  //             // meta={invalidError !== null ? {error: invalidError} : ""}
  //             />
  //             {/* <div className={`input-holder ${favouriteActive !== null ? 'hidden' : ''}`}>
  //               <label htmlFor="">Phone Number</label>
  //               <input type="text" placeholder="Enter phone number" disabled={favouriteActive !== null} onChange={e => setNumber(e.target.value)} />
  //             </div> */}
  //             {list[cardActive].have_bills === 0 && (
  //               <div className="input-holder amount">
  //                 <label htmlFor="">Amount</label>
  //                 <p>Minimum amount RM{list[cardActive].denomination.split('-')[0]}</p>
  //                 <div className="amount-input">
  //                   <h4>RM</h4>
  //                   {/* <Field type="text" placeholder="0" name="amount" /> */}
  //                   <input type="text" placeholder="0" onChange={e => setAmount(e.target.value)}/>
  //                 </div>
  //               </div>
  //             )}
  //             <div className={`favourite-holder ${number ? 'hidden' : ''}`}>
  //               <h4>My Favourite</h4>
  //               {fav_accounts.hasOwnProperty(slug) && fav_accounts[slug].map(fav => (
  //                 <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}>
  //                   <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/>
  //                   <p>{fav.name} - {fav.phoneNumber}</p>
  //                 </div>
  //               ))}
  //               {/* <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 0 : favouriteActive === 0 ? null : 0)}> */}
  //                 {/* <img src={require(`../../assets/images/${favouriteActive === 0 ? 'mark-active' : 'mark'}.png`)} alt=""/> */}
  //                 {/* <p>My favourite phone - 0124444543</p> */}
  //               {/* </div> */}
  //               {/* <div className="holder" onClick={() => !number && setFavouriteActive(favouriteActive === null ? 1 : favouriteActive === 1 ? null : 1)}> */}
  //                 {/* <img src={require(`../../assets/images/${favouriteActive === 1 ? 'mark-active' : 'mark'}.png`)} alt=""/> */}
  //                 {/* <p>Mom - 0124423322</p> */}
  //               {/* </div> */}
  //             </div>
  //             <button>NEXT</button>
  //           </div>
  //         </form>
  //       </div>
  //     )}
  //     </div>
  // );

  return (
    <div id="content" className="flex">
      {loading && <LoadingOverlay />}
      <FavouriteModal slug={slug} favouriteModal={favouriteModal} handleClose={() => setFavouriteModal(false)} label="Phone number" />
      <div className="h-100 d-flex padding" id="page-container">
        <div className="block flex mb-0">
        <div className="page-title padding pb-0 ">
            <h2 className="text-md mb-0">Postpaid</h2>
        </div>
        <div className="padding">
          <div className="row">
            <div className="col-sm-4 col-md-8">
              {/* <div className="card shadow-md border"> */}
                <div className="text-muted">Select telco</div>
                <div className="pt-4">
                    {list.length === 0 && (
                        <div className="text-center d-flex py-4 justify-content-center">
                            <div className="loading w-48"></div>
                        </div>
                    )}
                  <div className="row">
                    {list.map((l, i) => (
                      <div className="col-3" key={l.id}>
                        <div className={`card pointer border no-shadow ${cardActive === i ? "border-primary" : ""} interactive`} onClick={async () => {
                        setCardActive(null)
                        var headers = { Authorization: `Bearer ${auth_token}` }
                        const {response: {data: {data}}} = await api.getMerchantsById(headers, l.merchant_id)
                        setCardActive(cardActive !== null ? cardActive === i ? null : i : i)
                        setSlug(data.slug)
                        setError("")
                        setAmount("")
                        dispatch(Actions.fetchListFavourites(data.slug, null))
                      }
                      }>
                          <div className="p-3 text-center">
                            <img height={50} src={l.picture} alt=""/>
                            <p className="text-center m-2">{l.name.split(" ")[0]}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          {cardActive !== null && (
            <div className="row">
              <div className="col-sm-4 col-md-8">
                  {/* <div className="card shadow-md border"> */}
                    {/* <div className="p-4"> */}
                      {/* <div className="form-group">
                        <label htmlFor="">Phone Number</label>
                        <input type="number" className="form-control"/>
                      </div> */}
                      <div className="form-group">
                        <label htmlFor="">Phone Number</label>
                        <input type="number" placeholder="0123456789" className={`form-control ${error ? 'is-invalid' : ''}`} disabled={favouriteActive !== null} value={favouriteActive === null ? number : ''} onChange={e => setNumber(e.target.value)} />
                        {error && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
                        <li class="parsley-required">{error}</li>
                        </ul>}
                      </div>
                      {list[cardActive].have_bills === 0 && (
                        <div className="form-group">
                          <label htmlFor="">Amount</label>
                          <div className="input-group">
                              <div className="input-group-prepend">
                                  <span className="input-group-text">RM</span>
                              </div>
                              <input type="number" className="form-control" name="name" onChange={e => setAmount(e.target.value)} />
                          </div>
                        </div>
                      )}
                      <div className="row m-0 justify-content-between">
                        <p className="text-muted">My Favourite</p>
                        <div className="i-con-h-a" onClick={() => setFavouriteModal(true)}>
                          <i className="i-con i-con-plus"><i></i></i>
                        </div>
                      </div>
                      <div className="row row-sm">
                        <div className="col">
                          {fav_accounts.hasOwnProperty(slug) && fav_accounts[slug].map((fav, i) => (
                            <div className={`card border no-shadow i-con-h-a pointer ${favouriteActive === i ? 'border-primary' : ''}`} onClick={() => {
                                setFavouriteActive(favouriteActive === null ? i : favouriteActive === i ? null : i)
                                setNumber(favouriteActive === null ? fav.phoneNumber : favouriteActive === i ? null : fav.phoneNumber)
                            }}>
                              <div className="p-2">
                                <div className="row row-sm">
                                  <div className="col-auto">
                                  <i className="i-con i-con-ok"><i></i></i>
                                  </div>
                                  <div className="col">
                                    {fav.name} - {fav.phoneNumber}
                                  </div>
                                  <div className="col-auto i-con-h-a">
                                    <i className={`i-con i-con-trash`} onClick={(e) => {
                                      e.stopPropagation()
                                      dispatch(Actions.deleteFavourite(slug, fav.name, null))
                                    }}><i></i></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <button className="btn btn-primary w-100 mt-2" disabled={list[cardActive].have_bills === 0 ? !number || !amount : !number} onClick={handleNext}>Next</button>
                    </div>
                  {/* </div> */}
              {/* </div> */}
            </div>
          )}
          </div>
      </div>
    </div>

    </div>
  );
};

export default reduxForm({
  form: "postpaidForm",
  validate
})(Postpaid);

// export default Postpaid;
