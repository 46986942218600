import { createStore, compose, applyMiddleware } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";

import reducers from "../reducers";
import sagas from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const config = {
  key: "root",
  storage,
  whitelist: ["SESSION", "PROFILE"]
};

const combinedReducers = persistCombineReducers(config, reducers);

const configureStore = () => {
  const store = createStore(
    combinedReducers,
    compose(
      applyMiddleware(sagaMiddleware),
    //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
  sagaMiddleware.run(sagas);
  const persistor = persistStore(store);

  return { persistor, store };
};

export default configureStore;
