import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";

const getSessionData = store => store.PROFILE.userSession;

function* getPaipList({ data }) {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}` };
  console.log("PAIP SAGA", data);
  const { response, error } = yield call(api.getPaipList, headers, data);
  console.log("PAIP SAGA", response);
  if (response) {
    yield put(Actions.getPaipListSuccess(response.data.data));
  } else {
    yield put(Actions.getPaipListFail(error.response.data));
  }
}

function* watchGetPaipList() {
  yield takeLatest(Actions.GET_PAIP_LIST, getPaipList);
}

export default function* submit() {
  yield all([fork(watchGetPaipList)]);
}
