import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* deletePaymentMethod({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.deletePaymentMethod, headers, data.paramString);
    if (response) {
        yield put(Actions.deletePaymentMethodSuccess(response.data));
    } else {
        yield put(Actions.deletePaymentMethodFail(error.response.data));
    }
}

function* watchDeletePaymentMethod() {
    yield takeLatest(Actions.DELETE_PAYMENT_METHOD, deletePaymentMethod);
}

export default function* submit() {
    yield all([
        fork(watchDeletePaymentMethod)
    ])
}