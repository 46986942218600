import React from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, reset} from "react-redux"
import moment from "moment"

import Actions from "../actions"

const Sidebar = (props) => {
    const dispatch = useDispatch()

    const handleReset = () => {
        dispatch(Actions.resetGetCompoundList())
        dispatch(Actions.resetGetAssessmentTax())
        dispatch(Actions.resetGetPaipList())
        dispatch(Actions.resetGetOtherCompoundList())
    }

    return (
        <div id="aside" className="page-sidenav no-shrink nav-expand  animate fadeInLeft fade sticky" aria-hidden="true">
            <div className="sidenav h-100 modal-dialog bg-white box-shadow">
                <div className="flex scrollable hover">
                    <div className="nav-border b-primary" style={{paddingTop: "1rem"}} data-nav>
                        <ul className="nav bg">
                            {/* <li className="nav-header hidden-folded">
                                <span>Main</span>
                            </li> */}
                            <li className={props.location.pathname === "/home" && "active"}>
                                <Link to="/home" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-home"><i></i></i>
                                    </span>
                                    <span className="nav-text">Home</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "pay" && "active"}>
                                <Link to="/pay" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-shop"><i></i></i>
                                    </span>
                                    <span className="nav-text">Pay</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "prepaid" && "active"}>
                                <Link to="/prepaid" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-phone"><i></i></i>
                                    </span>
                                    <span className="nav-text">Prepaid</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "postpaid" && "active"}>
                                <Link to="/postpaid" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-phone"><i></i></i>
                                    </span>
                                    <span className="nav-text">Postpaid</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "bill" && "active"}>
                                <Link to="/bill" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-menu"><i></i></i>
                                    </span>
                                    <span className="nav-text">Bills</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "roadtax" && "active"}>
                                <Link to="/roadtax" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-file"><i></i></i>
                                    </span>
                                    <span className="nav-text">Roadtax</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "education" && "active"}>
                                <Link to="/education" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-archive"><i></i></i>
                                    </span>
                                    <span className="nav-text">Education</span>
                                </Link>
                            </li>
                            <li className={props.location.pathname.split('/')[1] === "zakat" && "active"}>
                                <Link to="/zakat" className="i-con-h-a" onClick={handleReset}>
                                    <span className="nav-icon">
                                        <i className="i-con i-con-globe"><i></i></i>
                                    </span>
                                    <span className="nav-text">Zakat</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="no-shrink ">
                    <div class="text-sm p-3 b-t">
                        <div class="hidden-folded text-sm">
                            {/* <div class="mt-1">
                                <a href="index.html" class="text-muted">About</a>
                                <a href="../docs/" class="text-muted mx-2 no-ajax" target="_blank">Documentation</a>
                            </div> */}
                            <div class="text-muted"><small class="text-muted">© Copyright {moment().format('YYYY')}, PahangGo</small></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // return (
    //     <div className="sidebar">
    //         <div className="sidebar-title link">
    //             Menu
    //         </div>
    //         {/* <Link to="/home">
    //             <div className={`link ${props.location.pathname === "/home" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname === "/home" ? 'home-active': 'home'}.png`)} className="sidebar-icon"/>
    //                 <p>Home</p>
    //             </div>
    //         </Link> */}

    //         <Link to="/pay">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "pay" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === 'pay' ? 'card-active' : 'card'}.png`)} className="sidebar-icon"/>
    //                 <p>Pay</p>
    //             </div>
    //         </Link>
    //         {/* <Link to="/paip">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "paip" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === 'paip' ? 'waterdrop-active' : 'waterdrop'}.png`)} className="sidebar-icon"/>
    //                 <p>PAIP</p>
    //             </div>
    //         </Link> */}
    //         <Link to="/prepaid">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "prepaid" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === 'prepaid' ? 'phone-active' : 'phone'}.png`)} className="sidebar-icon"/>
    //                 <p>Prepaid</p>
    //             </div>
    //         </Link>
    //         <Link to="/postpaid">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "postpaid" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === "postpaid" ? 'call-active' : 'call'}.png`)} className="sidebar-icon"/>
    //                 <p>Postpaid</p>
    //             </div>
    //         </Link>
    //         <Link to="/bill">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "bill" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === "bill" ? 'bill-active' : 'bill'}.png`)} className="sidebar-icon"/>
    //                 <p>Bills</p>
    //             </div>
    //         </Link>

    //         {/* <Link to="/bus">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "bus" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === "bus" ? 'bus-active' : 'bus'}.png`)} className="sidebar-icon"/>
    //                 <p>Bus</p>
    //             </div>
    //         </Link> */}
    //         <Link to="/roadtax">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "roadtax" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${'car'}.png`)} className="sidebar-icon"/>
    //                 <p>Roadtax</p>
    //             </div>
    //         </Link>
    //         <Link to="/education">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "education" && "active"}`}>

    //                 <img alt="sidebar-icon" src={require(`../assets/images/${props.location.pathname.split("/")[1] === "education" ? 'school-active' : 'school'}.png`)} className="sidebar-icon"/>
    //                 <p>Education</p>
    //             </div>
    //         </Link>
    //         <Link to="/zakat">
    //             <div className={`link ${props.location.pathname.split("/")[1] === "zakat" && "active"}`}>
    //                 <img alt="sidebar-icon" src={require(`../assets/images/${'islam'}.png`)} className="sidebar-icon"/>
    //                 <p>Zakat</p>
    //             </div>
    //         </Link>
    //     </div>
    // );
};

export default Sidebar;