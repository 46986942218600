import React from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom";
import axios from "axios"

import popupTool from "popup-tools"

import Actions from "../actions"

import LoadingOverlay from "../components/LoadingOverlay"
import BackButton from "../components/BackButton"

const PrepaidPay = props => {
  const [paymentType, setPaymentType] = React.useState("online_banking")
  const [status, setStatus] = React.useState(null)
  const [card, setCard] = React.useState(null)
  const [cardInt, setCardInt] = React.useState(null)
  const [d, setD] = React.useState(null);
  const {type} = useParams()
  const dispatch = useDispatch();
  const {isLoading, error, data} = useSelector(state => state.PAYMENT.getPaymentMethods)
  const [ewalletList, setEwalletList] = React.useState([{bank_name: 'Add new method', logo: ''}])
  const [onlineList, setOnlineList] = React.useState([{bank_name: 'Add new method', logo: ''}])
  const [cardList, setCardList] = React.useState([{bank_name: 'Add new method', logo: ''}])
//   const ewallet = useSelector(state => state.PAYMENT.paymentMethodOptions)
  const parkingPayment = useSelector(state => state.FEEDERS.payVehicleCompound)
  const assessmentPayment = useSelector(state => state.FEEDERS.payAssessmentTax)
  const paipPayment = useSelector(state => state.FEEDERS.payPaip)
  const otherCompoundPayment = useSelector(state => state.FEEDERS.payOtherCompound)
  const paymentStatus = useSelector(state => state.PAYMENT.verifyPayment)
  
  React.useEffect(() => {
    if (data.length === 0) {
      dispatch(Actions.getPaymentMethods())
    }
    // dispatch(Actions.getPaymentMethodOptions({paramString: 'ewallet'}))
  }, [])

  React.useEffect(() => {
    if (data.length > 0) {
      data.forEach(d => {
        // console.log(d)
        d.methods.forEach((e, i) => {
          switch (d.type) {
            case "ewallet":
              setEwalletList(state => [e, ...state])
              break;
            case "online_banking":
              setOnlineList(state => [e, ...state])
              break;
            case "credit_card":
                setCardList(state => [e, ...state])
                break;
            default:
              break;
          }

          if (e.is_default === 1) {
            setCard(e)
            setCardInt(i)
            setPaymentType(d.type)
          }
        })
      })
    }
  }, [data])

  const callback = event => {
    if (event.data.status !== undefined) {
        if ("PAYMENT_FAILED") {
          props.history.push('/payment/failed')
        } else {
          props.history.push('/payment/success')
        }
        window.removeEventListener('message', callback)
        return
    }
    // console.log(event.data.status)
    //   if (win.closed) {
    //     props.history.push('/payment/failed')
    //     window.removeEventListener('message', callback)
    //   }
  }

  const handlePayment = (data, paymentId) => {
    var win = window.open(data)
    let interval = setInterval(() => {
        if (win.closed) {
            // Payment verification
            dispatch(Actions.verifyPayment(paymentId))
            dispatch(Actions.resetPayAssessmentTax())
            dispatch(Actions.resetPayOtherCompound())
            dispatch(Actions.resetPayPaip())
            dispatch(Actions.resetPayVehicleCompound())
            clearInterval(interval)
        }
    }, 1000)
  }

  React.useEffect(() => {
    if (!parkingPayment.isLoading && Object.keys(parkingPayment.data).length > 0) {
        handlePayment(parkingPayment.data.redirectUrl, parkingPayment.data.payment_id)
    }
  }, [parkingPayment])

  React.useEffect(() => {
    if (!assessmentPayment.isLoading && Object.keys(assessmentPayment.data).length > 0) {
        handlePayment(assessmentPayment.data.redirectUrl, assessmentPayment.data.payment_id)
    }
  }, [assessmentPayment])

  React.useEffect(() => {
    if (!paipPayment.isLoading && Object.keys(paipPayment.data).length > 0) {
      handlePayment(paipPayment.data.redirectUrl, paipPayment.data.payment_id)
    }
  }, [paipPayment])

  React.useEffect(() => {
      console.log(Object.keys(otherCompoundPayment.data).length)
      if (!otherCompoundPayment.isLoading && Object.keys(otherCompoundPayment.data).length > 0) {
          handlePayment(otherCompoundPayment.data.redirectUrl, otherCompoundPayment.data.payment_id)
      }
  }, [otherCompoundPayment])

  React.useEffect(() => {
    if (!paymentStatus.isLoading && Object.keys(paymentStatus.data).length > 0 && !paymentStatus.error) {
        if (paymentStatus.data.status === 0) {
            props.history.push('/payment/failed')
        } else {
            props.history.push('/payment/success')
        }
        dispatch(Actions.resetVerifyPayment())
    }
  }, [paymentStatus])

  const handlePay = () => {
    let payload = {}
    let payment_method = ""
    if (card.type !== "credit_card") {
        payment_method = card.card_name === null ? "payment_method" : card.card_name
    } else {
        payment_method = card.card_name === null ? "payment_method" : "pre_auth_checkout"
    }
    let payment_method_id = card.id === null ? "payment_method_id" : card.id

    switch (type) {
      case 'parking_compound':
        if (!props.location.state.manual) {
          payload = {
            service_reference_1: props.location.state.data.service_reference_1,
            service_reference_2: props.location.state.data.service_reference_2,
            registration_number: props.location.state.data.registration_number,
            payment_method_id,
            payment_method,
            vehicle_compound_amount_in_cents: props.location.state.data.compound_amount
          }
          dispatch(Actions.payVehicleCompound({data: payload, paramString: props.location.state.data.pbtid}))
        } else {
          payload = {
            name: props.location.state.name,
            compound_no: props.location.state.compound,
            ic: props.location.state.ic,
            registration_number: props.location.state.plate,
            vehicle_compound_amount_in_cents: parseFloat(props.location.state.amount) * 100,
            payment_method_id,
            payment_method,
          }
          dispatch(Actions.payManual("vehicle_compounds", props.location.state.id, payload, data => {
            handlePayment(data.redirectUrl, data.payment_id)
          }))
        }
        break;
      case 'other_compound':
          if (props.location.state.manual) {
            payload = {
              name: props.location.state.name,
              compound_no: props.location.state.compound,
              ic: props.location.state.ic,
              company_no: props.location.state.company,
              other_compound_amount_in_cents: parseFloat(props.location.state.amount) * 100,
              payment_method_id,
              payment_method,
            }
            dispatch(Actions.payManual("other_compounds", props.location.state.id, payload, data => {
              handlePayment(data.redirectUrl, data.payment_id)
            }))
          } else {
              payload = {
                service_reference_1: props.location.state.data.service_reference_1,
                service_reference_2: props.location.state.data.service_reference_2,
                payment_method_id,
                payment_method,
                other_compound_amount_in_cents: props.location.state.data.compound_amount
              }
              dispatch(Actions.payOtherCompound({
                  payload,
                  paramString: props.location.state.data.pbtid
              }))
          }
        break;
      case 'assessment_tax':
        if (!props.location.state.manual) {
          payload = {
            account_number: props.location.state.data.account_number,
            checkdigit: props.location.state.data.checkdigit,
            payment_method_id,
            payment_method,
            assessment_tax_amount_in_cents: props.location.state.data.amount
          }
          dispatch(Actions.payAssessmentTax({data: payload, paramString: props.location.state.data.pbtid}))
        } else {
          payload = {
            name: props.location.state.name,
            tax_no: props.location.state.tax,
            ic: props.location.state.ic,
            address: props.location.state.address,
            assessment_tax_amount_in_cents: parseFloat(props.location.state.amount) * 100,
            payment_method_id,
            payment_method,
          }
          dispatch(Actions.payManual('assessment_taxes', props.location.state.id, payload, data => {
            handlePayment(data.redirectUrl, data.payment_id)
          }))
        }
        break;
      case 'paip':
        dispatch(Actions.payPaip({paramString: `payment_method=${card.card_name}&account_number=${props.location.state.data.account_number}&paip_amount_in_cents=${props.location.state.data.amount}`}))
        break;
      case 'education':
        payload = {
          name: props.location.state.name, 
          ic: props.location.state.ic, 
          amount: parseFloat(props.location.state.amount).toFixed(2),
          product_id: props.location.state.id,
          payment_method_id,
          payment_method,
        }
        dispatch(Actions.submitEducation(payload, data => {
          handlePayment(data.redirectUrl, data.payment_id)
        }))
        break;
      case 'zakat':
        payload = {
          name: props.location.state.name,
          ic: props.location.state.ic,
          phone: props.location.state.phone,
          email: props.location.state.email,
          amount: parseFloat(props.location.state.amount).toFixed(2),
          product_id: props.location.state.type,
          payment_method_id,
          payment_method,
          address: props.location.state.address
        }
        dispatch(Actions.submitZakat(payload, data => {
            handlePayment(data.redirectUrl, data.payment_id)
        }))
        break;
      default:
        payload = {
          amount: parseFloat(props.location.state.amount).toFixed(2),
          account_no: `+60${props.location.state.number}`,
          payment_method_id,
          payment_method,
          product_code: props.location.state.merchant_code
        }
        // console.log(card)
        dispatch(Actions.purchaseProduct(payload, data => {
          handlePayment(data.redirectUrl, data.payment_id)
        }))
        break;
    }

  }

  const renderSummary = param => {
    switch (param) {
      case 'land_tax':
      case 'other_compound':
      case 'assessment_tax':
      case 'bill':
      case 'parking_compound':
      case 'astro':
      case 'education':
      case 'zakat':
        return props.location.state.paymentData.map((i, inc) => (
            <li className="nav-item" key={inc}>
              <div className="nav-link d-flex justify-content-between">
                <p className="mb-0">{i.title}</p>
                <p className="mb-0">{i.value}</p>
              </div>
            </li>
        ))
    
      default:
        return (
          <>
            <li className="nav-item">
              <div className="nav-link d-flex justify-content-between">
                <span className="d-flex">
                  <p className="m-0 mr-2"><strong>PAY</strong></p>
                  <p>{props.location.state.card}<br />{props.location.state.number}</p>
                </span>
                <p><strong>RM {parseFloat(props.location.state.amount).toFixed(2)}</strong></p>
              </div>
            </li>
          </>
        )
        break;
    }
  }

  // return (
  //   <div className="content pay">
  //     {isLoading || parkingPayment.isLoading || assessmentPayment.isLoading && <LoadingOverlay />}
  //     <h1 className="content-title">Prepaid</h1>

  //     <div className="payment-superwrap">
  //       <div className="payment-choices-wrap">
  //         <div className="payment-choices-row">
  //           <div
  //             className={`payment-choice ${paymentType === "online_banking" ? 'active' : ''}`}
  //             onClick={() => setPaymentType("online_banking")}
  //           >
  //             <img src={require('../assets/images/bank.png')} alt="" />
  //             <p>Online Banking</p>
  //           </div>
  //           <div
  //             className={`payment-choice ${paymentType === "credit_card" ? 'active' : ''}`}
  //             onClick={() => setPaymentType("credit_card")}
  //           >
  //             <img src={require('../assets/images/creditcard.png')} alt="" />
  //             <p>Credit/Debit Card</p>
  //           </div>
  //           <div
  //             className={`payment-choice ${paymentType === "ewallet" ? 'active' : ''}`}
  //             onClick={() => setPaymentType("ewallet")}
  //           >
  //             <img src={require('../assets/images/ewallet.png')} alt="" />
  //             <p>eWallet</p>
  //           </div>
  //         </div>
  //         <div className="payment-form">
  //           {paymentType === "online_banking" && (
  //             <>
  //               <p className="payment-type-title">Perbankan online</p>
  //               <div className="form-wrapper">
  //                 <div className="input-holder no-margin">
  //                   <input type="submit" onClick={handlePay} value="PAY NOW" />
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //           {paymentType === "credit_card" && (
  //             <>
  //               <p className="payment-type-title">Credit / Debit Card</p>
  //               <div className="form-wrapper">
  //                 <div className="input-holder">
  //                   <input
  //                     type="text"
  //                     placeholder="Name on card"
  //                     name="cardName"
  //                     value={""}
  //                   />
  //                 </div>
  //                 <div className="input-holder">
  //                   <input
  //                     type="text"
  //                     placeholder="Name"
  //                     name="cardNumber"
  //                     value={""}
  //                   />
  //                 </div>

  //                 <div className="side-by-side">
  //                   <div className="input-holder">
  //                     <input
  //                       type="text"
  //                       placeholder="MM/YY"
  //                       name="expiryDate"
  //                       value={""}
  //                     />
  //                   </div>
  //                   <div className="input-holder">
  //                     <input
  //                       type="text"
  //                       placeholder="CVC"
  //                       name="cvc"
  //                       value={""}
  //                     />
  //                   </div>
  //                 </div>

  //                 <div className="input-holder no-margin">
  //                   <input type="submit" value="PAY" />
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //           {paymentType === "ewallet" && (
  //             <>
  //               <p className="payment-type-title">eWallet</p>
  //               <div className="form-wrapper">
  //                 <div className="input-holder no-margin">
  //                   <input type="submit" onClick={handlePay} value="PAY NOW" />
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //         </div>
  //       </div>

  //       <div className="payment-data prepaid">
  //         <p className="title">Summary</p>
  //         <table className="payment-table">
  //           {renderSummary(type)}
  //           <tr className="border-top">
  //             <td>Payment Amount</td>
  //             <td>RM {parseFloat(props.location.state.amount).toFixed(2)}</td>
  //           </tr>
  //         </table>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div id="content" className="flex">
        {isLoading || paymentStatus.isLoading && <LoadingOverlay />}
      {d !== null && <div className="lolo">
        <iframe id="pay" src={d} frameborder="0"></iframe>
      </div>}
      <div className="h-100 d-flex flex-column padding">
        <div className="row mb-4 d-flex align-items-center">
          <div className="col-auto pr-0">
            <BackButton history={props.history} />
          </div>
          <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-transparent mb-0 p-0">
                  <li class="breadcrumb-item"><Link to="/pay">Pay Pahang</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">Payment Type</li>
              </ol>
          </nav>
        </div>
        <div className="d-flex block flex m-0">
          <div className="aside aside-sm">
            <div className="modal-dialog d-flex flex-column w-md b-r bg-white">
              <div className="navbar">
                <span>Payment</span>
              </div>
              <div className="sidenav my-3">
                <nav className="nav-active-text-primary">
                  <ul className="nav">
                    <li className={paymentType === "online_banking" ? 'active' : ''}>
                      <div className="i-con-h-a menu pointer" onClick={() => {
                        setPaymentType("online_banking");
                        setCard(null);
                        setCardInt(null);
                      }}>
                        <span className="nav-icon">
                          <i className="i-con i-con-archive"><i></i></i>
                        </span>
                        <span className="nav-text">Online Banking</span>
                      </div>
                    </li>
                    <li className={paymentType === "credit_card" ? 'active' : ''}>
                      <div className="i-con-h-a menu pointer" onClick={() => {
                        setPaymentType("credit_card");
                        setCard(null);
                        setCardInt(null);
                      }}>
                        <span className="nav-icon">
                          <i className="i-con i-con-layer"><i></i></i>
                        </span>
                        <span className="nav-text">Credit/Debit Card</span>
                      </div>
                    </li>
                    <li className={paymentType === "ewallet" ? 'active' : ''}>
                      <div className="i-con-h-a menu pointer" onClick={() => {
                        setPaymentType("ewallet");
                        setCard(null);
                        setCardInt(null);
                      }}>
                        <span className="nav-icon">
                          <i className="i-con i-con-page"><i></i></i>
                        </span>
                        <span className="nav-text">EWallet</span>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="d-flex flex">
            <div className="flex py-3 px-4">
              <span>
                {paymentType === "online_banking" && "Online Banking"}
                {paymentType === "credit_card" && "Credit/Debit Card"}
                {paymentType === "ewallet" && "EWallet"}
              </span>
              <div className="pt-5">
                {paymentType === "credit_card" && (
                  <>
                  {/* <div className="form-group">
                    <label htmlFor="">Name on card</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Card number</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="">Expiry Date</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="">CVV</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div> */}
                  <div className="grid">
                    {cardList.length > 0 && cardList.map((e, i) => (

                        <div className={`i-con-h-a justify-content-between card px-2 py-3 border no-shadow text-center d-flex flex-column align-items-center interactive pointer ${cardInt === i ? 'border-primary' : ''}`} style={{minHeight: '150px'}} onClick={() => {
                            if (e.logo) {
                                setCard(e)
                                setCardInt(i)
                            }
                        }}>
                          {e.logo ? <img src={e.logo} style={{height: "60px", position: "absolute", top: "40%", transform: "translateY(-50%)"}} alt=""/> : <i className="i-con i-con-plus" style={{width: '60px', height: '60px', position: "absolute", top: "40%", transform: "translateY(-50%)"}}><i></i></i>}
                          {/* <p className="mb-0 mt-3 text-center" style={{position: "absolute", bottom: "20px", width: "80%"}}>{e.bank_name.length > 14 ? e.bank_name.substring(0, 14) + '...' : e.bank_name}</p> */}
                          <p className="mb-0 mt-3 text-center" style={{position: "absolute", bottom: "20px", width: "80%"}}>{e.card_no}</p>
                        </div>

                    ))}
                  </div>
                  </>
                )}
                {paymentType === "ewallet" && (
                  <div className="grid">
                    {ewalletList.length > 0 && ewalletList.map((e, i) => (

                        <div className={`card justify-content-between px-2 py-3 border no-shadow text-center d-flex flex-column align-items-center interactive pointer ${cardInt === i ? 'border-primary' : ''}`} style={{minHeight: '150px'}} onClick={() => {
                            if (e.logo) {
                                setCard(e)
                                setCardInt(i)
                            }
                        }}>
                          {e.logo ? <img src={e.logo} style={{height: "60px", position: "absolute", top: "40%", transform: "translateY(-50%)"}} alt=""/> : <i className="i-con i-con-plus" style={{width: '60px', height: '60px', position: "absolute", top: "40%", transform: "translateY(-50%)"}}><i></i></i>}
                          <p className="mb-0 mt-3 text-center" style={{position: "absolute", bottom: "20px", width: "80%"}}>{e.bank_name.length > 14 ? e.bank_name.substring(0, 14) + '...' : e.bank_name}</p>
                        </div>

                    ))}
                  </div>
                )}
                {paymentType === "online_banking" && (
                  <div className="grid">
                    {onlineList.length > 0 && onlineList.map((e, i) => (

                        <div className={`card justify-content-between px-2 py-3 border no-shadow text-center d-flex flex-column align-items-center interactive pointer ${cardInt === i ? 'border-primary' : ''}`} style={{minHeight: '150px'}} onClick={() => {
                            if (e.logo) {
                                setCard(e)
                                setCardInt(i)
                            }
                        }}>
                          {e.logo ? <img src={e.logo} style={{height: "60px", position: "absolute", top: "40%", transform: "translateY(-50%)"}} alt=""/> : <i className="i-con i-con-plus" style={{width: '60px', height: '60px', position: "absolute", top: "40%", transform: "translateY(-50%)"}}><i></i></i>}
                          <p className="mb-0 mt-3 text-center" style={{position: "absolute", bottom: "20px", width: "80%"}}>{e.bank_name.length > 14 ? e.bank_name.substring(0, 14) + '...' : e.bank_name}</p>
                        </div>

                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="b-l d-flex flex-column justify-content-between" style={{minWidth: "30%"}}>
              <div>
                <div className="navbar">
                  <span>Summary</span>
                </div>
                <div className="py-3">
                  <ul className="nav flex-column">
                    {renderSummary(type)}
                    <li className="nav-item mt-5 mx-3 b-t">
                      <div className="nav-link px-0 d-flex justify-content-between">
                        <p>Payment Amount</p>
                        <p><strong>RM {parseFloat(props.location.state.amount).toFixed(2)}</strong></p>
                      </div>
                    </li>
                    {/* <li className="nav-item">
                      <div className="nav-link d-flex justify-content-between">
                        <span className="d-flex">
                          <p className="m-0 mr-2"><strong>PAY</strong></p>
                          <p>Umobile Postpaid<br />0172914197</p>
                        </span>
                        <p><strong>RM 50.00</strong></p>
                      </div>
                    </li>
                    <li className="nav-item mt-5 mx-3 b-t">
                      <div className="nav-link px-0 d-flex justify-content-between">
                        <p>Payment Amount</p>
                        <p><strong>RM 50.00</strong></p>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="b-t px-4 py-3">
                <button className="btn w-100 btn-primary" disabled={!card} onClick={handlePay}>Pay Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PrepaidPay;
