export const NAME = "FEEDERS";

export const PAY_ASSESSMENT_TAX = `${NAME}/PAY_ASSESSMENT_TAX`;
export const PAY_ASSESSMENT_TAX_SUCCESS = `${NAME}/PAY_ASSESSMENT_TAX_SUCCESS`;
export const PAY_ASSESSMENT_TAX_FAIL = `${NAME}/PAY_ASSESSMENT_TAX_FAIL`;
export const RESET_PAY_ASSESSMENT_TAX = `${NAME}/RESET_PAY_ASSESSMENT_TAX`;

export const getPayAssessmentTaxData = store => store[NAME].payAssessmentTax;

export const payAssessmentTax = data => ({
  type: PAY_ASSESSMENT_TAX,
  data
});

export const payAssessmentTaxSuccess = data => ({
  type: PAY_ASSESSMENT_TAX_SUCCESS,
  data
});

export const payAssessmentTaxFail = error => ({
  type: PAY_ASSESSMENT_TAX_FAIL,
  error
});

export const resetPayAssessmentTax = () => ({
  type: RESET_PAY_ASSESSMENT_TAX
});