export const NAME = 'AUTH'

export const OTP = `${NAME}/OTP`;
export const OTP_SUCCESS = `${NAME}/OTP_SUCCESS`;
export const OTP_FAIL = `${NAME}/OTP_FAIL`;
export const RESET_OTP = `${NAME}/RESET_OTP`;

export const getOtp = store => store[NAME].otp;

export const otp = data => ({
    type: OTP,
    data
});

export const otpSuccess = data => ({
    type: OTP_SUCCESS,
    data
});

export const otpFail = error => ({
    type: OTP_FAIL,
    error
});

export const resetOtp = () => ({
    type: RESET_OTP,
})