export const NAME = 'ORDER'

export const SUBMIT_ORDER = `${NAME}/SUBMIT_ORDER`;
export const SUBMIT_ORDER_SUCCESS = `${NAME}/SUBMIT_ORDER_SUCCESS`;
export const SUBMIT_ORDER_FAILED = `${NAME}/SUBMIT_ORDER_FAILED`;

export const SAVE_TYPE = `${NAME}/SAVE_TYPE`;
export const SAVE_USER_INFO = `${NAME}/SAVE_USER_INFO`;
export const SAVE_VEHICLE_TYPE = `${NAME}/SAVE_VEHICLE_TYPE`;

export const SAVE_VEHICLE_DATA = `${NAME}/SAVE_VEHICLE_DATA`;

export const CHECK_EXPIRY_ROADTAX = `${NAME}/CHECK_EXPIRY_ROADTAX`;
export const CHECK_EXPIRY_ROADTAX_SUCCESS = `${NAME}/CHECK_EXPIRY_ROADTAX_SUCCESS`;
export const CHECK_EXPIRY_ROADTAX_FAILED = `${NAME}/CHECK_EXPIRY_ROADTAX_FAILED`;

export const submitOrder = (data, callback, callbackError) => ({
    type: SUBMIT_ORDER,
    data,
    callback,
    callbackError
});

export const submitOrderSuccess = () => ({
    type: SUBMIT_ORDER_SUCCESS
});

export const submitOrderFailed = () => ({
    type: SUBMIT_ORDER_FAILED
});

export const saveUserInfo = data => ({
    type: SAVE_USER_INFO,
    data
});

export const saveType = data => ({
    type: SAVE_TYPE,
    data
});

export const saveVehicleType = data => ({
    type: SAVE_VEHICLE_TYPE,
    data
});

export const saveVehicleData = data => ({
    type: SAVE_VEHICLE_DATA,
    data
});

export const checkExpiryRoadTax = (data, callback, callbackError, callbackExist) => ({
    type: CHECK_EXPIRY_ROADTAX,
    data,
    callback,
    callbackError,
    callbackExist
});

export const checkExpiryRoadTaxSuccess = (data) => ({
    type: CHECK_EXPIRY_ROADTAX_SUCCESS,
    data
});

export const checkExpiryRoadTaxFailed = (data) => ({
    type: CHECK_EXPIRY_ROADTAX_FAILED,
    data
});