import React from 'react';
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector, connect } from "react-redux";

import LoadingOverlay from "../components/LoadingOverlay";
import MessageModal from "../components/MessageModal"

import Actions from "../actions"

const Account = props => {
    const [linkedAccFb, setLinkedFb] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState({
        current: false,
        password: false
    })
    const [input, setInput] = React.useState({
        current: "",
        password: "",
        pin1: "",
        pin2: "",
        pin3: "",
        pin4: "",
        old1: "",
        old2: "",
        old3: "",
        old4: "",
        reenter1: "",
        reenter2: "",
        reenter3: "",
        reenter4: "",
    })
    const [inputError, setInputError] = React.useState("")
    const [success, setSuccess] = React.useState(false)
    const [linkedAccGoogle, setLinkedGoogle] = React.useState(true);
    const dispatch = useDispatch()
    const profileData = useSelector(state => state.PROFILE.userProfile);
    const {isLoading} = useSelector(state => state.AUTH.reset)
    const [pinModal, setPinModal] = React.useState(false)
    const [step, setStep] = React.useState(1)
    const [pinError, setPinError] = React.useState("")

    const handleChange = e => {
        const {name, value} = e.target
        setInput((state) => ({...state, [name]: value}))
    }

    const handleUpdate = () => {
        setInputError("")
        setSuccess(false)
        dispatch(Actions.changePassword({
            old_password: input.current,
            new_password: input.password
        }, () => {
            setSuccess(true)
        }, () => {
            setInputError("Your current password is incorrect.")
        }))
    }

    // React.useEffect(() => {
    //     console.log()
    // }, [profileData])

    // React.useEffect(() => {
    //     dispatch(Actions.getUserProfileData())
    // }, [])

    // return (
    //     <div className="content profile-settings">
    //         <h1 className="content-title">Linked Account</h1>

    //         <div className="content-card margin-bottom">
    //             <div className="linked-account">
    //                 <div className="link-block">
    //                     <div className="facebook">
    //                         <img src={require(`../assets/images/facebook-white.png`)} alt=""/>
    //                         FACEBOOK
    //                     </div>
    //                     <img src={require(`../assets/images/${linkedAccFb ? 'mark-active' : 'mark'}.png`)} alt=""/>
    //                 </div>
    //                 <div className="link-block active">
    //                     <div className="google">
    //                         <img src={require(`../assets/images/google-white.png`)} alt=""/>
    //                         GOOGLE
    //                     </div>
    //                     <img src={require(`../assets/images/${linkedAccGoogle ? 'mark-active' : 'mark'}.png`)} alt=""/>
    //                 </div>
    //             </div>
    //         </div>

    //         <h1 className="content-title">Change Password</h1>

    //         <div className="content-card">
    //             <div className="form-wrapper">
    //                 <Field
    //                     component={TextInput}
    //                     label="Current Password"
    //                     name="inputPassword"
    //                     placeholder="Enter current password"
    //                     className="password"
    //                 />
                    
    //                 <div className="stand-in-label">
    //                     <p>New Password</p>
    //                 </div>
    //                 <Field
    //                     component={TextInput}
    //                     type={`${showPassword ? "text" : "password"}`}
    //                     label=""
    //                     name="inputNewPassword"
    //                     placeholder="New Password"
    //                     className="password"
    //                 >
    //                     <img
    //                         src={require(`../assets/images/${
    //                             showPassword ? "visable" : "not-visable"
    //                             }.png`)}
    //                         alt=""
    //                         onClick={() => setShowPassword(!showPassword)}
    //                     />
    //                 </Field>

    //                 <div className="orange-btn">
    //                     UPDATE
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className="flex" id="content">
            {isLoading && <LoadingOverlay />}
            <MessageModal show={success} pinError={pinError} title="Password Changed" button="Continue to login" message="You’ve succesfully updated your password. You can now sign in with your new credentials." handleClose={() => {
                dispatch(Actions.logout())
                props.history.push('/')
            }} />
            <MessageModal show={pinModal} input={input} title="Add Transaction PIN" button="Next" step={step} handleChange={handleChange} pin={true} handleClose={() => {
              if (step < 2) {
                setStep(state => state+1)
              } else {
                if (`${input.pin1}${input.pin2}${input.pin3}${input.pin4}` !== `${input.reenter1}${input.reenter2}${input.reenter3}${input.reenter4}`) {
                  setPinError("New PIN and Re-enter PIN is incorrect.")
                }
              }
            }} handleCancel={() => setPinModal(false)} />
            <div className="h-100 d-flex padding" id="page-container">
                <div className="block flex mb-0">
                    <div className="page-title padding pb-0 ">
                        <h2 className="text-md mb-0">Linked Account</h2>
                    </div>
                    <div className="padding">
                        <div className="row">
                            <div className="col-sm-4">
                                <button className={`btn btn-block indigo text-white mb-2 ${Object.keys(profileData.data.linked_accounts)[1] !== "facebook" ? 'opacity' : ''}`} disabled>
                                    <i className="float-left">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 264 512">
                                            <path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" class=""></path>
                                        </svg>
                                    </i>
                                    Facebook
                                </button>
                                <button className={`btn btn-block red text-white mb-2 ${Object.keys(profileData.data.linked_accounts)[1] !== "google" ? 'opacity' : ''}`} disabled>
                                    <i className="float-left">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 640 512">
                                        <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" class=""></path>
                                        </svg>
                                    </i>
                                    Google
                                </button>
                            </div>
                        </div>
                    </div>
                    {profileData.data.channel === "email" && (
                        <>
                            <div className="page-title padding pb-0 pt-0 ">
                                <h2 className="text-md mb-0">Change Password</h2>
                            </div>
                            <div className="padding">
                                <div className="row">
                                    <div className="col-sm-6">
                                        {/* {success && <div className={`alert bg-success mb-4 fade ${success ? 'show' : ''}`} role="alert">
                                            You’ve succesfully updated your password.
                                        </div>} */}
                                        <div className="form-group">
                                            <label htmlFor="">Current Password</label>
                                            <div className="input-group">
                                                <input type={showPassword.current ? 'text' : 'password'} className={`form-control ${inputError ? 'is-invalid' : ''}`} name="current" onChange={handleChange} value={input.current} />
                                                <div className="input-group-append">
                                                    <button onClick={() => setShowPassword(state => ({...state, current: !state.current}))} className={`btn btn-outline-secondary ${showPassword.current ? 'bg-secondary' : ''}`}>{showPassword.current ? 'Hide' : 'Show'}</button>
                                                </div>
                                            </div>
                                            {inputError && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
                                            <li class="parsley-required">{inputError}</li>
                                            </ul>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">New Password</label>
                                            <div className="input-group">
                                                <input type={showPassword.password ? 'text' : 'password'} className="form-control" name="password" onChange={handleChange} value={input.password} />
                                                <div className="input-group-append">
                                                    <button onClick={() => setShowPassword(state => ({...state, password: !state.password}))} className={`btn btn-outline-secondary ${showPassword.password ? 'bg-secondary' : ''}`}>{showPassword.password ? 'Hide' : 'Show'}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary w-100 mt-4" disabled={!input.current || !input.password} onClick={handleUpdate}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {/* <div className="page-title padding pb-0 pt-0 ">
                        <h2 className="text-md mb-0">Transaction PIN</h2>
                    </div>
                    <div className="padding">
                        <div className="row">
                            <div className="col-sm-6">
                                <button className="btn btn-primary w-100" onClick={() => setPinModal(true)}>Add Transaction PIN</button>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "accountForm",
    // validate
})(Account);
