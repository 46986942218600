import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

import delay from 'redux-saga'

function* login({ data }) {
    // console.log(data);
    // return;
    const channel = data.inputChannel;
    // const formData = new FormData();
    // formData.append('email', data.inputEmail);
    // formData.append('channel', channel);

    // only include password if its for email login
    // if (channel === "email") formData.append("password", data.inputPassword);
    // if (channel !== "email") formData.append("oauth_token", data.oauth_token);

    var obj = {
        'email': data.inputEmail,
        'channel': channel,
    }

    if (channel === "email") obj["password"] = data.inputPassword;
    if (channel !== "email") obj["oauth_token"] = data.oauth_token;

    // console.log('channel', channel);

    // console.log(formData)
    
    // select api accordingly
    let loginAPI = channel === "email" ? api.login : api.loginFromSocial
    
    const { response, error } = yield call(loginAPI, obj);

    console.log(response);
    if (response) {

        console.log(response.data)

        yield put(Actions.activateUserSession({ auth_token: response.data.data.auth_token }));

        yield delay(500)

        yield put(Actions.loginSuccess(response.data.data));
        
    } else {
        yield put(Actions.loginFail(error.response.data));
    }
}

function* watchLogin() {
    yield takeLatest(Actions.LOGIN, login);
}

export default function* submit() {
    yield all([
        fork(watchLogin)
    ])
}