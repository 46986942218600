export const NAME = "FEEDERS";

export const PAY_PAIP = `${NAME}/PAY_PAIP`;
export const PAY_PAIP_SUCCESS = `${NAME}/PAY_PAIP_SUCCESS`;
export const PAY_PAIP_FAIL = `${NAME}/PAY_PAIP_FAIL`;
export const RESET_PAY_PAIP = `${NAME}/RESET_PAY_PAIP`;

export const getPayPaipData = store => store[NAME].payPaip;

export const payPaip = data => ({
  type: PAY_PAIP,
  data
});

export const payPaipSuccess = data => ({
  type: PAY_PAIP_SUCCESS,
  data
});

export const payPaipFail = error => ({
  type: PAY_PAIP_FAIL,
  error
});

export const resetPayPaip = () => ({
  type: RESET_PAY_PAIP
});
