import * as userSession from "./userSession";
import * as userProfile from "./userProfile";
import * as paymentMethods from "./paymentMethods";
// import * as updateUserPhone from "./updateUserPhone";
// import * as updatePhoneVerify from "./updatePhoneVerify";

export default {
  ...userSession,
  ...userProfile,
  ...paymentMethods,
//   ...updateUserPhone,
//   ...updatePhoneVerify
};
