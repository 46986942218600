import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: {} });

function payOtherCompound(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.PAY_OTHER_COMPOUND:
      return { isLoading: true, error: null, data: {} };
    case Actions.PAY_OTHER_COMPOUND_SUCCESS:
      return { isLoading: false, error: null, data: action.data };
    case Actions.PAY_OTHER_COMPOUND_FAIL:
      return { isLoading: false, error: action.error, data: {} };
    case Actions.RESET_PAY_OTHER_COMPOUND:
      return getDefaultState();
    default:
      return state;
  }
}

export default payOtherCompound;
