import React from 'react';
import {useSelector, useDispatch} from "react-redux"
import {Link} from "react-router-dom"
import moment from "moment"

import Actions from "../../actions"

import AddressModal from "../../components/AddressModal"
import BackButton from "../../components/BackButton"
import MessageModal from "../../components/MessageModal"

const RoadtaxSummary = ({history, location}) => {
    const [showPopup, setShowPopup] = React.useState(false)
    const [message, setMessage] = React.useState({
        title: "",
        message1: "",
        message2: ""
    })
    const [success, setSuccess] = React.useState(false)
    const [address, setAddress] = React.useState("")
    const dispatch = useDispatch()
    const {insuranceData} = useSelector(state => state.VEHICLE.order)
    const profileData = useSelector(state => state.PROFILE.userProfile);
    const addressData = useSelector(state => state.VEHICLE.address)
    const [input, setInput] = React.useState({
        name: profileData.data.name,
        phone: profileData.data.phone,
        email: profileData.data.email,
    })

    React.useEffect(() => {
        if (insuranceData === null) {
            history.push('/roadtax')
        }
    }, [])

    const handleChange = e => {
        const {name, value} = e.target
        setInput(state => ({...state, [name]: value}))
    }

    const handleSubmit = () => {
        let payload = {
    		name: input.name,
			phone: input.phone,
			email: input.email,
			ic: location.state.ic,
			plate_number: location.state.plate,
			address1: address.unit,
			address2: address.street,
			state: address.state,
			city: address.city,   
			postcode: address.postcode,
			vehicle_type: "motorcycle",
			type: "Car",
			chassis: insuranceData.chassis_no,
			model: insuranceData.model,
			maker: insuranceData.maker,
			expiry_date: insuranceData.expiry_date
        };
        
        if (insuranceData.almost_expire) {
            setMessage({
                title: "Submitted",
                message1: "Your application was submitted to process",
                message2: "We will email you for the quotation soon!"
            })
        } else {
            setMessage({
                title: "Submitted",
                message1: `Your renewal expired on ${moment(insuranceData.expiry_date).format('DD/MM/YYYY')}. We only process 2 months below for expiry.`,
                message2: `But don’t worry we set an auto reminder to remind you again on ${moment(insuranceData.expiry_date).subtract(2, 'months').format('DD/MM/YYYY')} to process your renewal again.`
            })
        }

        dispatch(Actions.submitOrder(
        payload, 
        () => {
            setSuccess(true)
        }, 
        () => {
            setSuccess(true)
            setMessage({
                title: "Failed",
                message1: `Failed submit roadtax/insurance order.`,
                message2: ""
            })
        })
        )

    }

    // return (
    //     <div className="content road-tax">
    //         <h1 className="content-title">Roadtax</h1>
    //         <div className="content-card">
    //             <div className="form-wrapper">
    //                 {insuranceData !== null && (
    //                     <>
    //                         <div className="input-holder">
    //                             <label htmlFor="plateNumber">Car Brand</label>
    //                             <input type="text" disabled placeholder="Enter plate number" name="plateNumber" value={insuranceData.maker} />
    //                         </div>
    //                         <div className="input-holder">
    //                             <label htmlFor="plateNumber">Car Model</label>
    //                             <input type="text" disabled placeholder="Enter plate number" name="plateNumber" value={insuranceData.model} />
    //                         </div>
    //                         <div className="input-holder">
    //                             <label htmlFor="plateNumber">Chassis Number</label>
    //                             <input type="text" disabled placeholder="Enter plate number" name="plateNumber" value={insuranceData.chassis_no} />
    //                         </div>
    //                         <div className="input-holder">
    //                             <label htmlFor="plateNumber">Roadtax Expiry Date</label>
    //                             <input type="text" disabled placeholder="Enter plate number" name="plateNumber" value={insuranceData.expiry_date} />
    //                         </div>
    //                     </>
    //                 )}
    //                 <div className="input-holder">
    //                     <label htmlFor="plateNumber">Applicant Name</label>
    //                     <input type="text" placeholder="Enter applicant name" name="plateNumber" value={profileData.data.name} />
    //                 </div>
    //                 <div className="input-holder">
    //                     <label htmlFor="plateNumber">Phone Number</label>
    //                     <input type="text" placeholder="Enter phone number" name="plateNumber" value={profileData.data.phone} />
    //                 </div>
    //                 <div className="input-holder">
    //                     <label htmlFor="plateNumber">Email</label>
    //                     <input type="text" placeholder="Enter email" name="plateNumber" value={profileData.data.email} />
    //                 </div>
    //                 <div className="input-holder">
    //                     <label htmlFor="plateNumber">Delivery Address</label>
    //                     <input type="text" placeholder="Enter address" name="plateNumber" value={""} />
    //                 </div>
    //                 <button>NEXT</button>
    //             </div>
    //             {/* <div className="payment-data">
    //                 <p className="title">Summary</p>
    //                 <table className="payment-table">
    //                     <tr>
    //                         <td>Name</td>
    //                         <td>Malik Khalid</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Phone Number</td>
    //                         <td>60123456789</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Email</td>
    //                         <td>malik@pahanggo.com</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Mykad</td>
    //                         <td>901234567890</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Address</td>
    //                         <td>12 ICT Hub,Jalan Putra 4,25300,Kuantan, Pahang.</td>
    //                     </tr>
    //                     <tr>
    //                         <td>Plate Number</td>
    //                         <td>CDC1234</td>
    //                     </tr>
    //                 </table>
    //             </div> */}

    //             {/* <div className="submit-button">
    //                 <p>SUBMIT</p>
    //             </div> */}
    //         </div>
    //     </div>
    // );

    return (
        <div id="content" className="flex">
            <AddressModal showPopup={showPopup} setShowPopup={setShowPopup} setAddress={setAddress} />
            <MessageModal show={success} title={message.title} button="Okay" isMessage={true} message={`${message.message1}${message.message2 ? '<br />' : ''}${message.message2}`} handleClose={() => {
                setSuccess(false)
                setMessage({
                    title: "",
                    message1: "",
                    message2: ""
                })
                if (message.title === "Submitted") {
                    history.push('/roadtax')
                }
            }} />
            <div className="h-100 d-flex padding">
                <div className="block flex mb-0">
                    <div className="padding pb-0 d-flex align-items-center">
                        <BackButton history={history} />
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-white mb-0 p-0">
                                <li class="breadcrumb-item"><Link to="/pay">Roadtax Form</Link></li>
                                <li class="breadcrumb-item active" aria-current="page">Roadtax Summary</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="page-title padding pb-0">
                        <h2 className="text-md mb-0">Roadtax</h2>
                    </div>
                    <div className="padding">
                        <div className="row">
                            <div className="col-sm-4 col-md-6">
                                <div className="form-group">
                                    <label htmlFor="">Car Brand</label>
                                        <input type="text" className="form-control" name="name" disabled value={insuranceData.maker ? insuranceData.maker : "-"} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Car Model</label>
                                    <input type="text" className="form-control" name="name" disabled value={insuranceData.model ? insuranceData.model : "-"} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Chassis Number</label>
                                    <input type="text" className="form-control" name="name" disabled value={insuranceData.chassis_no ? insuranceData.chassis_no : "-"} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Roadtax Expiry Date</label>
                                    <input type="text" className="form-control" name="name" disabled value={insuranceData.expiry_date ? insuranceData.expiry_date : "-"} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Applicant Name</label>
                                    <input type="text" className="form-control" name="name" onChange={handleChange} value={input.name} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Phone Number</label>
                                    <input type="text" className="form-control" name="phone" onChange={handleChange} value={input.phone} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <input type="text" className="form-control" name="email" onChange={handleChange} value={input.email} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Delivery Address</label>
                                    <div className="form-control" onClick={() => setShowPopup(true)}>
                                        <p className="mb-0">{address ? `${address.unit}, ${address.street}, ${address.postcode}, ${address.city}, ${address.state}, ${address.country}` : "Add address"}</p>
                                    </div>
                                </div>
                                <button className="btn btn-primary w-100 mt-4" disabled={!input.name || !input.email || !input.phone || !address.unit} onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoadtaxSummary;