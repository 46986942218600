import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* forgot({ data, callback, callbackError }) {
    const { response, error } = yield call(api.forgot, data);
    if (response) {
        yield put(Actions.forgotPasswordSuccess());
        callback && callback();
    } else {
        yield put(Actions.forgotPasswordFailed());
        callbackError && callbackError();
    }
}

function* check({ data, callback, callbackError }) {
    const { response, error } = yield call(api.check, data);
    if (response) {
        yield put(Actions.checkResetCodeSuccess());
        callback && callback();
    } else {
        yield put(Actions.checkResetCodeFailed());
        callbackError && callbackError();
    }
}

function* update({ data, callback, callbackError }) {
    const { response, error } = yield call(api.update, data);
    if (response) {
        yield put(Actions.updatePasswordSuccess());
        callback && callback();
    } else {
        yield put(Actions.updatePasswordFailed());
        callbackError && callbackError();
    }
}

function* change({ data, callback, callbackError }){

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.changePassword, data, headers);
    if (response) {
        yield put(Actions.changePasswordSuccess());
        // yield put(Actions.logout());
        callback && callback();
    } else {
        yield put(Actions.changePasswordFailed());
        callbackError && callbackError();
    }
}

function* watch() {
    yield takeLatest(Actions.FORGOT_PASSWORD, forgot);
    yield takeLatest(Actions.CHECK_RESET_CODE, check);
    yield takeLatest(Actions.UPDATE_PASSWORD, update);
    yield takeLatest(Actions.CHANGE_PASSWORD, change);
}

export default function* reset() {
    yield all([
        fork(watch)
    ])
}