import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";
import { useSelector } from "react-redux";

const getSessionData = store => store.PROFILE.userSession;

function* home({data}) {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}` };

  const { response, error } = yield call(api.getCount, headers, data);

  if (response) {
    yield put(Actions.homeSuccess(response.data.data));
  } else {
    // yield put(Actions.homeFailed(error.response.data));
  }
}

function* watchHome() {
  yield takeLatest(Actions.HOME, home);
}

export default function* submit() {
  yield all([fork(watchHome)]);
}
