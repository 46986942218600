import { combineReducers } from "redux";

import paymentMethodOptions from "./paymentMethodOptions";
import addPaymentMethod from "./addPaymentMethod";
import deletePaymentMethod from "./deletePaymentMethod";
import getPaymentMethods from "./getPaymentMethods";
import setDefaultPaymentMethod from "./setDefaultPaymentMethod";
import paymentService from "./paymentService";
import addCardPaymentMethod from "./addCardPaymentMethod";
import addeWalletPaymentMethod from "./addeWalletPaymentMethod";
import verifyPayment from "./verifyPayment"

export default combineReducers({
  paymentMethodOptions,
  addPaymentMethod,
  deletePaymentMethod,
  getPaymentMethods,
  setDefaultPaymentMethod,
  paymentService,
  addCardPaymentMethod,
  addeWalletPaymentMethod,
  verifyPayment
});
