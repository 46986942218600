import React from 'react';
import {useSelector, useDispatch} from "react-redux"

import Actions from "../actions"
import profile from '../actions/profile';

import AddressModal from "../components/AddressModal"

const Zakat = props => {
  const dispatch = useDispatch()
  const {list} = useSelector(state => state.ZAKAT.options)
  const [showPopup, setShowPopup] = React.useState(false)
  const profileData = useSelector(state => state.PROFILE.userProfile);
  const [address, setAddress] = React.useState("")
  const [input, setInput] = React.useState({
    name: profileData.data.name,
    ic: profileData.data.ic,
    phone: profileData.data.phone,
    email: profileData.data.email,
    type: 58,
    amount: ""
  })

  React.useEffect(() => {
    dispatch(Actions.getZakatOptions())
  }, [])

  // React.useEffect(() => {
  //   console.log(list)
  // }, [list])

  const handleChange = e => {
    const {name, value} = e.target
    setInput((state) => ({...state, [name]: value}))
  }
  
  const handleNext = () => {
    props.history.push({
      pathname: '/zakat/pay',
      state: {
        paymentData: [
          {
            title: "Name",
            value: input.name
          },
          {
            title: "MyKad",
            value: input.ic
          },
          {
            title: "Email",
            value: input.email
          }
        ],
        name: input.name,
        ic: input.ic,
        amount: input.amount,
        type: input.type,
        phone: input.phone,
        email: input.email,
        address: `${address.unit}, ${address.street}, ${address.postcode}, ${address.city}, ${address.state}, ${address.country}`
       }
    })
  }

  // return (
  //   <div className="content zakat">

  //   {showPopup && (

  //   <div className="popup-backdrop">
  //       <div className="popup small">
  //           <div 
  //               className="close-popup"
  //               onClick={() => setShowPopup(false)}
  //           >
  //               <img src={require('../assets/images/close.png')} alt="" />
  //           </div>

  //           <div className="popup-content">
  //               <div className="form-wrapper">
  //                   <div className="input-holder">
  //                       <label htmlFor="unitNo">Unit/Building/Lot</label>
  //                       <input type="text" placeholder="e.g. 10" name="unit" onChange={handleChange} value={inputAddr.unit} />
  //                   </div>

  //                   <div className="input-holder">
  //                       <label htmlFor="street">Street</label>
  //                       <input type="text" placeholder="e.g. Jalan Tasik" name="street" onChange={handleChange} value={inputAddr.street} />
  //                   </div>

  //                   <div className="input-holder">
  //                       <label htmlFor="country">Postcode</label>
  //                       <input type="text" placeholder="e.g. 00000" name="postcode" onChange={handleChange} value={inputAddr.postcode} />
  //                   </div>

  //                   <div className="input-holder">
  //                       <label htmlFor="country">Country</label>
  //                       <input type="text" placeholder="e.g. Malaysia" name="country" onChange={handleChange} value={inputAddr.country} />
  //                   </div>

  //                   <div className="input-holder">
  //                       <label htmlFor="state">State</label>
  //                       <input type="text" placeholder="e.g. Pahang" name="state" onChange={handleChange} value={inputAddr.state} />
  //                   </div>

  //                   <div className="input-holder">
  //                       <label htmlFor="city">City</label>
  //                       <input type="text" placeholder="e.g. Kuantan" name="city" onChange={handleChange} value={inputAddr.city} />
  //                   </div>

  //                   <div className="input-holder submit-holder">
  //                       <input type="submit" value="SAVE" onClick={() => {
  //                         setAddress(`${inputAddr.unit}, ${inputAddr.street}, ${inputAddr.postcode}, ${inputAddr.city}, ${inputAddr.state}, ${inputAddr.country}`);
  //                         setShowPopup(false);
  //                       }}/>
  //                   </div>
  //               </div>
  //           </div>
  //       </div>
  //   </div>
  //   )}

  //     <h1 className="content-title">Zakat</h1>

  //     <div className="content-form">

  //       <form>
  //         <div className="form-wrapper">
  //           <div className="input-holder">
  //             <label htmlFor="">Name</label>
  //             <input type="text" placeholder="Enter your name" name="name" onChange={handleChange} value={input.name} />
  //           </div>
  //           <div className="input-holder">
  //             <label htmlFor="">Mykad</label>
  //             <input type="text" placeholder="Enter your MyKad number" name="ic" onChange={handleChange} value={input.ic} />
  //           </div>
  //           <div className="input-holder">
  //             <label htmlFor="">Phone Number</label>
  //             <input type="text" placeholder="Enter your phone number" name="phone" onChange={handleChange} value={input.phone} />
  //           </div>
  //           <div className="input-holder">
  //             <label htmlFor="">Email</label>
  //             <input type="text" placeholder="Enter your email" name="email" onChange={handleChange} value={input.email} />
  //           </div>
  //           <div className="input-holder">
  //             <label htmlFor="">Address</label>
  //             {/* <input type="text" placeholder="Add Address" /> */}
  //             <div className="amount-input btn" onClick={() => setShowPopup(true)}>
  //               <p className={address !== "" ? "value" : ""}>{address !== "" ? address : "Add address"}</p>
  //             </div>
  //           </div>
  //           <div className="input-holder">
  //             <label htmlFor="">Zakat Type</label>
  //             <select name="type" onChange={handleChange}>
  //               <option disabled selected value="0">Choose Type</option>
  //               {list.map(l => (
  //                 <option key={l.id} value={l.id}>{l.name}</option>
  //               ))}
  //             </select>
  //           </div>
  //           <div className="input-holder amount">
  //             <label htmlFor="">Amount</label>
  //             {/* <p>Minimum amount RM40</p> */}
  //             <div className="amount-input">
  //               <h4>RM</h4>
  //               {/* <Field type="text" placeholder="0" name="amount" /> */}
  //               <input type="text" name="amount" onChange={handleChange} value={input.amount} placeholder="0" />
  //             </div>
  //           </div>
  //           <button onClick={handleNext}>NEXT</button>
  //         </div>
  //       </form>

  //     </div>
      
  //   </div>
  // );

  return (
    <div className="flex" id="content">
      <AddressModal showPopup={showPopup} setShowPopup={setShowPopup} setAddress={setAddress} />
      <div className="h-100 d-flex padding" id="page-container">
        <div className="block flex mb-0">
          <div className="page-title padding pb-0 ">
            <h2 className="text-md mb-0">Zakat</h2>
          </div>
          <div className="padding">
            <div className="row">
              <div className="col-sm-4 col-md-8">
                {/* <div className="card shadow-md border"> */}
                  {/* <div className="p-4"> */}
                    <div className="form-group">
                      <label htmlFor="">Name</label>
                      <input type="text" className="form-control" name="name" onChange={handleChange} value={input.name} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">MyKad Number</label>
                      <input type="text" className="form-control" name="ic" onChange={handleChange} value={input.ic} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Phone Number</label>
                      <input type="text" className="form-control" name="phone" onChange={handleChange} value={input.phone} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Email</label>
                      <input type="text" className="form-control" name="email" onChange={handleChange} value={input.email} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Address</label>
                      <div className="form-control pointer" onClick={() => setShowPopup(true)}>
                          <p className="mb-0">{address ? `${address.unit}, ${address.street}, ${address.postcode}, ${address.city}, ${address.state}, ${address.country}` : "Add address"}</p>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Zakat Type</label>
                      <select name="type" className="form-control" onChange={handleChange} value={input.type}>
                        <option disabled selected value="0">Choose Type</option>
                        {list.map((l, i) => (
                          <option key={l.id} value={l.id}>{l.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Amount</label>
                      <div className="input-group">
                          <div className="input-group-prepend">
                              <span className="input-group-text">RM</span>
                          </div>
                          <input type="number" className="form-control" name="amount" onChange={handleChange} value={input.amount} />
                      </div>
                    </div>
                    <button className="btn btn-primary w-100 mt-2" disabled={!input.name || !input.email || !input.ic || !input.phone || !input.type || !address.unit || !input.amount} onClick={handleNext}>Next</button>
                  </div>
                {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Zakat;