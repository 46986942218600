import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* addPaymentMethod({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.addPaymentMethod, data, headers);
    if (response) {
        yield put(Actions.addPaymentMethodSuccess(response.data));
    } else {
        yield put(Actions.addPaymentMethodFail(error.response.data));
    }
}

function* watchAddPaymentMethod() {
    yield takeLatest(Actions.ADD_PAYMENT_METHOD, addPaymentMethod);
}

export default function* submit() {
    yield all([
        fork(watchAddPaymentMethod)
    ])
}