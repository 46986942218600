import React from 'react';

const BusSearch = (props) => {
    const[isReturn, setIsReturn] = React.useState(props.isReturn)
    console.log(props.isReturn)
    return (
        <div className="bus-trip-results-wrap">
            <div className="bus-trip-results">
                <div className="depart-results">
                    <div className="result-detail">
                        <div className="trip-detail">
                            <p className="title">Depart</p>
                            <div className="route">
                                <p>Bentong</p>
                                <img src={require('../../assets/images/arrow-right.png')} alt="" />
                                <p>Malacca Sentral</p>
                            </div>
                            <p className="trip-date">17 April 2020</p>
                        </div>
                        <div className="trip-avalability">
                            <p>3 trips available</p>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Bus</th>
                                    <th>Time</th>
                                    <th>Adult(RM)</th>
                                    <th>Child(RM)</th>
                                    <th>Seating</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img src={require('../../assets/images/car.png')} alt=""/></td>
                                    <td>11:30 am</td>
                                    <td>44.00</td>
                                    <td>12.00</td>
                                    <td>36</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {isReturn && (
                    <div className="depart-results margin-top">
                        <div className="result-detail">
                            <div className="trip-detail">
                                <p className="title">Return</p>
                                <div className="route">
                                    <p>Malacca Sentral</p>
                                    <img src={require('../../assets/images/arrow-right.png')} alt="" />
                                    <p>Bentong</p>
                                </div>
                                <p className="trip-date">19 April 2020</p>
                            </div>
                            <div className="trip-avalability">
                                <p>3 trips available</p>
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Adult(RM)</th>
                                        <th>Child(RM)</th>
                                        <th>Seating</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>11:30 am</td>
                                        <td>44.00</td>
                                        <td>12.00</td>
                                        <td>36</td>
                                        <td>
                                            <div className="bus-trip-select">
                                                SELECT
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>

            <div className="bus-trip-summary">
                <div className="payment-data">
                    <p className="title">Summary</p>
                    <table className="payment-table">
                        <tbody>
                            <tr>
                                <td><b>Depart</b></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="trip-detail-text">Terminal Bersepadu (TBS)</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="trip-detail-text">17 April 2020</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td> <b>Ticket x 0</b></td>
                                <td>RM00.00</td>
                            </tr>
                            <tr className="border-top">
                                <td>Payment Amount</td>
                                <td>RM00.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default BusSearch;