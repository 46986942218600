import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import UserAuth from './UserAuth';
import PhonePrompt from './PhonePrompt';
const MainAuth = ({history}) => {
    const [authPass, setAuthPass] = React.useState(false);
    const [mode, setMode] = React.useState(0);
    const [value, setValue] = React.useState();

    const handleLogin = (status) => {
        setAuthPass(status)
    }

    const handleRegister = () => {
        setMode(0)
    }

    const handleOTPSwitch = (d) => {
        setMode(1)
        setValue(d)
    }

    // return (
    //     <div className="auth-superwrap">
    //         {authPass && <Redirect to="/home" />}
    //         <div className="auth-wrap">
    //             <div className="logo-row">
    //                 <div className="logo-wrap">
    //                     <img src={require('../assets/images/pahanggo_main_logo.png')} alt="" />
    //                 </div>
    //                 {/* <p className="logo-title">pahangGO</p> */}
    //             </div>
    //             {mode === 0 && (
    //                 <UserAuth 
    //                     handleLogin={handleLogin}
    //                     handleOTPSwitch={handleOTPSwitch}
    //                     history={history}
    //                 />
    //             )}

    //             {mode === 1 && (
    //                 <PhonePrompt handleRegister={handleRegister} value={value} />
    //             )}
    //         </div>
    //     </div>
    // );

    return (
        <div className="d-flex flex-column flex justify-content-center">
            <div className="text-center">
                <img src={require('../assets/images/pahanggo-logo.svg')} alt="" className="mt-5"/>
                <div className="p-3 p-md-5 text-center d-flex justify-content-center">
                    {/* <div className="card  p-0 col-lg-8 offset-lg-2"> */}
                        {/* <div className="row no-gutters"> */}
                            {mode === 0 ? (
                                <UserAuth 
                                    handleLogin={handleLogin}
                                    handleOTPSwitch={handleOTPSwitch}
                                    history={history}
                                />
                            ) : (
                                <PhonePrompt handleRegister={handleRegister} history={history} value={value} />
                            )}
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
};

export default MainAuth;