import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from "../../actions";

import * as api from '../../api';

function* register({ data }) {
    const channel = data.inputChannel;
    const formData = new FormData();
    formData.append('email', data.inputEmail);
    formData.append('name', data.inputName);
    formData.append('phone', data.inputPhone);

    // only include password if its for email registration
    if (channel === "email") formData.append('password', data.inputPassword);
    if (channel !== "email") formData.append('oauth_token', data.oauth_token);

    // select api accordingly
    let registerAPI = channel === "email" ? api.register : api.registerSocial

    var headers = {
        'Content-Type': 'application/json',
        "Authorization": `Bearer QzIhhBBE3HBws/rLiKCYqPOmlzd0IHKC`
    }

    const { response, error } = yield call(registerAPI, formData, headers);
    if (response) {
        yield put(Actions.registerSuccess(response.data.data));
    } else {
        yield put(Actions.registerFail(error.response.data));
    }
}

function* watchRegister() {
    yield takeLatest(Actions.REGISTER, register);
}

export default function* submit() {
    yield all([
        fork(watchRegister),
    ])
}