import { all, fork } from "redux-saga/effects";
import getCompoundList from "./getCompoundList";
// import getFeederServices from "./getFeederServices";
import payVehicleCompound from "./payVehicleCompound";
import getAssessmentTax from "./getAssessmentTax";
import payAssessmentTax from "./payAssessmentTax";
import getLandTax from "./getLandTax";
// import payLandTax from "./payLandTax";
import getPaipList from "./getPaipList";
import payPaip from "./payPaip";
import getOtherCompoundList from "./getOtherCompoundList";
import payOtherCompound from "./payOtherCompound";

export default function* home() {
  yield all([
    fork(getCompoundList),
    // fork(getFeederServices),
    fork(payVehicleCompound),
    fork(getAssessmentTax),
    fork(payAssessmentTax),
    fork(getLandTax),
    // fork(payLandTax),
    fork(getPaipList),
    fork(payPaip),
    fork(getOtherCompoundList),
    fork(payOtherCompound)
  ]);
}
