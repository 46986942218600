export const NAME = 'AUTH'

export const LOGOUT = `${NAME}/LOGOUT`;
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`;
export const LOGOUT_FAIL = `${NAME}/LOGOUT_FAIL`;
export const RESET_LOGOUT = `${NAME}/RESET_LOGOUT`;

export const getLogout = store => store[NAME].logout;

export const logout = () => ({
    type: LOGOUT
});

export const logoutSuccess = data => ({
    type: LOGOUT_SUCCESS,
    data
});

export const logoutFail = error => ({
    type: LOGOUT_FAIL,
    error
});

export const resetLogout = () => ({
    type: RESET_LOGOUT,
})