import Actions from '../../actions';

const getDefaultState = () => ({ 
    isLoading: false,
    order: null,
    user: {
        "name": null,
        "phone": null,
        "email": null,
        "ic": null,
        "plate_number": null,
    },
    vehicleData: {
        "chassis": "",
        "engine": "",
        "model": "",
        "manufactured_year": ""
    },
    "vehicle_type": null,
    "needInsurance": "Yes",
    "insuranceData": null
});

function order(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.SUBMIT_ORDER:
            return {
                ...state,
                isLoading: true,
            };
        case Actions.SUBMIT_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                order: action.data
            };
        case Actions.SUBMIT_ORDER_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case Actions.SAVE_USER_INFO:
            return{
                ...state,
                user: action.data
            };
        case Actions.SAVE_TYPE:
            return{
                ...state,
                needInsurance: action.data,
                vehicleData: {
                    "chassis": "",
                    "engine": "",
                    "model": "",
                    "manufactured_year": ""
                },
            };
        case Actions.SAVE_VEHICLE_TYPE:
            return{
                ...state,
                vehicle_type: action.data
            };
        case Actions.SAVE_VEHICLE_DATA:
            return{
                ...state,
                vehicleData: action.data
            };
        case Actions.CHECK_EXPIRY_ROADTAX:
            return{
                ...state,
                isLoading: true
            };
        case Actions.CHECK_EXPIRY_ROADTAX_SUCCESS:
            return{
                ...state,
                isLoading: false,
                insuranceData: action.data
            };
        case Actions.CHECK_EXPIRY_ROADTAX_FAILED:
            return{
                ...state,
                isLoading: false
            };       
        default:
            return state;
    }
}

export default order;