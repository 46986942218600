import React from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

export default function Graph() {
    const data = [
        {
            name: '', usage: 180,
        },
        {
            name: 'Aug', usage: 100,
        },
        {
            name: 'Sept', usage: 300,
        },
        {
            name: 'Nov', usage: 200,
        },
        {
            name: 'Dec', usage: 278,
        },
        {
            name: 'Jan', usage: 189,
        },
    ];
    return (
        <>
            <AreaChart
                width={400}
                height={300}
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <defs>
                    <linearGradient id="colorusage" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="73%" stopColor="#1F90FA" stopOpacity={0.1}/>
                    <stop offset="95%" stopColor="#1F90FA" stopOpacity={0}/>
                    </linearGradient>
                </defs>                
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis hide={true}/>
                <Tooltip />
                <Area type="monotone" dataKey="usage" stroke="#1F90FA" strokeWidth={3} fillOpacity={1} fill="url(#colorusage)" />
            </AreaChart>
        </>
    )
}