import Actions from '../../actions';

const getDefaultState = () => ({ 
    result: null,
    isLoading: false,
    querying: null,
    history: null
});

function address(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }
    switch (action.type) {
        case Actions.GET_BILL:
            return {
                ...state,
                querying: action.data,
                result: null,
                isLoading: true,
            };   
        case Actions.GET_BILL_SUCCESS:
            return {
                ...state,
                result: action.data,
                isLoading: false,
            };
        case Actions.GET_BILL_FAILED:
            return {
                ...state,
                result: null,
                isLoading: false,
            }; 
        case Actions.GET_TRANSACTION_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.data
            };
        case Actions.GET_TRANSACTION_HISTORY_FAILED:
            return {
                ...state,
                history: action.data
            }                           
        default:
            return state;
    }
}

export default address;