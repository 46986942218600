import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: {} });

function userExistence(state, action) {
    if (typeof state === 'undefined') {
        return getDefaultState();
    }

    switch (action.type) {
        case Actions.CHECK_USER_EXISTENCE:
            return {
                isLoading: true,
                error: null,
                data: {},
            };
        case Actions.CHECK_USER_EXISTENCE_SUCCESS:
            return {
                isLoading: false,
                error: null,
                data: action.data,
            };
        case Actions.CHECK_USER_EXISTENCE_FAIL:
            return {
                isLoading: false,
                error: action.error,
                data: {},
            };
        case Actions.RESET_CHECK_USER_EXISTENCE:
            return getDefaultState();
        default:
            return state;
    }
}

export default userExistence;