import React from 'react';
import { Link, useParams } from 'react-router-dom';

const LandTaxPayment = ({location}) => {
    const [paymentActive, setPaymentActive] = React.useState(0)
    const [showDetails, setShowDetails] = React.useState(false)
    const {type} = useParams()

    const formatCurrency = d => {
        let t = d / 100
        return `${t.toFixed(2)}`
    }

    return (
        <div className="row">
            <div className="col-sm-4 col-md-8">
                {location.state.pbtid !== "ptg" && (
                    <div className="row">
                        <div className="col-auto">
                            <div className={`card border pointer ${paymentActive === 0 ? 'border-primary' : ''}`}  onClick={() => setPaymentActive(0)}>
                                <div className="p-2">
                                    Current Half
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className={`card border pointer ${paymentActive === 1 ? 'border-primary' : ''}`}  onClick={() => setPaymentActive(1)}>
                                <div className="p-2">
                                    Next Half
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="">Owner Name</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.pbtid == 'ptg' ? location.state.owner_name : location.state.name} />
                </div>
                <div className="form-group">
                    <label htmlFor="">{location.state.pbtid == 'ptg' ? "Title Number" : "Tax Number"}</label>
                    <input type="text" className="form-control" name="name" disabled value={location.state.pbtid == 'ptg' ? location.state.title_number : location.state.account_number} />
                </div>
                <div className="form-group">
                    <label htmlFor="">Address</label>
                    <textarea className="form-control" name="name" disabled>{location.state.address}</textarea>
                </div>
                <div className="form-group">
                    <label htmlFor="">Amount</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">RM</span>
                        </div>
                        <input type="number" className="form-control" name="name" disabled value={formatCurrency(location.state.pbtid === "ptg" ? location.state.payable_amount : paymentActive === 0 ? location.state.payment_amount_1 : location.state.payment_amount_2)} />
                    </div>
                </div>
                <a href="#">Open Details</a>
                <Link
                    to={location.state.pbtid == 'ptg' ? 
                    {
                        pathname: "/land_tax/pay",
                        state: {
                            paymentData: [
                                {
                                    title: "Name",
                                    value: location.state.owner_name
                                },
                                {
                                    title: "Title Number",
                                    value: location.state.title_number
                                },
                            ],
                            data: {
                                account_number: location.state.account_number,
                                amount: location.state.payable_amount,
                                pbtid: type
                            },
                            amount: (location.state.payable_amount/100).toFixed(2)
                        }
                    }
                    :
                    {
                        pathname: "/assessment_tax/pay",
                        state: {
                            paymentData: [
                                {
                                    title: "Name",
                                    value: location.state.name
                                },
                                {
                                    title: "Title Number",
                                    value: location.state.account_number
                                },
                            ],
                            data: {
                                account_number: location.state.account_number,
                                checkdigit: location.state.checkdigit,
                                amount: paymentActive === 0 ? location.state.payment_amount_1 : location.state.payment_amount_2,
                                pbtid: type
                            },
                            amount: paymentActive === 0 ? (location.state.payment_amount_1/100).toFixed(2) : (location.state.payment_amount_2/100).toFixed(2)
                        }
                    }
                    }
                >
                <button className="btn btn-primary w-100 mt-4">Next</button>
                </Link>
            </div>
        </div>
    )

    // return (
    //     <? className="land-payment-form">
    //         <div className="form-wrapper">
    //             {location.state.pbtid !== "ptg" && (
    //                 <div className="radio-select-holder inline">
    //                     <div className={`radio-select hide-radio ${paymentActive === 0 ? 'active' : ''}`}>
    //                         <input type="radio" name="paymentPeriod" id="sixMonths" onClick={() => setPaymentActive(0)} />
    //                         <label htmlFor="sixMonths"> Current Half </label>
    //                     </div>

    //                     <div className={`radio-select hide-radio ${paymentActive === 1 ? 'active' : ''}`}>
    //                         <input type="radio" name="paymentPeriod" id="twelveMonths" onClick={() => setPaymentActive(1)} />
    //                         <label htmlFor="twelveMonths"> Next Half </label>
    //                     </div>
    //                 </div>
    //             )}


    //             <div className="input-holder">
    //                 <label htmlFor="name">Owner</label>
    //                 <input type="text" placeholder="Owner name" name="ownerName" value={location.state.pbtid == 'ptg' ? location.state.owner_name : location.state.name} disabled />
    //             </div>
    //             <div className="input-holder">
    //                 <label htmlFor="name">Tax Number</label>
    //                 <input type="text" placeholder="Tax Number" name="taxNumber" value={location.state.pbtid == 'ptg' ? location.state.title_number : location.state.account_number} disabled />
    //             </div>
    //             <div className="input-holder">
    //                 <label htmlFor="name">Address</label>
    //                 <textarea placeholder="Address" name="address" disabled>{location.state.address}</textarea>
    //             </div>
    //             <div className="input-holder data-display">
    //                 <label htmlFor="name">Pay Amount</label>
    //                 <input type="text" placeholder="" name="paymentAmount" value={formatCurrency(location.state.pbtid === "ptg" ? location.state.payable_amount : paymentActive === 0 ? location.state.payment_amount_1 : location.state.payment_amount_2)} disabled />
    //             </div>

    //             <p
    //                 className="show-payment-details"
    //                 onClick={() => setShowDetails(!showDetails)}
    //             >{`${showDetails ? 'Close' : 'Open'} Details`}</p>

    //             {showDetails && (
    //                 <div className="payment-details-wrap">
    //                     <table className="payment-table">
    //                         {location.state.pbtid !== "ptg" && (
    //                             <>
    //                                 <tr>
    //                                     {/* <td>Pending Payment</td> */}
    //                                     <td>Current Amount</td>
    //                                     <td>{formatCurrency(location.state.current_amount)}</td>
    //                                 </tr>
    //                                 <tr>
    //                                     {/* <td>First Half of the Year</td> */}
    //                                     <td>Outstanding</td>
    //                                     <td>{formatCurrency(location.state.outstanding_amount)}</td>
    //                                 </tr>
    //                                 <tr>
    //                                     {/* <td>Second Half of the Year</td> */}
    //                                     <td>Late Charges</td>
    //                                     <td>{formatCurrency(location.state.late_charges)}</td>
    //                                 </tr>
    //                                 <tr>
    //                                     {/* <td>Additional Tax</td> */}
    //                                     <td>Warrant</td>
    //                                     <td>{formatCurrency(location.state.warrant_charges)}</td>
    //                                 </tr>
    //                                 <tr className="bold-text">
    //                                     <td>Payable Amount</td>
    //                                     <td>{formatCurrency(paymentActive === 0 ? location.state.payment_amount_1 : location.state.payment_amount_2)}</td>
    //                                 </tr>
    //                             </>
    //                         // <tr>
    //                         //     <td>Late Fine</td>
    //                         //     <td>0</td>
    //                         // </tr>
    //                         )}
    //                         {location.state.pbtid === "ptg" && (
    //                             <tr>
    //                                 <td>Current Tax</td>
    //                                 <td>{formatCurrency(location.state.current_tax)}</td>
    //                             </tr>
    //                         )}
    //                         <tr className="bold-text">
    //                             <td>Payment Status</td>
    //                             <td className={!location.state.is_paid ? 'error' : 'success'}>{!location.state.is_paid ? "Unpaid" : "Paid"}</td>
    //                         </tr>
    //                     </table>
    //                 </div>
    //             )}

    //             <div className="input-holder">
    //                 <Link
    //                     to={{
    //                         pathname: "/land_tax/pay",
    //                         state: {
    //                             paymentData: [
    //                                 {
    //                                     title: "Name",
    //                                     value: location.state.name
    //                                 },
    //                                 {
    //                                     title: "Title Number",
    //                                     value: location.state.account_number
    //                                 },
    //                             ],
    //                             data: {
    //                                 account_number: location.state.account_number,
    //                                 checkdigit: location.state.checkdigit,
    //                                 amount: paymentActive === 0 ? location.state.payment_amount_1 : location.state.payment_amount_2,
    //                                 pbtid: type
    //                             },
    //                             amount: paymentActive === 0 ? (location.state.payment_amount_1/100).toFixed(2) : (location.state.payment_amount_2/100).toFixed(2)
    //                         }
    //                     }}
    //                 >
    //                     <input type="submit" value="Next" />
    //                 </Link>
    //             </div>
    //         </div>
    //     </?>
    // );
};

export default LandTaxPayment;