import Actions from "../../actions";

const getDefaultState = () => ({
  isLoading: false,
  error: null,
  data: {},
  tempData: {}
});

function userProfile(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.GET_USER_PROFILE:
      return { isLoading: true, error: null, data: state.data, tempData: {} };
    case Actions.GET_USER_PROFILE_SUCCESS:
      return { isLoading: false, error: null, data: action.data, tempData: {} };
    case Actions.GET_USER_PROFILE_FAIL:
      return {
        isLoading: false,
        error: action.error,
        data: state.data,
        tempData: {}
      };

    case Actions.UPDATE_USER_PROFILE:
      return { isLoading: true, error: null, data: state.data, tempData: {} };

    case Actions.UPDATE_USER_PROFILE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        data: action.data,
        tempData: action.data
      };

    case Actions.UPDATE_USER_PROFILE_FAIL:
      return {
        isLoading: false,
        error: action.error,
        data: state.data,
        tempData: {}
      };

    case Actions.UPDATE_USER_EMAIL:
      return { isLoading: true, error: null, data: state.data, tempData: {} };

    case Actions.UPDATE_USER_EMAIL_SUCCESS:
      return { isLoading: false, error: null, data: action.data, tempData: {} };

    case Actions.UPDATE_USER_EMAIL_FAIL:
      return {
        isLoading: false,
        error: action.error,
        data: state.data,
        tempData: {}
      };
    case Actions.RESET_USER_PROFILE:
      return { isLoading: false, error: null, data: {}, tempData: {} };
    case Actions.CLEAR_USER_PROFILE:
      return getDefaultState();
    default:
      return state;
  }
}

export default userProfile;
