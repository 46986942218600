import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* purchaseProduct({ data, callback, callbackError }) {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.purchaseProduct, data, headers);

    console.log('response', response);

    console.log('error', error);
    
    if (response) {
        yield put(Actions.purchaseProductSuccess(response.data.redirectUrl));
        callback && callback(response.data);
    } else {
        yield put(Actions.purchaseProductFailed());

        if(error.response){
            callbackError && callbackError(error.response.data.message);
        } else {
            callbackError && callbackError('Please try again later');
        }
    }
}

function* validateProduct({ data, callback, callbackError }) {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.validateProduct, data, headers);
    
    if (response) {
        yield put(Actions.validateProductSuccess(response.data.status));
        callback && callback(response.data.status);
    } else {
        yield put(Actions.validateProductFailed());

        if(error.response){
            callbackError && callbackError(error.response.data.status);
        } else {
            callbackError && callbackError('Please try again later');
        }
    }
}


function* watch() {
    yield takeLatest(Actions.PURCHASE_PRODUCT, purchaseProduct);
    yield takeLatest(Actions.VALIDATE_PRODUCT, validateProduct);
}

export default function* purchase() {
    yield all([
        fork(watch)
    ])
}