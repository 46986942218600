export const NAME = "PAYMENT";

export const VERIFY_PAYMENT = `${NAME}/VERIFY_PAYMENT`;
export const VERIFY_PAYMENT_SUCCESS = `${NAME}/VERIFY_PAYMENT_SUCCESS`;
export const VERIFY_PAYMENT_FAIL = `${NAME}/VERIFY_PAYMENT_FAIL`;
export const RESET_VERIFY_PAYMENT = `${NAME}/RESET_VERIFY_PAYMENT`;

export const verifyPayment = data => ({
  type: VERIFY_PAYMENT,
  data
});

export const verifyPaymentSuccess = data => ({
  type: VERIFY_PAYMENT_SUCCESS,
  data
});

export const verifyPaymentFail = error => ({
  type: VERIFY_PAYMENT_FAIL,
  error
});

export const resetVerifyPayment = () => ({
  type: RESET_VERIFY_PAYMENT
});
