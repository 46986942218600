export const NAME = "FEEDERS";

export const GET_ASSESSMENT_TAX = `${NAME}/GET_ASSESSMENT_TAX`;
export const GET_ASSESSMENT_TAX_SUCCESS = `${NAME}/GET_ASSESSMENT_TAX_SUCCESS`;
export const GET_ASSESSMENT_TAX_FAIL = `${NAME}/GET_ASSESSMENT_TAX_FAIL`;
export const RESET_GET_ASSESSMENT_TAX = `${NAME}/RESET_GET_ASSESSMENT_TAX`;

export const getAssessmentTaxData = store => store[NAME].getAssessmentTax;

export const getAssessmentTax = data => ({
  type: GET_ASSESSMENT_TAX,
  data
});

export const getAssessmentTaxSuccess = data => ({
  type: GET_ASSESSMENT_TAX_SUCCESS,
  data
});

export const getAssessmentTaxFail = error => ({
  type: GET_ASSESSMENT_TAX_FAIL,
  error
});

export const resetGetAssessmentTax = () => ({
  type: RESET_GET_ASSESSMENT_TAX
});
