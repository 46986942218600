export const NAME = "PROFILE";

export const UPDATE_SAVED_PAYMENT_METHODS = `${NAME}/UPDATE_SAVED_PAYMENT_METHODS`;
export const CLEAR_SAVED_PAYMENT_METHODS = `${NAME}/CLEAR_SAVED_PAYMENT_METHODS`;

export const getSavedPaymentMethodsData = store => store[NAME].paymentMethods;

export const updateSavedPaymentMethods = data => ({
  type: UPDATE_SAVED_PAYMENT_METHODS,
  data
});

export const clearSavedPaymentMethods = () => ({
  type: CLEAR_SAVED_PAYMENT_METHODS
});