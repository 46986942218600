import { all, fork } from "redux-saga/effects";

import auth from './auth';
import profile from './profile';
import payment from './payment';
import iimmpact from './iimmpact';
import favourite from './favourite';
import history from "./history";
import zakat from './zakat';
import vehicle from './vehicle';
import feeders from "./feeders";
import education from "./education";
import manual from './manual';
import h from "./home"
// import parking from './parking';
// import services from './services';
// import dashboard from "./dashboard";
// import notification from "./notification";
// import billing from './billing';
// import bus from "./bus";
// import feedback from './feedback';
// import language from './language';

export default function* home() {
    yield all([
        fork(auth),
        fork(profile),
        fork(payment),
        fork(iimmpact),
        fork(favourite),
        fork(history),
        fork(zakat),
        fork(vehicle),
        fork(feeders),
        fork(education),
        fork(manual),
        fork(h)
        // fork(parking),
        // fork(services),
        // fork(dashboard),
        // fork(notification),
        // fork(billing),
        // fork(bus),
        // fork(feedback),
        // fork(language)
    ]);
}