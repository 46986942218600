import { takeLatest, call, all, fork, put, select, take } from 'redux-saga/effects';
import Actions from "../../actions";
import * as api from '../../api';

function* otp({ data }) {
    var headers = {
        'Content-Type': 'application/json',
        // 'Accept': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS'
    }

    const formData = new FormData();
    formData.append('phone', data.phone);
    formData.append('otp', data.otp);

    const { response, error } = yield call(api.otp, formData, headers);
    if (response) {
        yield put(Actions.otpSuccess(response.data));
    } else {
        yield put(Actions.otpFail(error.response.data));
    }
}

function* watchOtp() {
    yield takeLatest(Actions.OTP, otp);
}

export default function* submit() {
    yield all([
        fork(watchOtp)
    ])
}