export const NAME = "PAYMENT";

export const SET_DEFAULT_PAYMENT_METHOD = `${NAME}/SET_DEFAULT_PAYMENT_METHOD`;
export const SET_DEFAULT_PAYMENT_METHOD_SUCCESS = `${NAME}/SET_DEFAULT_PAYMENT_METHOD_SUCCESS`;
export const SET_DEFAULT_PAYMENT_METHOD_FAIL = `${NAME}/SET_DEFAULT_PAYMENT_METHOD_FAIL`;
export const RESET_SET_DEFAULT_PAYMENT_METHOD = `${NAME}/RESET_SET_DEFAULT_PAYMENT_METHOD`;

export const getSetDefaultPaymentMethodData = store => store[NAME].setDefaultPaymentMethod;

export const setDefaultPaymentMethod = data => ({
  type: SET_DEFAULT_PAYMENT_METHOD,
  data
});

export const setDefaultPaymentMethodSuccess = data => ({
  type: SET_DEFAULT_PAYMENT_METHOD_SUCCESS,
  data
});

export const setDefaultPaymentMethodFail = error => ({
  type: SET_DEFAULT_PAYMENT_METHOD_FAIL,
  error
});

export const resetSetDefaultPaymentMethod = () => ({
  type: RESET_SET_DEFAULT_PAYMENT_METHOD
});