
import {all, fork, takeLatest, call,select, put} from 'redux-saga/effects';
import Action from '../../actions';
import * as api from '../../api';


const getSessionData = store => store.PROFILE.userSession;

function* postAddFavourite({data, callback}) {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }
    const { response, error } = yield call(api.saveFavourite, data.slug, headers, data, data.category);
    
    if (response) {

        yield put({
            type: Action.ADD_FAVOURITE_SUCCESS,
            payload: data,
            category: data.category

          });

          callback && callback();

          yield put({
              type: Action.FETCH_FAVOURITE_LIST,
              slug: data.slug,
              category: data.category
          })
    } else {
        if(error){
           
        }
    }

}

function* fetchFavourites({slug, category}) {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.fetchFavourites, slug, headers, category);

    if (response) {

        yield put({
            type: Action.FETCH_FAVOURITE_LIST_SUCCESS,
            payload: {
                slug,
                category,
                data: response.data.data.fields
            }
          });

    } else {
        if(error){
           
        }
    }
};

function* deleteFavourites({slug, name, category}) {
    

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.deleteFavourites, slug, name, headers, category);

    if (response) {
        yield put({
            type: Action.DELETE_FAVOURITE_LIST_SUCCESS,
            payload: {
                name,
                slug, 
                category
            }
          });

    } else {
        if(error){
           
        }
    }

}

function* watchFavourite() {
    yield takeLatest(Action.ADD_FAVOURITE, postAddFavourite );
};

function* watchFetchFavourites() {
    yield takeLatest(Action.FETCH_FAVOURITE_LIST, fetchFavourites)

}


function* watchDeleteFavourites() {
    yield takeLatest(Action.DELETE_FAVOURITE_LIST, deleteFavourites)

}

export default function* favourite() {
    yield all([
        fork(watchFavourite),
        fork(watchFetchFavourites),
        fork(watchDeleteFavourites)
    ])
}