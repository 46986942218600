import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getPrepaidList() {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.getPrepaidList, headers);

    console.log('response', response);

    console.log('error', error);
    
    if (response) {
        yield put(Actions.getPrepaidListSuccess(response.data.data));
    } else {
        yield put(Actions.getPrepaidListFailed());
    }
}

function* watch() {
    yield takeLatest(Actions.GET_PREPAID_LIST, getPrepaidList);
}

export default function* search() {
    yield all([
        fork(watch)
    ])
}