// import { API_URL } from "utils/Config";
import axios from "axios";
import moment from "moment"

export const configureInterceptor = () => {};

// general token
const baseToken = "QzIhhBBE3HBws/rLiKCYqPOmlzd0IHKC";

export const getHeader = () => {
  return {
    Authorization: `Bearer ${baseToken}`,
    "Content-type": "application/json"
  };
};

var mainUrl

const stagingUrl = "https://staging.gox-api.phgo.xyz";
const prodUrl = "https://gox-api.pahanggo.com";

export const getFullUrl = endpoint => {
  mainUrl = prodUrl;
  return mainUrl + "/" + endpoint;
};

const fetchApi = (method, endpoint, params, headers, type = null) =>
  axios({
    method,
    headers: headers !== undefined ? headers : getHeader(),
    url: getFullUrl(endpoint),
    params: params,
    timeout: 25000,
    responseType: type ? 'blob' : '',
  })
    .then(response => {
        if (type) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pahanggo-payment-statement-${moment().format('DD-MM-YYYY_h_mm_ss_a')}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
        return {response}
    })
    .catch(error => ({ error }));

export default fetchApi;
