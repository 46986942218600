export const NAME = "PAYMENT";

export const PAYMENT_SERVICE = `${NAME}/PAYMENT_SERVICE`;
export const PAYMENT_SERVICE_SUCCESS = `${NAME}/PAYMENT_SERVICE_SUCCESS`;
export const PAYMENT_SERVICE_FAIL = `${NAME}/PAYMENT_SERVICE_FAIL`;
export const RESET_PAYMENT_SERVICE = `${NAME}/RESET_PAYMENT_SERVICE`;

export const getPaymentServiceData = store => store[NAME].paymentService;

export const paymentService = data => ({
  type: PAYMENT_SERVICE,
  data
});

export const paymentServiceSuccess = data => ({
  type: PAYMENT_SERVICE_SUCCESS,
  data
});

export const paymentServiceFail = error => ({
  type: PAYMENT_SERVICE_FAIL,
  error
});

export const resetPaymentService = () => ({
  type: RESET_PAYMENT_SERVICE
});
