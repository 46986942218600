import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* getLandTax({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.getLandTax, headers, data.paramString);
    if (response) {
        yield put(Actions.getLandTaxSuccess(response.data.data));
    } else {
        yield put(Actions.getLandTaxFail(error.response.data));
    }
}

function* watchGetLandTax() {
    yield takeLatest(Actions.GET_LAND_TAX, getLandTax);
}

export default function* submit() {
    yield all([
        fork(watchGetLandTax)
    ])
}