import React from "react";
import { Link, Redirect } from "react-router-dom";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import Actions from "../actions";

import TextInput from "../components/TextInput.js";
import LoadingOverlay from "../components/LoadingOverlay"

const UserAuth = ({
  change,
  handleSubmit,
  history,
  location,
  handleLogin,
  handleOTPSwitch,
}) => {
  const [mode, setMode] = React.useState(0);
  const [emailCheck, setEmailCheck] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [authPass, setAuthPass] = React.useState(false);
  const [channel, setChannel] = React.useState("email");
  const [emailFilled, setEmailFilled] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [obj, setObj] = React.useState({});
  const [input, setInput] = React.useState({
    name: '',
    password: '',
    email: ''
  });
  const [inputError, setInputError] = React.useState({
    email: "",
    password: "",
  })
  const { isLoading, error, data } = useSelector(
    (state) => state.AUTH.userExistence
  );
  const login = useSelector((state) => state.AUTH.login);
  const dispatch = useDispatch();

  const handleAuth = () => {
    if (mode === 1) {
      var obj = {
        inputName: input.name,
        inputEmail: input.email,
        inputPassword: input.password
      }
      dispatch(Actions.checkUserExistence(obj));
      setValue(obj);
    }
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setInput(state => ({...state, [name]: value}))
  }

  React.useEffect(() => {
    if (!isLoading && !error && Object.getOwnPropertyNames(data).length != 0) {
      if (!data.existed) {
          if (channel === "social") {
            switchOTP(obj)
          } else {
            switchOTP(value);
          }
      } else {
          if (channel === "social") {
              dispatch(Actions.login(obj))
          }
      }
    }
  }, [isLoading, error, data]);

  React.useEffect(() => {
    if (
      !login.isLoading &&
      !login.error &&
      Object.getOwnPropertyNames(login.data).length != 0
    ) {
      history.push("/home");
      dispatch(Actions.resetLogin())
      dispatch(Actions.getUserProfile())
    } else if (!login.isLoading && login.error) {
      setInputError(state => ({...state, password: login.error.status}))
    }
  }, [login]);

  const sendLogin = () => {
    // if (!emailCheck) {
      // setEmailCheck(true);
    // } else {
      // console.log(d)
      setInputError({email: "", password: ""})
      if (!input.email && !input.password) {
        if (!input.email) {
          setInputError(state => ({...state, email: "This value is required."}))
        }

        if (!input.password) {
          setInputError(state => ({...state, password: "This value is required."}))
        }
      } else {
        var d = {};
        d['inputEmail'] = input.email
        d['inputPassword'] = input.password
        d["inputChannel"] = "email";
        dispatch(Actions.login(d));
      }
    // }
    // handleLogin(status);
  };

  const switchOTP = (d) => {
    handleOTPSwitch(d);
  };

  const responseGoogle = response => {
      setChannel("social")
      var data = {
          oauth_token: response.accessToken,
          inputName: response.profileObj.name,
          inputEmail: response.profileObj.email,
          inputChannel: "social"
      }
      setObj(data)
      dispatch(Actions.checkUserExistence({inputEmail: response.profileObj.email}))
  }

  const responseFacebook = res => {
    //   console.log(res)
    setChannel("social")
    var data = {
        oauth_token: res.accessToken,
        inputName: res.name,
        inputEmail: res.email,
        inputChannel: "social"
    }
    setObj(data)
    dispatch(Actions.checkUserExistence({inputEmail: res.email}))
  }

  // return (
  //   <div className="auth-wrap-inner">
  //     {mode === 0 ? (
  //       <div className="signin">
  //         <div className="auth-title">
  //           <p>Log into your account</p>
  //         </div>
  //         <div className="form-wrapper">
  //           <Field
  //             component={TextInput}
  //             type="text"
  //             label="none"
  //             name="inputEmail"
  //             placeholder="Email"
  //           />

  //           {emailCheck && (
  //             <Field
  //               component={TextInput}
  //               type={`${showPassword ? "text" : "password"}`}
  //               label="none"
  //               name="inputPassword"
  //               placeholder="Password"
  //               className="password"
  //             >
  //               <img
  //                 src={require(`../assets/images/${
  //                   showPassword ? "visable" : "not-visable"
  //                 }.png`)}
  //                 alt=""
  //                 onClick={() => setShowPassword(!showPassword)}
  //               />
  //             </Field>
  //           )}

  //           <div className="input-holder btn-holder">
  //             <input
  //               type="submit"
  //               value={`${emailCheck ? "Log In" : "Continue"}`}
  //               onClick={handleSubmit(sendLogin)}
  //             />
  //           </div>

  //           <div className="link-separator">
  //             <p>OR</p>
  //           </div>

  //           <div className="social-login-wrapper">
  //             <div className="button-holder">
  //               {/* <div className="fb-login social-login-button"> */}
  //                 {/* <img src={require('../assets/images/facebook-white.png')} alt="" /> */}
  //                 {/* <p>CONNECT WITH FACEBOOK</p> */}
  //                 <FacebookLogin
  //                   appId="1051395621944646"
  //                   // autoLoad={true}
  //                   onClick={() => console.log("test")}
  //                   fields="name,email,picture"
  //                   render={renderProps => (
  //                       <button className="fb-login social-login-button" onClick={renderProps.onClick}>CONNECT WITH FACEBOOK</button>
  //                   )}
  //                   callback={responseFacebook} />
  //               {/* </div> */}
  //             </div>
  //             <div className="button-holder">
  //               {/* <div className="google-login social-login-button"> */}
  //                 {/* <img src={require('../assets/images/google-white.png')} alt="" /> */}
  //                 <GoogleLogin
  //                   clientId="743634830845-hbr33m8uq1d2dkel4pk4bjrc84u34h4k.apps.googleusercontent.com"
  //                   render={renderProps => (
  //                       <button className="google-login social-login-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>CONNECT WITH GOOGLE</button>
  //                   )}
  //                   buttonText="Login"
  //                   onSuccess={responseGoogle}
  //                   onFailure={responseGoogle}
  //                   cookiePolicy={'single_host_origin'}
  //                 />
  //               {/* </div> */}
  //             </div>
  //           </div>

  //           <div className="switch">
  //             <p>
  //               Don't have an account?{" "}
  //               <span className="switch-link" onClick={() => setMode(1)}>
  //                 Sign up
  //               </span>
  //             </p>
  //           </div>
  //           <p className="forgot-password">Forgot Password? </p>
  //         </div>
  //       </div>
  //     ) : (
  //       <div className="signup">
  //         <div className="auth-title">
  //           <p>Please sign up to continue</p>
  //         </div>
  //         <form onSubmit={handleSubmit(handleAuth)}>
  //           <div className="form-wrapper">
  //             <Field
  //               component={TextInput}
  //               type="text"
  //               label="none"
  //               name="inputName"
  //               placeholder="Name"
  //             />

  //             <Field
  //               component={TextInput}
  //               type="text"
  //               label="none"
  //               name="inputEmail"
  //               placeholder="Email Address"
  //             />

  //             <Field
  //               component={TextInput}
  //               type="password"
  //               label="none"
  //               name="inputPassword"
  //               placeholder="Password"
  //             />

  //             <div className="input-holder btn-holder">
  //               <input type="submit" value="Sign Up" />
  //             </div>

  //             <div className="link-separator">
  //               <p>OR</p>
  //             </div>

  //             <div className="social-login-wrapper">
  //               <div className="button-holder">
  //                 <div className="fb-login social-login-button">
  //                   {/* <img src={require('../assets/images/facebook-white.png')} alt="" /> */}
  //                   <p>CONNECT WITH FACEBOOK</p>
  //                 </div>
  //               </div>
  //               <div className="button-holder">
  //                 <div className="google-login social-login-button">
  //                   {/* <img src={require('../assets/images/google-white.png')} alt="" /> */}
  //                   <p>CONNECT WITH GOOGLE</p>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="switch">
  //               <p>
  //                 Already have an account?{" "}
  //                 <span className="switch-link" onClick={() => setMode(0)}>
  //                   Sign In
  //                 </span>
  //               </p>
  //             </div>
  //           </div>
  //         </form>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div className="col-md-4" id="content-body">
        {login.isLoading && <LoadingOverlay />}
      <div className="card">
      {mode === 0 && (
        <div className="p-4">
          <h5>Welcome back</h5>
          <p>
              <small className="text-muted">Log into your account</small>
          </p>
          {/* <Field 
          component={TextInput}
          label="Email"
          name="inputEmail"
          type="email"
          /> */}
          <div className="form-group">
            <label htmlFor="">Email</label>
            <input type="email" name="email" className={`form-control ${inputError.email ? 'is-invalid' : ''}`} value={input.email} onChange={handleChange} />
            {inputError.email && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
            <li class="parsley-required">{inputError.email}</li>
            </ul>}
          </div>
          <div className="form-group">
            <label htmlFor="">Password</label>
            <div className="input-group">
              <input type={showPassword ? 'text' : 'password'} name="password" className={`form-control ${inputError.password ? 'is-invalid' : ''}`} value={input.password} onChange={handleChange} />
              <div className="input-group-append">
                <button onClick={() => setShowPassword(state => !state)} className={`btn btn-outline-secondary ${showPassword ? 'bg-secondary' : ''}`}>{showPassword ? 'Hide' : 'Show'}</button>
              </div>
            </div>
            {inputError.password && <ul class="list-unstyled text-sm mt-1 text-muted filled" id="parsley-id-5">
            <li class="parsley-required">{inputError.password}</li>
            </ul>}
            <div class="my-3 text-right"><p class="text-muted mb-0 text-right pointer" onClick={() => setMode(2)}>Forgot password?</p></div>
          </div>
          {/* <Field 
          component={TextInput}
          label="Password"
          name="inputPassword"
          type="password"
          forgot={true}
          setMode={() => setMode(2)}
          /> */}
          <button type="submit" className="btn btn-primary mb-4" onClick={sendLogin}>Sign in</button>
          <div>Do not have an account? <span onClick={() => setMode(1)} className="text-primary pointer">Sign up</span></div>
          <div className="my-3 text-muted text-center">
            OR
          </div>
          <div>
            <FacebookLogin
            appId="1095413317318572"
            // autoLoad={true}
            onClick={() => console.log("test")}
            fields="name,email,picture"
            render={renderProps => (
              <button className="btn btn-block indigo text-white mb-2" onClick={renderProps.onClick}>
                <i className="float-left">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 264 512">
                    <path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" class=""></path>
                  </svg>
                </i>
                Sign in with Facebook
              </button>
            )}
            callback={responseFacebook} />
            <GoogleLogin
              clientId="133601413627-g6pi0orijee7l0rvr96em1hrfh7rn1b6.apps.googleusercontent.com"
              render={renderProps => (
                <button className="btn btn-block red text-white mb-2" onClick={renderProps.onClick}>
                  <i className="float-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 640 512">
                      <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" class=""></path>
                    </svg>
                  </i>
                  Sign in with Google
                </button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </div>
        </div>
      )}
      {mode === 1 && (
        <div className="p-4">
          <h5>Welcome to PahangGo</h5>
          <p>
              <small className="text-muted">Sign up with your social network</small>
          </p>
          <div>
            {/* <form> */}
              <div>
                <a href="#" className="btn btn-block indigo text-white mb-2">
                  <i className="float-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 264 512">
                      <path fill="currentColor" d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" class=""></path>
                    </svg>
                  </i>
                  Sign up with Facebook
                </a>
                <a href="#" className="btn btn-block red text-white mb-2">
                  <i className="float-left">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 640 512">
                      <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" class=""></path>
                    </svg>
                  </i>
                  Sign up with Google
                </a>
              </div>
              <div className="my-3 text-muted text-center">
                OR
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="name" value={input.name} onChange={handleChange} placeholder="Name" required />
              </div>
              <div className="form-group">
                <input type="email" className="form-control" name="email" value={input.email} onChange={handleChange} placeholder="Email Address" required />
              </div>
              <div className="form-group">
                <div className="input-group">
                  <input type={showPassword ? 'text' : 'password'} name="password" className={`form-control ${inputError.password ? 'is-invalid' : ''}`} value={input.password} placeholder="Password" onChange={handleChange} />
                  <div className="input-group-append">
                    <button onClick={() => setShowPassword(state => !state)} className={`btn btn-outline-secondary ${showPassword ? 'bg-secondary' : ''}`}>{showPassword ? 'Hide' : 'Show'}</button>
                  </div>
                </div>
              </div>
              <div className="mb-3 text-sm">
                <span className="text-muted">By clicking Sign Up, I agree to the</span> 
                <a href="#">Terms of service</a>
              </div>
              <button type="submit" className="btn btn-primary mb-4" onClick={handleAuth}>Sign Up</button>
              <div>Already have an account? <span onClick={() => setMode(0)} className="text-primary _600 pointer">Sign in</span></div>
            {/* </form> */}
          </div>
        </div>
      )}
      {mode === 2 && (
        <div className="p-4">
          <div>
            <h5>Forgot your password?</h5>
            <p className="text-muted my-3">
                Enter your email below and we'll send you a verification code to reset your password.
            </p>
          </div>
            <form className="py-5 my-md-5">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Email"/>
              </div>
              <button className="btn btn-primary btn-block">Send Link</button>
            </form>
            <div class="pt-1">
              Return to 
              <span class="text-primary font-weight-bold ml-1 pointer" onClick={() => setMode(0)}>Sign in</span>
            </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default reduxForm({
  form: "authForm",
  // validate
})(UserAuth);
