import React from 'react';

const PayDetails = ({location}) => {
    const [paymentType, setPaymentType] = React.useState(0)
    const [paymentSummary, setPaymentSummary] = React.useState(location.state)

    return (
        <div className="content pay">
            <h1 className="content-title">Check & Pay</h1>

            <div className="payment-superwrap">
                <div className="payment-choices-wrap">
                    <div className="payment-choices-row">
                        <div
                            className={`payment-choice ${paymentType === 0 ? 'active' : ''}`}
                            onClick={() => setPaymentType(0)}
                        >
                            <img src={require('../../assets/images/kuantan-logo.png')} alt="" />
                            <p>Online Banking</p>
                        </div>
                        <div
                            className={`payment-choice ${paymentType === 1 ? 'active' : ''}`}
                            onClick={() => setPaymentType(1)}
                        >
                            <img src={require('../../assets/images/kuantan-logo.png')} alt="" />
                            <p>Credit/Debit Card</p>
                        </div>
                        <div
                            className={`payment-choice ${paymentType === 2 ? 'active' : ''}`}
                            onClick={() => setPaymentType(2)}
                        >
                            <img src={require('../../assets/images/kuantan-logo.png')} alt="" />
                            <p>eWallet</p>
                        </div>
                    </div>
                    <div className="payment-form">
                        {paymentType === 0 && (
                            <>
                                <p className="payment-type-title">Perbankan online</p>
                                <div className="form-wrapper">
                                    <div className="input-holder no-margin">
                                        <input type="submit" value="PAY NOW" />
                                    </div>
                                </div>
                            </>
                        )}
                        {paymentType === 1 && (
                            <>
                                <p className="payment-type-title">Credit / Debit Card</p>
                                <div className="form-wrapper">
                                    <div className="input-holder">
                                        <input type="text" placeholder="Name on card" name="cardName" value={""} />
                                    </div>
                                    <div className="input-holder">
                                        <input type="text" placeholder="Name" name="cardNumber" value={""} />
                                    </div>

                                    <div className="side-by-side">
                                        <div className="input-holder">
                                            <input type="text" placeholder="MM/YY" name="expiryDate" value={""} />
                                        </div>
                                        <div className="input-holder">
                                            <input type="text" placeholder="CVC" name="cvc" value={""} />
                                        </div>
                                    </div>

                                    <div className="input-holder no-margin">
                                        <input type="submit" value="PAY" />
                                    </div>
                                </div>
                            </>
                        )}
                        {paymentType === 2 && (
                            <>
                                <p className="payment-type-title">eWallet</p>
                                <div className="form-wrapper">
                                    <div className="input-holder no-margin">
                                        <input type="submit" value="PAY NOW" />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="payment-data">
                    <p className="title">Summary</p>
                    <table className="payment-table">
                        {
                            paymentSummary.paymentData.map(i => (
                                <tr>
                                    <td>{i.title}</td>
                                    <td>{i.value}</td>
                                </tr>
                            ))
                        }
                        <tr className="border-top">
                            <td>Payment Amount</td>
                            <td>{paymentSummary.totalAmount}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PayDetails;