import { combineReducers } from "redux";

import getCompoundList from "./getCompoundList";
// import getFeederServices from "./getFeederServices";
import payVehicleCompound from "./payVehicleCompound";
import getAssessmentTax from "./getAssessmentTax";
import payAssessmentTax from "./payAssessmentTax";
import getLandTax from "./getLandTax";
// import payLandTax from "./payLandTax";
import getPaipList from "./getPaipList";
import payPaip from "./payPaip";
import getOtherCompoundList from "./getOtherCompoundList";
import payOtherCompound from "./payOtherCompound";

export default combineReducers({
  getCompoundList,
  // getFeederServices,
  payVehicleCompound,
  getAssessmentTax,
  payAssessmentTax,
  getLandTax,
  // payLandTax,
  getPaipList,
  payPaip,
  getOtherCompoundList,
  payOtherCompound
});
