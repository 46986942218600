import React from 'react';

const BillPay = props => {
    const [paymentType, setPaymentType] = React.useState(0)

    return (
        <div className="content pay">
            <h1 className="content-title">Postpaid</h1>

            <div className="payment-superwrap">
                <div className="payment-choices-wrap">
                    <div className="payment-choices-row">
                        <div
                            className={`payment-choice ${paymentType === 0 ? 'active' : ''}`}
                            onClick={() => setPaymentType(0)}
                        >
                            <img src={require('../../assets/images/bank.png')} alt="" />
                            <p>Online Banking</p>
                        </div>
                        <div
                            className={`payment-choice ${paymentType === 1 ? 'active' : ''}`}
                            onClick={() => setPaymentType(1)}
                        >
                            <img src={require('../../assets/images/creditcard.png')} alt="" />
                            <p>Credit/Debit Card</p>
                        </div>
                        <div
                            className={`payment-choice ${paymentType === 2 ? 'active' : ''}`}
                            onClick={() => setPaymentType(2)}
                        >
                            <img src={require('../../assets/images/ewallet.png')} alt="" />
                            <p>eWallet</p>
                        </div>
                    </div>
                    <div className="payment-form">
                        {paymentType === 0 && (
                            <>
                                <p className="payment-type-title">Perbankan online</p>
                                <div className="form-wrapper">
                                    <div className="input-holder no-margin">
                                        <input type="submit" value="PAY NOW" />
                                    </div>
                                </div>
                            </>
                        )}
                        {paymentType === 1 && (
                            <>
                                <p className="payment-type-title">Credit / Debit Card</p>
                                <div className="form-wrapper">
                                    <div className="input-holder">
                                        <input type="text" placeholder="Name on card" name="cardName" value={""} />
                                    </div>
                                    <div className="input-holder">
                                        <input type="text" placeholder="Name" name="cardNumber" value={""} />
                                    </div>

                                    <div className="side-by-side">
                                        <div className="input-holder">
                                            <input type="text" placeholder="MM/YY" name="expiryDate" value={""} />
                                        </div>
                                        <div className="input-holder">
                                            <input type="text" placeholder="CVC" name="cvc" value={""} />
                                        </div>
                                    </div>

                                    <div className="input-holder no-margin">
                                        <input type="submit" value="PAY" />
                                    </div>
                                </div>
                            </>
                        )}
                        {paymentType === 2 && (
                            <>
                                <p className="payment-type-title">eWallet</p>
                                <div className="form-wrapper">
                                    <div className="input-holder no-margin">
                                        <input type="submit" value="PAY NOW" />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="payment-data prepaid">
                    <p className="title">Summary</p>
                    <table className="payment-table">
                        <tr>
                            <td><b>PAY</b> {props.location.state.card} Astro</td>
                            <td>RM {props.location.state.amount.split(".")[0]}.{props.location.state.amount.split(".")[1] || '00'}</td>
                        </tr>
                        <tr>
                            <td className="phone-no">{props.location.state.number}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr className="border-top">
                            <td>Payment Amount</td>
                            <td>RM {props.location.state.amount.split(".")[0]}.{props.location.state.amount.split(".")[1] || '00'}</td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default BillPay;