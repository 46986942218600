import React from 'react';
import { Link } from 'react-router-dom';

const PayList = () => {
    const firstRow_entities = [
        {
            pbttitle: "Quit Rent",
            pbticon: "ptg",
            pbtid: "ptg",
            directory: "PTGForm",
            // title: "Quit Rent",
            // icon: images.ptg,
            opacity: 1,
            size: 50,
            enable: true
        },
        {
            pbttitle: "Carian Rasmi",
            pbticon: "ptg",
            pbtid: "ptg",
            directory: "PTGCarian",
            // icon: images.ptg,
            opacity: 1,
            size: 50,
            enable: true
        }
    ];

    const payment_entities = [
    {
        pbttitle: "Quit Rent",
        pbticon: "ptg",
        pbtid: "ptg",            
        directory: "PTGForm",
        icon: require('../../assets/images/ptg-logo.png'),
        opacity: 1,
        size: 50,
        cukai: false,
        manual: false,
        parking: false,
        pelbagai: false,
        enable: true
    },
    {
        pbttitle: "Kuantan",
        pbticon: "mpk",
        pbtid: "mpk",
        directory: "PayPahangPaymentType",
        icon: require('../../assets/images/kuantan-logo.png'),
        opacity: 1,
        enable: true,
        cukai: true,
        parking: true,
        manual: false,
        pelbagai: true
    },
    {
        pbttitle: "Bentong",
        pbticon: "mpb",
        pbtid: "mpb",
        directory: "PayPahangPaymentType",
        icon: require('../../assets/images/bentong-logo.png'),
        opacity: 1,
        enable: true,
        manual: false,
        cukai: true,
        parking: true,
        pelbagai: true
    },
    {
        pbttitle: "Raub",
        pbticon: "mdra",
        pbtid: "mdra",
        directory: "PayPahangPaymentType",
        icon: require('../../assets/images/raub-logo.jpg'),
        opacity: 1,
        manual: false,
        enable: true,
        cukai: true,
        parking: true,
        pelbagai: true
    },
    {
        pbttitle: "Cameron Highlands",
        pbticon: "mdch",
        pbtid: "mdch",
        directory: "PayPahangPaymentType",
        icon: require('../../assets/images/cameron-highlands-logo.png'),
        opacity: 1,
        size: 60,
        enable: true,
        manual: false,
        cukai: true,
        parking: true,
        pelbagai: true
    }
    
    //
    ];
      
    const payment_paid_entities = [
    {
        pbttitle: "Bera",
        pbticon: "mdb",
        pbtid: "mdb",
        directory: "ManualSelectType",
        icon: require('../../assets/images/bera-logo.png'),
        opacity: 1,
        cukai: true,
        parking: false,
        manual: true,
        pelbagai: true,
        enable: true
    },
    {
        pbttitle: "Maran",
        pbticon: "mdm",
        pbtid: "mdm",
        directory: "ManualSelectType",
        icon: require('../../assets/images/maran-logo.png'),
        opacity: 1,
        cukai: true,
        parking: false,
        manual: true,
        pelbagai: true,
        enable: true
    },
    {
        pbttitle: "Kuala Lipis",
        pbticon: "mdl",
        pbtid: "mdl",
        directory: "ManualSelectType",
        icon: require('../../assets/images/kuala-lipis-logo.png'),
        opacity: 1,
        cukai: true,
        parking: true,
        manual: true,
        pelbagai: true,
        enable: true
    },
    {
        pbttitle: "Rompin",
        pbticon: "mdro",
        pbtid: "mdro",
        directory: "ManualSelectType",
        icon: require('../../assets/images/rompin-logo.png'),
        opacity: 1,
        cukai: true,
        parking: false,
        pelbagai: true,
        manual: true,
        enable: true
    },
    {
        pbttitle: "Jerantut",
        pbticon: "mdj",
        pbtid: "mdj",
        directory: "ManualSelectType",
        icon: require('../../assets/images/jerantut-logo.png'),
        opacity: 1,
        cukai: true,
        parking: false,
        pelbagai: true,
        manual: true,
        enable: true
    },
    {
        pbttitle: "Pekan",
        pbticon: "mdp",
        pbtid: "mdp",
        directory: "ManualSelectType",
        icon: require('../../assets/images/pekan-logo.png'),
        opacity: 1,
        cukai: true,
        manual: true,
        parking: false,
        pelbagai: true,
        enable: true
    },
    {
        pbttitle: "Temerloh",
        pbticon: "mpt",
        pbtid: "mpt",
        directory: "ManualSelectType",
        icon: require('../../assets/images/temerloh-logo.png'),
        opacity: 1,
        manual: true,
        cukai: true,
        parking: false,
        pelbagai: true,
        enable: true
    }
    ];

    // return (
    //     <div className="content pay">
    //         <div className="tab-container">
    //             <h1 className="content-title">Check & Pay</h1>

    //             {payment_entities.map(entity => (
    //                 <Link to={{pathname: `/pay/${entity.pbtid}`, state: entity}}>
    //                     <div className="tab-child">
    //                         {/* <div className="notification">1</div> */}
    //                         <div className="image-holder">
    //                             <img src={entity.icon} alt=""/>
    //                         </div>
    //                         <p className="tab-title">{entity.pbttitle}</p>
    //                     </div>
    //                 </Link>
    //             ))}
                
    //             {/* <Link to={'/pay/ptg'}>
    //                 <div className="tab-child">
    //                     <div className="notification">1</div>
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/ptg-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Quit Rent </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/kuantan'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/kuantan-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Kuantan </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/bentong'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/bentong-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Bentong </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/raub'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/raub-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Raub </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/cameron-highlands'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/cameron-highlands-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Cameron Highlands </p>
    //                 </div>
    //             </Link> */}
    //         </div>
    //         <div className="tab-container">
    //             <h1 className="content-title full-margin">Pay</h1>

    //             {payment_paid_entities.map(entity => (
    //                 <Link to={{pathname: `/pay/${entity.pbtid}`, state: entity}}>
    //                     <div className="tab-child">
    //                         <div className="image-holder">
    //                             <img src={entity.icon} alt=""/>
    //                         </div>
    //                         <p className="tab-title">{entity.pbttitle}</p>
    //                     </div>
    //                 </Link>
    //             ))}
                
    //             {/* <Link to={'/pay/bera'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/bera-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Bera </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/maran'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/maran-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Maran </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/kuala-lipis'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/kuala-lipis-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Kuala Lipis </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/rompin'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/rompin-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Rompin </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/jerantut'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/jerantut-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Jerantut </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/pekan'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/pekan-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Pekan </p>
    //                 </div>
    //             </Link>

    //             <Link to={'/pay/temerloh'}>
    //                 <div className="tab-child">
    //                     <div className="image-holder">
    //                         <img src={require('../../assets/images/temerloh-logo.png')} alt=""/>
    //                     </div>
    //                     <p className="tab-title">Temerloh </p>
    //                 </div>
    //             </Link> */}
    //         </div>
    //     </div>
    // );

    return (
        <div id="content" className="flex">
            <div className="h-100 d-flex padding">
                <div className="block flex mb-0">
                <div className="page-title padding pb-0 ">
                    <h2 className="text-md mb-0">Check & Pay</h2>
                </div>
                <div className="padding">
                    <div className="row row-sm">
                        {payment_entities.map(entity => (
                            <div className="col-md-2">
                                <Link to={{pathname: `/pay/${entity.pbtid}`, state: entity}}>
                                    <div className="row row-sm">
                                        <div className="col-lg-12 col-sm-6">
                                            <div className="card border no-shadow interactive">
                                                <div className="p-4 text-center">
                                                    <p className="text-muted"><strong>{entity.pbttitle}</strong></p>
                                                    <img height={entity.pbtid === "ptg" ? 40 : 50} style={entity.pbtid === "ptg" ? {marginTop: "10px"} : {}} src={entity.icon} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="page-title padding pb-0 ">
                    <h2 className="text-md mb-0">Pay</h2>
                </div>
                <div className="padding">
                    <div className="row row-sm">
                        {payment_paid_entities.map(entity => (
                            <div className="col-md-2">
                                <Link to={{pathname: `/pay/${entity.pbtid}`, state: entity}}>
                                    <div className="row row-sm">
                                        <div className="col-lg-12 col-sm-6">
                                            <div className="card border no-shadow interactive">
                                                <div className="p-4 text-center">
                                                    <p className="text-muted"><strong>{entity.pbttitle}</strong></p>
                                                    <img height={50} src={entity.icon} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
};

export default PayList;