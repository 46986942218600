import Actions from "../../actions";

const getDefaultState = () => ({ isLoading: false, error: null, data: [] });

function getPaymentMethods(state, action) {
  if (typeof state === "undefined") {
    return getDefaultState();
  }

  switch (action.type) {
    case Actions.GET_PAYMENT_METHODS:
      return { isLoading: true, error: null, data: [] };
    case Actions.GET_PAYMENT_METHODS_SUCCESS:
      return { isLoading: false, error: null, data: action.data };
    case Actions.GET_PAYMENT_METHODS_FAIL:
      return { isLoading: false, error: action.error, data: [] };
    case Actions.RESET_GET_PAYMENT_METHODS:
      return getDefaultState();
    default:
      return state;
  }
}

export default getPaymentMethods;
