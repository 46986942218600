import { all, fork } from "redux-saga/effects";
import history from "./history";
import exportHistory from "./export"
// import deleteHistory from "./deleteHistory";

export default function* home() {
  yield all([
    fork(history), 
    fork(exportHistory)
    // fork(deleteHistory)
  ]);
}
