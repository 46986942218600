import React from 'react';
import AccountStats from './AccountStats'

const PaipAccount = () => {
    const [activeTab, setActiveTab] = React.useState(0)
    
    return (
        <div className="content paip">
            <div className="title-row">
                <h1 className="content-title">PAIP</h1>

                <div className="switch-buttons-row">
                    <div 
                        className={activeTab == 0 ? 'switch-buttons active' : 'switch-buttons'}
                        onClick={() => setActiveTab(0)}
                    >
                        BILL
                    </div>
                    <div 
                        className={activeTab == 1 ? 'switch-buttons active' : 'switch-buttons'}
                        onClick={() => setActiveTab(1)}
                    >
                        ADUAN
                    </div>
                    <div 
                        className={activeTab == 2 ? 'switch-buttons active' : 'switch-buttons'}
                        onClick={() => setActiveTab(2)}
                    >
                        ACCOUNT STATEMENT
                    </div>
                </div>
            </div>

            {activeTab === 0 && (
                <AccountStats></AccountStats>
            )}
        </div>
    );
};

export default PaipAccount;