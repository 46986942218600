import auth from "./auth";
import profile from "./profile";
import payment from "./payment"
import iimmpact from './iimmpact';
import favourite from './favourite';
import history from "./history";
import zakat from "./zakat"
import vehicle from './vehicle';
import feeders from "./feeders";
import education from './education';
import manual from './manual';
import * as home from "./home"
// import parking from "./parking";
// import dashboard from "./dashboard";
// import notification from "./notification";
// import services from './services';
// import billing from './billing';
// import bus from './bus';
// import language from './language';
// import feedback from './feedback';

export default {
  ...auth,
  ...profile,
  ...payment,
  ...iimmpact,
  ...favourite,
  ...history,
  ...zakat,
  ...vehicle,
  ...feeders,
  ...education,
  ...manual,
  ...home
//   ...parking,
//   ...dashboard,
//   ...notification,
//   ...services,
  // ...billing,
//   ...bus,
//   ...language,
//   ...feedback
};