export const NAME = "PAYMENT";

export const ADD_PAYMENT_METHOD = `${NAME}/ADD_PAYMENT_METHOD`;
export const ADD_PAYMENT_METHOD_SUCCESS = `${NAME}/ADD_PAYMENT_METHOD_SUCCESS`;
export const ADD_PAYMENT_METHOD_FAIL = `${NAME}/ADD_PAYMENT_METHOD_FAIL`;
export const RESET_ADD_PAYMENT_METHOD = `${NAME}/RESET_ADD_PAYMENT_METHOD`;

export const getAddPaymentMethodData = store => store[NAME].addPaymentMethod;

export const addPaymentMethod = data => ({
  type: ADD_PAYMENT_METHOD,
  data
});

export const addPaymentMethodSuccess = data => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  data
});

export const addPaymentMethodFail = error => ({
  type: ADD_PAYMENT_METHOD_FAIL,
  error
});

export const resetAddPaymentMethod = () => ({
  type: RESET_ADD_PAYMENT_METHOD
});