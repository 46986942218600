export const NAME = "PAYMENT";

export const DELETE_PAYMENT_METHOD = `${NAME}/DELETE_PAYMENT_METHOD`;
export const DELETE_PAYMENT_METHOD_SUCCESS = `${NAME}/DELETE_PAYMENT_METHOD_SUCCESS`;
export const DELETE_PAYMENT_METHOD_FAIL = `${NAME}/DELETE_PAYMENT_METHOD_FAIL`;
export const RESET_DELETE_PAYMENT_METHOD = `${NAME}/RESET_DELETE_PAYMENT_METHOD`;

export const getDeletePaymentMethodData = store => store[NAME].deletePaymentMethod;

export const deletePaymentMethod = data => ({
  type: DELETE_PAYMENT_METHOD,
  data
});

export const deletePaymentMethodSuccess = data => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  data
});

export const deletePaymentMethodFail = error => ({
  type: DELETE_PAYMENT_METHOD_FAIL,
  error
});

export const resetDeletePaymentMethod = () => ({
  type: RESET_DELETE_PAYMENT_METHOD
});