import { takeLatest, call, all, fork, put, select } from "redux-saga/effects";
import Actions from "../../actions";
import * as api from "../../api";

const getSessionData = store => store.PROFILE.userSession;

function* payVehicleCompound({ data }) {
  let sessionData = yield select(getSessionData);
  let token = sessionData.data.auth_token;
  const headers = { Authorization: `Bearer ${token}` };

  const { response, error } = yield call(
    api.payVehicleCompound,
    data.data,
    headers,
    data.paramString
  );
  if (response) {
    yield put(Actions.payVehicleCompoundSuccess(response.data));
  } else {
    yield put(Actions.payVehicleCompoundFail(error.response.data));
  }
}

function* watchPayVehicleCompound() {
  yield takeLatest(Actions.PAY_VEHICLE_COMPOUND, payVehicleCompound);
}

export default function* submit() {
  yield all([fork(watchPayVehicleCompound)]);
}
