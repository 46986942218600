import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;

function* payAssessmentTax({ data }) {
    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.payAssessmentTax, data.data, headers, data.paramString);
    if (response) {
        yield put(Actions.payAssessmentTaxSuccess(response.data));
    } else {
        if (error.code === "ECONNABORTED") {
            return yield put(Actions.payAssessmentTaxFail(error))
        }
        yield put(Actions.payAssessmentTaxFail(error.response.data));
    }
}

function* watchPayAssessmentTax() {
    yield takeLatest(Actions.PAY_ASSESSMENT_TAX, payAssessmentTax);
}

export default function* submit() {
    yield all([
        fork(watchPayAssessmentTax)
    ])
}