import { takeLatest, call, all, fork, put, select } from 'redux-saga/effects';
import Actions from '../../actions';
import * as api from '../../api';

const getSessionData = store => store.PROFILE.userSession;
const getAddressData = store => store.VEHICLE.address;
const getUserData = store => store.VEHICLE.order.user;
const getTypeData = store => store.VEHICLE.order.type;
const getVehicleTypeData = store => store.VEHICLE.order.vehicle_type;
const getVehicleData = store => store.VEHICLE.order.vehicleData;
const getNeedInsuranceData = store => store.VEHICLE.order.needInsurance;

function* submitOrder({ data, callback, callbackError }) {

    let sessionData = yield select(getSessionData);

    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.vehicleOrder, data, headers);
    
    if (response) {
        yield put(Actions.submitOrderSuccess());
        callback && callback();
    } else {
        //@todo handle failed 
        yield put(Actions.submitOrderFailed());
        callbackError && callbackError();
        // yield put(Actions.getUserProfileFail(error.response.data));
    }
}

function* checkExpiryRoadTax({ data, callback, callbackError, callbackExist }) {

    let sessionData = yield select(getSessionData);
    let token = sessionData.data.auth_token;
    const headers = { "Authorization": `Bearer ${token}` }

    const { response, error } = yield call(api.checkExpiryRoadTax, data,headers);
    
    if (response) {
        yield put(Actions.checkExpiryRoadTaxSuccess(response.data));

        if(response.data.already_exist){
            callbackExist && callbackExist();
            return;
        }
        
        callback && callback();
    } else {
        //@todo handle failed 
        yield put(Actions.checkExpiryRoadTaxFailed(error.response.data.message ? error.response.data.message : 'Failed to find'));
        callbackError && callbackError();
        // yield put(Actions.getUserProfileFail(error.response.data));
    }
}

function* watch() {
    yield takeLatest(Actions.SUBMIT_ORDER, submitOrder);
    yield takeLatest(Actions.CHECK_EXPIRY_ROADTAX, checkExpiryRoadTax);
}

export default function* submit() {
    yield all([
        fork(watch)
    ])
}