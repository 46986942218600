export const NAME = "FEEDERS";

export const GET_LAND_TAX = `${NAME}/GET_LAND_TAX`;
export const GET_LAND_TAX_SUCCESS = `${NAME}/GET_LAND_TAX_SUCCESS`;
export const GET_LAND_TAX_FAIL = `${NAME}/GET_LAND_TAX_FAIL`;
export const RESET_GET_LAND_TAX = `${NAME}/RESET_GET_LAND_TAX`;

export const getLandTaxData = store => store[NAME].getLandTax;

export const getLandTax = data => ({
  type: GET_LAND_TAX,
  data
});

export const getLandTaxSuccess = data => ({
  type: GET_LAND_TAX_SUCCESS,
  data
});

export const getLandTaxFail = error => ({
  type: GET_LAND_TAX_FAIL,
  error
});

export const resetGetLandTax = () => ({
  type: RESET_GET_LAND_TAX
});
